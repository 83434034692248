// src/api/logEntry/queries.ts

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { 
  fetchLogEntries, 
  fetchLogEntry, 
  fetchLogEntriesByObjectId,
  createLogEntry, 
  editLogEntry, 
  deleteLogEntry 
} from './api';
import { LogEntry } from './types';

// Hook for fetching all log entries
export const useLogEntries = () => {
  const navigate = useNavigate();
  return useQuery<LogEntry[], Error>('logEntries', () => fetchLogEntries(navigate), {
    select: (data) => data ?? [],
  });
};

// Hook for fetching a single log entry
export const useLogEntry = (id: string) => {
  const navigate = useNavigate();
  return useQuery(['logEntry', id], () => fetchLogEntry(id, navigate));
};

// Hook for fetching log entries by object ID
export const useLogEntriesByObjectId = (objectId: string) => {
  const navigate = useNavigate();
  return useQuery(['logEntries', objectId], () => fetchLogEntriesByObjectId(objectId, navigate), {
    // Consider data stale immediately
    staleTime: 0,
    // Refetch in the background every 10 seconds
    refetchInterval: 10000,
    // Also refetch when window regains focus
    refetchOnWindowFocus: true,
    // Ensure we always return an array even if the backend returns null
    select: (data) => data ?? [],
  });
};

// Hook for creating a new log entry
export const useCreateLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (newLogEntry: Omit<LogEntry, 'id' | 'created_at' | 'created_by'>) => createLogEntry(newLogEntry, navigate),
    {
      onSuccess: (_, variables) => {
        // Invalidate all log entries
        queryClient.invalidateQueries('logEntries');
        // Invalidate specific object's log entries
        queryClient.invalidateQueries(['logEntries', variables.object_id]);
      },
    }
  );
};

// Hook for editing a log entry
export const useEditLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    ({ id, updatedLogEntry }: { id: string; updatedLogEntry: Partial<LogEntry> }) => 
      editLogEntry(id, updatedLogEntry, navigate),
    {
      onSuccess: (_, variables) => {
        // Invalidate all log entries
        queryClient.invalidateQueries('logEntries');
        // If we have the object_id in the update, invalidate that specific query too
        if (variables.updatedLogEntry.object_id) {
          queryClient.invalidateQueries(['logEntries', variables.updatedLogEntry.object_id]);
        }
      },
    }
  );
};

// Hook for deleting a log entry
export const useDeleteLogEntry = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (id: string) => deleteLogEntry(id, navigate),
    {
      onSuccess: () => {
        // Since we don't have the object_id in the delete mutation,
        // we need to invalidate all log entry queries
        queryClient.invalidateQueries('logEntries');
      },
    }
  );
};