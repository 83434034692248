import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { effortOptions } from '../../utils/entitiesOptions';

export interface EffortCount {
  name: string;
  count: number;
}

interface ProjectEffortFilterProps {
  efforts: EffortCount[];
  selectedEfforts: string[];
  onEffortSelect: (effort: string) => void;
  onEffortDeselect: (effort: string) => void;
  className?: string;
}

export const ProjectEffortFilter: React.FC<ProjectEffortFilterProps> = ({
  efforts,
  selectedEfforts,
  onEffortSelect,
  onEffortDeselect,
  className = '',
}) => {
  const mappedOptions = effortOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={mappedOptions}
      selectedValues={selectedEfforts}
      onSelect={onEffortSelect}
      onDeselect={onEffortDeselect}
      title="Filter by Effort"
      variant="secondary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 