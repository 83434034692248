import React from 'react';
import { FrequencyFilter, FrequencyItem } from '../ui/base/FrequencyFilter';

export type PersonCount = FrequencyItem;

interface PersonsFilterProps {
  persons: PersonCount[];
  selectedPersons: string[];
  onPersonSelect: (personName: string) => void;
  onPersonDeselect: (personName: string) => void;
  className?: string;
  defaultExpanded?: boolean;
  title?: string;
}

export const PersonsFilter: React.FC<PersonsFilterProps> = ({
  persons,
  selectedPersons,
  onPersonSelect,
  onPersonDeselect,
  className = '',
  defaultExpanded = false,
  title = 'Filter by Person',
}) => {
  return (
    <FrequencyFilter
      items={persons}
      selectedItems={selectedPersons}
      onItemSelect={onPersonSelect}
      onItemDeselect={onPersonDeselect}
      className={className}
      variant="secondary"
      icon="User"
      title={title}
      defaultExpanded={defaultExpanded}
    />
  );
}; 