import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { topicStateOptions } from '../../utils/entitiesOptions';

export interface TopicStateCount {
  name: string;
  count: number;
}

interface TopicStateFilterProps {
  states: TopicStateCount[];
  selectedStates: string[];
  onStateSelect: (state: string) => void;
  onStateDeselect: (state: string) => void;
  className?: string;
}

export const TopicStateFilter: React.FC<TopicStateFilterProps> = ({
  states,
  selectedStates,
  onStateSelect,
  onStateDeselect,
  className = '',
}) => {
  const stateOptions = topicStateOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={stateOptions}
      selectedValues={selectedStates}
      onSelect={onStateSelect}
      onDeselect={onStateDeselect}
      title="Filter by State"
      variant="primary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 