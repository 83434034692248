import React, { useState, useEffect } from 'react';
import { TextInput } from '../TextInput';
import { Button } from '../Button';
import { Icon } from '../base/Icon';
import { SelectSingle } from '../SelectSingle';
import { TagFilter, Tag } from '../../tag/TagFilter';
import { PersonsFilter, PersonCount } from '../../person/PersonsFilter';
import { StateFilter, StateCount } from '../../task/StateFilter';
import { PriorityFilter, PriorityCount } from '../../task/PriorityFilter';
import { TopicStateFilter, TopicStateCount } from '../../topic/TopicStateFilter';
import { HeatmapStatusFilter, HeatmapStatusCount } from '../../topic/HeatmapStatusFilter';
import { ImportanceFilter, ImportanceCount } from '../../topic/ImportanceFilter';
import { ProjectStateFilter, ProjectStateCount } from '../../project/ProjectStateFilter';
import { ProjectEffortFilter, EffortCount } from '../../project/ProjectEffortFilter';
import { ProjectPriorityFilter, PriorityCount as ProjectPriorityCount } from '../../project/ProjectPriorityFilter';
import { ProjectPulseFilter, PulseCount } from '../../project/ProjectPulseFilter';
import { MeetingStateFilter, MeetingStateCount } from '../../meeting/MeetingStateFilter';

export interface SortOption {
  label: string;
  value: string;
}

export interface TableHeaderProps {
  onSearchChange: (value: string) => void;
  onAddNew: () => void;
  onShowArchive?: () => void;
  onShowComplete?: () => void;
  searchPlaceholder?: string;
  addButtonLabel?: string;
  bulkActions?: React.ReactNode;
  showBulkActions?: boolean;
  showArchived?: boolean;
  showCompleted?: boolean;
  showFilterButton?: boolean;
  sortOptions?: SortOption[];
  onSortChange?: (value: string) => void;
  defaultSortValue?: string;
  currentSortValue?: string;
  sortDirection?: 'asc' | 'desc';
  onDirectionToggle?: () => void;
  additionalButtons?: React.ReactNode;
  tags?: Tag[];
  selectedTags?: string[];
  onTagSelect?: (tagName: string) => void;
  onTagDeselect?: (tagName: string) => void;
  persons?: PersonCount[];
  selectedPersons?: string[];
  onPersonSelect?: (personName: string) => void;
  onPersonDeselect?: (personName: string) => void;
  owners?: PersonCount[];
  selectedOwners?: string[];
  onOwnerSelect?: (personName: string) => void;
  onOwnerDeselect?: (personName: string) => void;
  states?: StateCount[];
  selectedStates?: string[];
  onStateSelect?: (state: string) => void;
  onStateDeselect?: (state: string) => void;
  priorities?: PriorityCount[];
  selectedPriorities?: string[];
  onPrioritySelect?: (priority: string) => void;
  onPriorityDeselect?: (priority: string) => void;
  topicStates?: TopicStateCount[];
  selectedTopicStates?: string[];
  onTopicStateSelect?: (state: string) => void;
  onTopicStateDeselect?: (state: string) => void;
  heatmapStatuses?: HeatmapStatusCount[];
  selectedHeatmapStatuses?: string[];
  onHeatmapStatusSelect?: (status: string) => void;
  onHeatmapStatusDeselect?: (status: string) => void;
  importances?: ImportanceCount[];
  selectedImportances?: string[];
  onImportanceSelect?: (importance: string) => void;
  onImportanceDeselect?: (importance: string) => void;
  projectStates?: ProjectStateCount[];
  selectedProjectStates?: string[];
  onProjectStateSelect?: (state: string) => void;
  onProjectStateDeselect?: (state: string) => void;
  efforts?: EffortCount[];
  selectedEfforts?: string[];
  onEffortSelect?: (effort: string) => void;
  onEffortDeselect?: (effort: string) => void;
  projectPriorities?: ProjectPriorityCount[];
  selectedProjectPriorities?: string[];
  onProjectPrioritySelect?: (priority: string) => void;
  onProjectPriorityDeselect?: (priority: string) => void;
  pulses?: PulseCount[];
  selectedPulses?: string[];
  onPulseSelect?: (pulse: string) => void;
  onPulseDeselect?: (pulse: string) => void;
  meetingStates?: MeetingStateCount[];
  selectedMeetingStates?: string[];
  onMeetingStateSelect?: (state: string) => void;
  onMeetingStateDeselect?: (state: string) => void;
  onClearAllFilters?: () => void;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  onSearchChange,
  onAddNew,
  onShowArchive,
  onShowComplete,
  searchPlaceholder = 'Search...',
  addButtonLabel = 'Add New',
  bulkActions,
  showBulkActions = false,
  showArchived = false,
  showCompleted = false,
  showFilterButton = false,
  sortOptions,
  onSortChange,
  defaultSortValue,
  currentSortValue,
  sortDirection = 'asc',
  onDirectionToggle,
  additionalButtons,
  tags = [],
  selectedTags = [],
  onTagSelect,
  onTagDeselect,
  persons = [],
  selectedPersons = [],
  onPersonSelect,
  onPersonDeselect,
  owners = [],
  selectedOwners = [],
  onOwnerSelect,
  onOwnerDeselect,
  states = [],
  selectedStates = [],
  onStateSelect,
  onStateDeselect,
  priorities = [],
  selectedPriorities = [],
  onPrioritySelect,
  onPriorityDeselect,
  topicStates = [],
  selectedTopicStates = [],
  onTopicStateSelect,
  onTopicStateDeselect,
  heatmapStatuses = [],
  selectedHeatmapStatuses = [],
  onHeatmapStatusSelect,
  onHeatmapStatusDeselect,
  importances = [],
  selectedImportances = [],
  onImportanceSelect,
  onImportanceDeselect,
  projectStates = [],
  selectedProjectStates = [],
  onProjectStateSelect,
  onProjectStateDeselect,
  efforts = [],
  selectedEfforts = [],
  onEffortSelect,
  onEffortDeselect,
  projectPriorities = [],
  selectedProjectPriorities = [],
  onProjectPrioritySelect,
  onProjectPriorityDeselect,
  pulses = [],
  selectedPulses = [],
  onPulseSelect,
  onPulseDeselect,
  meetingStates = [],
  selectedMeetingStates = [],
  onMeetingStateSelect,
  onMeetingStateDeselect,
  onClearAllFilters,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  useEffect(() => {
    // Set initial filters if provided
    setSelectedFilters((prevFilters) => {
      const hasChanged = Object.keys(selectedFilters).some(
        (key) => selectedFilters[key] !== prevFilters[key]
      );
      if (hasChanged) {
        return selectedFilters;
      }
      return prevFilters;
    });
  }, [selectedFilters]);
  

  const handleFilterChange = (newFilters: Record<string, any>) => {
    setSelectedFilters(newFilters);
  };

  const hasActiveFilters = () => {
    return (
      selectedTags.length > 0 ||
      selectedPersons.length > 0 ||
      selectedOwners.length > 0 ||
      selectedStates.length > 0 ||
      selectedPriorities.length > 0 ||
      selectedTopicStates.length > 0 ||
      selectedHeatmapStatuses.length > 0 ||
      selectedImportances.length > 0 ||
      selectedProjectStates.length > 0 ||
      selectedEfforts.length > 0 ||
      selectedProjectPriorities.length > 0 ||
      selectedPulses.length > 0 ||
      selectedMeetingStates.length > 0
    );
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between py-2 border-b border-neutral mb-4 whitespace-nowrap">
        {/* Left Section: Filter, Search, Sorting */}
        <div className="flex items-center space-x-4 flex-grow">
          {/* Search and Filter Button */}
          <div className="flex items-center h-8 space-x-2">
            <TextInput
              placeholder={searchPlaceholder}
              onChange={(e) => onSearchChange(e.target.value)}
              startIcon={<Icon name="Search" size="sm" color="text" />}
              className="w-64 mb-0 text-sm placeholder:text-sm bg-white border border-primary-100 rounded-lg focus:ring-2 focus:ring-primary-100"
            />
            {showFilterButton && (
              <>
                <Button
                  label=""
                  onClick={() => setIsFilterVisible(!isFilterVisible)}
                  variant="secondary"
                  buttonStyle={hasActiveFilters() ? "filled" : "outlined"}
                  size="small"
                  icon="Filter"
                  iconSize="sm"
                  iconColor={hasActiveFilters() ? "white" : "text"}
                  rounded={true}
                  title="Toggle Filters"
                  className="h-8"
                />
                {hasActiveFilters() && onClearAllFilters && (
                  <Button
                    label="Clear Filters"
                    onClick={onClearAllFilters}
                    variant="secondary"
                    buttonStyle="outlined"
                    size="small"
                    icon="X"
                    iconSize="sm"
                    iconColor="text"
                    className="h-8"
                  />
                )}
              </>
            )}
          </div>

          {/* Sorting */}
          {sortOptions && onSortChange && (
            <div className="flex items-center h-8 space-x-2">
              <div className="text-sm bg-white border border-neutral rounded-lg">
                <SelectSingle
                  options={sortOptions}
                  placeholder="Sort by"
                  defaultValue={currentSortValue}
                  onSelect={onSortChange}
                  enableSearch={false}
                  size="small"
                />
              </div>
              <button
                onClick={onDirectionToggle}
                className="flex items-center justify-center p-1.5 bg-white border border-neutral rounded-lg"
              >
                {sortDirection === 'asc' ? (
                  <Icon name="ArrowUp" size="sm" color="text" />
                ) : (
                  <Icon name="ArrowDown" size="sm" color="text" />
                )}
              </button>
            </div>
          )}
        </div>

        {/* Right Section: Buttons */}
        <div className="flex items-center space-x-4 flex-shrink-0">
          <div className="hidden md:flex items-center space-x-4">
            {onShowArchive && (
              <Button
                label=""
                onClick={onShowArchive}
                variant="secondary"
                buttonStyle={showArchived ? "filled" : "outlined"}
                size="small"
                icon="Archive"
                iconSize="sm"
                iconColor='text'
                rounded={true}
                title={showArchived ? "Hide Archived" : "Show Archived"}
              />
            )}

            {onShowComplete && (
              <Button
                label=""
                onClick={onShowComplete}
                variant="secondary"
                buttonStyle={showCompleted ? "filled" : "outlined"}
                size="small"
                icon="CheckCircle"
                iconSize="sm"
                iconColor='text'
                rounded={true}
                title={showCompleted ? "Hide Completed" : "Show Completed"}
              />
            )}

            {additionalButtons}
          </div>

          <Button
            label={addButtonLabel}
            onClick={onAddNew}
            variant="primary"
            buttonStyle="filled"
            size="medium"
            icon="Plus"
            iconColor="white"
            iconSize="sm"
          />
        </div>
      </div>

      {/* Filter Sections */}
      {isFilterVisible && (
        <div className="flex flex-wrap gap-4 pb-4">
          {/* Meeting State Filter Section */}
          {meetingStates && meetingStates.length > 0 && onMeetingStateSelect && onMeetingStateDeselect && (
            <MeetingStateFilter
              states={meetingStates}
              selectedStates={selectedMeetingStates || []}
              onStateSelect={onMeetingStateSelect}
              onStateDeselect={onMeetingStateDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Topic State Filter Section */}
          {topicStates && topicStates.length > 0 && onTopicStateSelect && onTopicStateDeselect && (
            <TopicStateFilter
              states={topicStates}
              selectedStates={selectedTopicStates || []}
              onStateSelect={onTopicStateSelect}
              onStateDeselect={onTopicStateDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Heatmap Status Filter Section */}
          {heatmapStatuses.length > 0 && onHeatmapStatusSelect && onHeatmapStatusDeselect && (
            <HeatmapStatusFilter
              statuses={heatmapStatuses}
              selectedStatuses={selectedHeatmapStatuses}
              onStatusSelect={onHeatmapStatusSelect}
              onStatusDeselect={onHeatmapStatusDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Importance Filter Section */}
          {importances.length > 0 && onImportanceSelect && onImportanceDeselect && (
            <ImportanceFilter
              importances={importances}
              selectedImportances={selectedImportances}
              onImportanceSelect={onImportanceSelect}
              onImportanceDeselect={onImportanceDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* State Filter Section */}
          {states.length > 0 && onStateSelect && onStateDeselect && (
            <StateFilter
              states={states}
              selectedStates={selectedStates}
              onStateSelect={onStateSelect}
              onStateDeselect={onStateDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Priority Filter Section */}
          {priorities.length > 0 && onPrioritySelect && onPriorityDeselect && (
            <PriorityFilter
              priorities={priorities}
              selectedPriorities={selectedPriorities}
              onPrioritySelect={onPrioritySelect}
              onPriorityDeselect={onPriorityDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Tag Filter Section */}
          {tags.length > 0 && onTagSelect && onTagDeselect && (
            <TagFilter
              tags={tags}
              selectedTags={selectedTags}
              onTagSelect={onTagSelect}
              onTagDeselect={onTagDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Person Filter Section */}
          {persons.length > 0 && onPersonSelect && onPersonDeselect && (
            <PersonsFilter
              persons={persons}
              selectedPersons={selectedPersons}
              onPersonSelect={onPersonSelect}
              onPersonDeselect={onPersonDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Owner Filter Section */}
          {owners.length > 0 && onOwnerSelect && onOwnerDeselect && (
            <PersonsFilter
              persons={owners}
              selectedPersons={selectedOwners}
              onPersonSelect={onOwnerSelect}
              onPersonDeselect={onOwnerDeselect}
              className="flex-shrink-0"
              title="Filter by Owner"
            />
          )}

          {/* Project State Filter Section */}
          {projectStates.length > 0 && onProjectStateSelect && onProjectStateDeselect && (
            <ProjectStateFilter
              states={projectStates}
              selectedStates={selectedProjectStates}
              onStateSelect={onProjectStateSelect}
              onStateDeselect={onProjectStateDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Project Effort Filter Section */}
          {efforts.length > 0 && onEffortSelect && onEffortDeselect && (
            <ProjectEffortFilter
              efforts={efforts}
              selectedEfforts={selectedEfforts}
              onEffortSelect={onEffortSelect}
              onEffortDeselect={onEffortDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Project Priority Filter Section */}
          {projectPriorities.length > 0 && onProjectPrioritySelect && onProjectPriorityDeselect && (
            <ProjectPriorityFilter
              priorities={projectPriorities}
              selectedPriorities={selectedProjectPriorities}
              onPrioritySelect={onProjectPrioritySelect}
              onPriorityDeselect={onProjectPriorityDeselect}
              className="flex-shrink-0"
            />
          )}

          {/* Project Pulse Filter Section */}
          {pulses.length > 0 && onPulseSelect && onPulseDeselect && (
            <ProjectPulseFilter
              pulses={pulses}
              selectedPulses={selectedPulses}
              onPulseSelect={onPulseSelect}
              onPulseDeselect={onPulseDeselect}
              className="flex-shrink-0"
            />
          )}
        </div>
      )}
    </div>
  );
};
