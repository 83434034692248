import React from 'react';
import { Topic } from '../../api/topic/types';
import { ContextMenu, ContextMenuItem, ContextMenuGroup } from '../ui';
import { topicStateOptions, heatmapStatusOptions, importanceOptions } from '../../utils/entitiesOptions';
import { Icon, IconName } from '../ui/base/Icon';

interface TopicContextMenuProps {
  topic: Topic | null;
  position: { x: number; y: number } | null;
  onClose: () => void;
  onUpdate: (topicSlug: string, updates: Partial<Topic>) => void;
  onOpenTopic: (topic: Topic) => void;
}

const TopicContextMenu: React.FC<TopicContextMenuProps> = ({
  topic,
  position,
  onClose,
  onUpdate,
  onOpenTopic,
}) => {
  if (!topic || !position) return null;

  const handleStateChange = (newState: Topic['state']) => {
    onUpdate(topic.slug, { state: newState });
  };

  const handleHeatmapStatusChange = (newStatus: Topic['heatmap_status']) => {
    onUpdate(topic.slug, { heatmap_status: newStatus });
  };

  const handleImportanceChange = (newImportance: number) => {
    onUpdate(topic.slug, { importance: newImportance });
  };

  // Map for state icons
  const stateIcons: Record<string, IconName> = {
    IDENTIFIED: 'Flag',
    IN_PROGRESS: 'Loader',
    ONGOING: 'RefreshCcw',
    COMPLETED: 'CheckCircle',
    ARCHIVED: 'Archive',
  };

  // Map for heatmap status icons
  const heatmapIcons: Record<string, IconName> = {
    GREEN: 'Activity',
    YELLOW: 'AlertTriangle',
    ORANGE: 'AlertOctagon',
    RED: 'XCircle',
  };

  // Map for importance icons
  const importanceIcons: Record<string, IconName> = {
    '1': 'SignalZero',
    '2': 'SignalLow',
    '3': 'SignalMedium',
    '4': 'SignalHigh',
    '5': 'Signal',
  };

  const stateItems: ContextMenuItem[] = topicStateOptions.map(option => ({
    id: `state-${option.value}`,
    label: option.label,
    icon: stateIcons[option.value],
    onClick: () => handleStateChange(option.value as Topic['state']),
    isSelected: topic.state === option.value,
  }));

  const heatmapStatusItems: ContextMenuItem[] = heatmapStatusOptions.map(option => ({
    id: `heatmap-${option.value}`,
    label: option.label,
    icon: heatmapIcons[option.value],
    onClick: () => handleHeatmapStatusChange(option.value as Topic['heatmap_status']),
    isSelected: topic.heatmap_status === option.value,
  }));

  const importanceItems: ContextMenuItem[] = importanceOptions.map(option => ({
    id: `importance-${option.value}`,
    label: option.label,
    icon: importanceIcons[option.value],
    onClick: () => handleImportanceChange(Number(option.value)),
    isSelected: topic.importance === Number(option.value),
  }));

  const menuGroups: ContextMenuGroup[] = [
    {
      title: 'Actions',
      items: [
        {
          id: 'edit',
          label: 'Edit Topic',
          icon: 'Edit' as IconName,
          onClick: () => onOpenTopic(topic),
        },
        { 
          id: 'state',
          label: 'Change State',
          icon: stateIcons[topic.state] || 'Circle',
          submenu: stateItems
        },
        {
          id: 'heatmap',
          label: 'Heatmap Status',
          icon: heatmapIcons[topic.heatmap_status] || 'Activity',
          submenu: heatmapStatusItems
        },
        {
          id: 'importance',
          label: 'Importance',
          icon: importanceIcons[String(topic.importance)] || 'Star',
          submenu: importanceItems
        }
      ]
    }
  ];

  return (
    <ContextMenu
      groups={menuGroups}
      position={position}
      onClose={onClose}
    />
  );
};

export default TopicContextMenu; 