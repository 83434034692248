import React, { useState, useEffect, useRef, useCallback, ClipboardEventHandler } from 'react';
import { useEditor, EditorContent, BubbleMenu, Editor } from '@tiptap/react';
import { Extension } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import BlockQuote from '@tiptap/extension-blockquote';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Image from '@tiptap/extension-image'
import { DragEvent } from 'react'
import { Icon, IconName } from '../base/Icon';
import Mention from '@tiptap/extension-mention';
import suggestion, { SuggestionItem } from './suggestion';
import { usePersons } from '../../../api/person/queries';
import { useTags } from '../../../api/tag/queries';
import { TaskModal } from '../../task/TaskModal';
import { PersonModal } from '../../person/PersonModal'; // Assume this component exists
import { Task } from '../../../api/task/types';
import { Person } from '../../../api/person/types';
import TaskContainer from '../../../containers/TaskContainer';
import { Snackbar } from '../feedback/Snackbar';

interface MarkDownEditorProps {
  initialContent?: string;
  placeholder?: string;
  onChange?: (content: string) => void;
  onBlur?: () => void;
  parentObjectType?: 'topic' | 'project';
  parentObjectId?: string;
  menuType?: 'permanent' | 'bubble' | 'combined';
  readOnly?: boolean;
}

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface MenuBarProps {
  editor: Editor;
  onCreateTask: () => void;
  onCreatePerson: () => void;
  onImageUpload: (file: File) => Promise<string>;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor, onCreateTask, onCreatePerson, onImageUpload }) => {
  const [formatType, setFormatType] = useState('paragraph');
  const [isFormatMenuOpen, setIsFormatMenuOpen] = useState(false);
  const formatMenuRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formatMenuRef.current && !formatMenuRef.current.contains(event.target as Node)) {
        setIsFormatMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleFormatChange = useCallback(
    (value: string) => {
      setFormatType(value);
      setIsFormatMenuOpen(false);
      if (value === 'paragraph') editor.chain().focus().setParagraph().run();
      else editor.chain().focus().toggleHeading({ level: parseInt(value, 10) as HeadingLevel }).run();
    },
    [editor]
  );

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const url = await onImageUpload(file);
        editor.chain().focus().insertContent({
          type: 'image',
          attrs: { src: url }
        }).run();
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    }
  };

  const formatOptions = [
    { value: 'paragraph', label: 'Regular text', className: 'text-sm' },
    { value: '1', label: 'H1', className: 'text-lg font-semibold' },
    { value: '2', label: 'H2', className: 'text-base font-semibold' },
    { value: '3', label: 'H3', className: 'text-sm font-medium' },
  ];

  return (
    <>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        accept="image/*"
        className="hidden"
        onChange={handleImageUpload}
      />
      {/* Dropdown for text format options */}
      <div className="relative" ref={formatMenuRef}>
        <button
          onClick={() => setIsFormatMenuOpen(!isFormatMenuOpen)}
          className="flex items-center justify-between w-28 px-1 py-0.5 text-sm bg-transparent focus:outline-none text-gray-600"
        >
          <span className={formatOptions.find(opt => opt.value === formatType)?.className}>
            {formatOptions.find(opt => opt.value === formatType)?.label}
          </span>
          <Icon name="ChevronDown" size="sm" color="text" />
        </button>
        {isFormatMenuOpen && (
          <div className="absolute z-10 w-28 mt-1 bg-white border border-gray-200 rounded-md shadow-lg">
            {formatOptions.map((option) => (
              <button
                key={option.value}
                onClick={() => handleFormatChange(option.value)}
                className={`block w-full text-left px-1 py-0.5 hover:bg-gray-100 ${option.className}`}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Editor action buttons */}
      {[
        { name: 'Bold', action: () => editor.chain().focus().toggleBold().run(), isActive: () => editor.isActive('bold') },
        { name: 'Italic', action: () => editor.chain().focus().toggleItalic().run(), isActive: () => editor.isActive('italic') },
        { name: 'Strikethrough', action: () => editor.chain().focus().toggleStrike().run(), isActive: () => editor.isActive('strike') },
        { name: 'Highlighter', action: () => editor.chain().focus().toggleHighlight().run(), isActive: () => editor.isActive('highlight') },
        { name: 'Code', action: () => editor.chain().focus().toggleCode().run(), isActive: () => editor.isActive('code') },
        { name: 'Quote', action: () => editor.chain().focus().toggleBlockquote().run(), isActive: () => editor.isActive('blockquote') },
        { name: 'CheckSquare', action: () => editor.chain().focus().toggleTaskList().run(), isActive: () => editor.isActive('taskList') },
        { name: 'List', action: () => editor.chain().focus().toggleBulletList().run(), isActive: () => editor.isActive('bulletList') },
        { name: 'ListOrdered', action: () => editor.chain().focus().toggleOrderedList().run(), isActive: () => editor.isActive('orderedList') },
        {
          name: 'LinkIcon',
          action: () => {
            const url = window.prompt('Enter the URL');
            if (url) editor.chain().focus().setLink({ href: url }).run();
          },
          isActive: () => editor.isActive('link'),
        },
        {
          name: 'Image',
          action: () => {
            // Use the ref to trigger the file input click
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          },
          isActive: () => editor.isActive('image'),
        },
      ].map(({ name, action, isActive }, index) => (
        <button
          key={index}
          onClick={action}
          className={`p-0.5 rounded hover:bg-gray-100 transition-colors ${isActive() ? 'text-blue-500' : 'text-gray-600'}`}
        >
          <Icon name={name as IconName} size="sm" />
        </button>
      ))}
      {/* New button for task creation */}
      <button
        onClick={onCreateTask}
        className="p-0.5 rounded hover:bg-gray-100 transition-colors text-gray-600"
      >
        <Icon name="FilePlus2" size="sm" />
      </button>
      <button
        onClick={onCreatePerson}
        className="p-0.5 rounded hover:bg-gray-100 transition-colors text-gray-600"
      >
        <Icon name="UserPlus" size="sm" />
      </button>
    </>
  );
};

export const MarkDownEditor: React.FC<MarkDownEditorProps> = ({ 
  initialContent = '', 
  placeholder = 'Start typing...', 
  onChange,
  onBlur,
  parentObjectType,
  parentObjectId,
  menuType = 'bubble', // Default to 'bubble'
  readOnly = false
}) => {
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const editorContent = initialContent.trim() === '' ? null : initialContent;
  const { data: persons, isLoading } = usePersons();
  const { data: tags, isLoading: isLoadingTags } = useTags();
  const [formattedItems, setFormattedItems] = useState<SuggestionItem[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const editorRef = useRef(null);

  useEffect(() => {
    if (persons && tags) {
      const combinedItems: SuggestionItem[] = [
        ...persons.map(person => ({
          id: person.id,
          name: person.name,
          type: 'person' as const,
        })),
        ...tags.map(tag => ({
          id: tag.id,
          name: tag.name,
          type: 'tag' as const,
        })),
      ];
      setFormattedItems(combinedItems);
    }
  }, [persons, tags]);


  const handleImageUpload = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          // For development, we'll use base64. In production, replace with actual upload
          resolve(e.target.result.toString());
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(file);
    });
  };

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = async (event) => {
    const items = event.clipboardData?.items;
    
    if (!items || !editor) return;
  
    for (const item of Array.from(items)) {
      if (item.type.indexOf('image') === 0) {
        event.preventDefault();
        
        const file = item.getAsFile();
        if (!file) continue;
  
        try {
          const url = await handleImageUpload(file);
          editor.chain().focus().insertContent({
            type: 'image',
            attrs: { src: url }
          }).run();
        } catch (error) {
          console.error('Failed to paste image:', error);
        }
      }
    }
  };

  const handleDrop = (event: DragEvent) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    
    imageFiles.forEach(async (file) => {
      try {
        const url = await handleImageUpload(file);
        editor?.chain().focus().setImage({ src: url }).run();
      } catch (error) {
        console.error('Failed to upload image:', error);
      }
    });
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: true,
          HTMLAttributes: {
            class: 'list-disc pl-6',
          },
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: true,
          HTMLAttributes: {
            class: 'list-decimal pl-6 text-sm',
          },
        },
        listItem: {
          HTMLAttributes: {
            class: 'ml-2',
          },
        },
        blockquote: {
          HTMLAttributes: {
            class: 'border-l-4 border-gray-300 pl-4 italic',
          },
        },
        paragraph: {
          HTMLAttributes: {
            class: 'my-2 text-sm', // Adjust margin and font size for paragraphs
          },
        },
        codeBlock: {
          HTMLAttributes: {
            class: 'bg-gray-100 p-2 rounded-md font-mono',
          },
        },
        heading: false, // Disable heading to avoid duplication
        horizontalRule: false, // Disable horizontalRule to avoid duplication
      }),
      
      // Add Heading extension separately with custom levels
      Heading.configure({
        levels: [1, 2, 3], // Define which heading levels to use
      }).extend({
        addAttributes() {
          return {
            level: {
              default: 1,
            },
            HTMLAttributes: {
              level: {
                renderHTML: (attributes: any) => { // Explicitly define 'attributes' as 'any' to avoid TypeScript error
                  if (attributes.level === 1) {
                    return {
                      class: 'text-lg font-bold my-2', // Styling for H1
                    };
                  }
                  if (attributes.level === 2) {
                    return {
                      class: 'text-base font-semibold my-1.5', // Styling for H2
                    };
                  }
                  if (attributes.level === 3) {
                    return {
                      class: 'text-sm font-semibold my-1', // Styling for H3
                    };
                  }
                  return {};
                },
              },
            },
          };
        },
      }),


      Image.configure({
        HTMLAttributes: {
          class: 'max-w-full h-auto rounded-lg',
        },
        allowBase64: true,
        inline: true,
      }),
      
      
      // Add HorizontalRule extension separately
      HorizontalRule,
  
      TaskList.configure({
        HTMLAttributes: {
          class: 'not-prose pl-2', // Balanced spacing between items
        },
      }),
  
      TaskItem.configure({
        nested: true,
        HTMLAttributes: {
          class: 'flex gap-2 items-baseline relative', // Changed to items-baseline and added gap
        },
      }).extend({
        renderHTML({ node, HTMLAttributes }) {
          const { checked } = node.attrs;
          const attrs = {
            ...HTMLAttributes,
            class: `flex gap-2 items-baseline relative ${
              node.attrs.nested ? 'ml-4' : ''
            }`,
          };
          
          return [
            'li',
            attrs,
            [
              'label',
              {
                contenteditable: 'false',
                class: 'flex items-center justify-center cursor-pointer shrink-0', // Added shrink-0 to prevent checkbox from shrinking
              },
              [
                'input',
                {
                  type: 'checkbox',
                  checked: checked,
                  class: 'form-checkbox h-4 w-4 text-primary rounded cursor-pointer',
                },
              ],
            ],
            ['div', { class: 'flex-1 break-words leading-normal pt-[1px]' }, 0], // Slight top padding for alignment
          ];
        },
      }),
  
      Highlight,
      
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
          class: 'text-blue-500 underline hover:text-blue-700',
          style: 'cursor: pointer;', // Adds the hand cursor on hover
        },
      }),
  
      Placeholder.configure({
        placeholder,
        // @ts-ignore
        shouldShow: ({ editor }) => editor.getHTML() === '<p class="my-2 text-sm"></p>',
      }),
  
      Mention.configure({
        HTMLAttributes: {
          class: '',
        },
        renderText({ node }) {
          const item = node.attrs as { id: string; label: string; type: string };
          const prefix = item.type === 'person' ? '@' : '@';
          const label = item.label || 'Unnamed';
          return `${prefix}${label}`;
        },
        suggestion: suggestion(formattedItems),
      }).extend({
        addAttributes() {
          return {
            id: {
              default: null,
            },
            label: {
              default: null,
            },
            type: {
              default: 'person',
              renderHTML: (attributes) => ({
                'data-type': attributes.type === 'tag' ? 'tag' : 'mention',
                class: attributes.type === 'tag'
                  ? 'bg-accent bg-opacity-10 rounded-md px-1 text-accent-dark font-medium inline-block'
                  : 'bg-primary bg-opacity-10 rounded-md px-1 text-primary-dark font-medium inline-block',
              }),
            },
          };
        },
      }),     
    ],
    
    content: editorContent,
    editable: !readOnly,
    onUpdate: ({ editor }: { editor: Editor }) => {
      if (readOnly) return;
      const newContent = editor.getHTML();
      if (onChange) {
        onChange(newContent);
      }
    },
  
    onSelectionUpdate: ({ editor }) => {
      const selection = editor.state.selection;
      const text = editor.state.doc.textBetween(selection.from, selection.to);
      setSelectedText(text);
    },
  }, [formattedItems, readOnly]);
  

  useEffect(() => {
    if (editor && initialContent !== editor.getHTML()) {
      editor.commands.setContent(initialContent);
    }
  }, [editor, initialContent]);

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const parseSelectedText = (text: string): { name: string; } => {
      return { name: text };
  };

  const handlePersonSuccess = useCallback((message: string, person: Person) => {
    setSnackbarMessage(message);
    setSnackbarType('success');
    setShowSnackbar(true);
    setShowPersonModal(false);

    // Insert mention for the newly created person
    if (editor) {
      const { from, to } = editor.state.selection;
      editor
        .chain()
        .focus()
        .deleteRange({ from, to })
        .insertContent([
          {
            type: 'mention',
            attrs: {
              id: person.id,
              label: person.name,
              type: 'person',
            },
          },
          { type: 'text', text: ' ' }, // Add a space after the mention
        ])
        .run();
    }

    // Update formattedItems with the new person
    setFormattedItems(prevItems => [
      ...prevItems,
      {
        id: person.id,
        name: person.name,
        type: 'person' as const,
      },
    ]);
  }, [editor]);

  const openTaskModal = useCallback(() => {
    setShowTaskModal(true);
  }, []);

  const openPersonModal = useCallback(() => {
    setShowPersonModal(true);
  }, []);

  return (
    <TaskContainer
      topicId={parentObjectType === 'topic' ? parentObjectId : undefined}
      projectId={parentObjectType === 'project' ? parentObjectId : undefined}
      render={({ data, loading, error, onCreate }) => (
        <div 
          className="w-full h-full min-h-[100px] flex flex-col relative" 
          ref={editorRef}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onPaste={handlePaste}
        >
          {/* Conditional Menu Rendering */}
          {editor && menuType && (
            <>
              {/* Permanent Menu */}
              {(menuType === 'permanent' || menuType === 'combined') && (
                <div className="hidden md:flex bg-background border border-gray-200 rounded-md p-2 mb-10 items-center space-x-1.5 whitespace-nowrap min-w-[420px]">
                  <MenuBar
                    editor={editor}
                    onCreateTask={() => setShowTaskModal(true)}
                    onCreatePerson={() => setShowPersonModal(true)}
                    onImageUpload={handleImageUpload}
                  />
                </div>
              )}

              {/* Bubble Menu */}
              {(menuType === 'bubble' || menuType === 'combined') && (
                <BubbleMenu
                  className="bg-white border border-gray-200 shadow-md rounded-md p-2 flex flex-wrap items-center gap-1.5 w-[225px] md:w-auto max-w-[90vw] md:max-w-none min-w-0 md:min-w-[420px]"
                  tippyOptions={{
                    duration: 100,
                    placement: 'top-start',
                    offset: [0, 10],
                    zIndex: 50,
                  }}
                  editor={editor}
                >
                  <MenuBar
                    editor={editor}
                    onCreateTask={() => setShowTaskModal(true)}
                    onCreatePerson={() => setShowPersonModal(true)}
                    onImageUpload={handleImageUpload}
                  />
                </BubbleMenu>
              )}
            </>
          )}

          {/* Editor Content */}
          <EditorContent 
            editor={editor} 
            className="flex-grow h-full overflow-auto prose max-w-none outline-none tiptap-editor" // Flexible height controlled by parent
            onBlur={handleBlur} // Trigger the onBlur when the editor loses focus
          />
          
          {showTaskModal && (
            <TaskModal
              isOpen={showTaskModal}
              onClose={() => setShowTaskModal(false)}
              onSuccess={(message) => {
                setSnackbarMessage(message);
                setSnackbarType('success');
                setShowSnackbar(true);
                setShowTaskModal(false);
              }}
              onError={(message) => {
                setSnackbarMessage(message);
                setSnackbarType('error');
                setShowSnackbar(true);
              }}
              topicId={parentObjectType === 'topic' ? parentObjectId : undefined}
              projectId={parentObjectType === 'project' ? parentObjectId : undefined}
              initialTitle={selectedText}
            />
          )}

          {showPersonModal && (
            <PersonModal
              isOpen={showPersonModal}
              onClose={() => setShowPersonModal(false)}
              prefilledData={parseSelectedText(selectedText)}
              onSuccess={handlePersonSuccess}
              onError={(message) => {
                setSnackbarMessage(message);
                setSnackbarType('error');
                setShowSnackbar(true);
              }}
            />
          )}

          {showSnackbar && (
            <Snackbar
              message={snackbarMessage}
              type={snackbarType}
              onClose={() => setShowSnackbar(false)}
            />
          )}
        </div>
      )}
    />
  );
};

export default MarkDownEditor;