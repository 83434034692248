import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TopicContainer from '../containers/TopicContainer';
import { TopicTable } from '../components/topic/TopicTable';
import { TopicModal } from '../components/topic/TopicModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Topic } from '../api/topic/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { Button } from '../components/ui';
import { TopicHeatmapView } from '../components/topic/TopicHeatmapView';
import { TopicBoardView } from '../components/topic/TopicBoardView';
import { Tag as TagFilterType } from '../components/tag/TagFilter';
import { PersonCount } from '../components/person/PersonsFilter';
import { topicStateOptions, heatmapStatusOptions, importanceOptions } from '../utils/entitiesOptions';
import { TopicTableMobile } from '../components/topic/TopicTableMobile';
import { BREAKPOINTS } from '../utils/breakpoints';
import { useTopicFilters } from '../hooks';

type TopicState = 'IDENTIFIED' | 'IN_PROGRESS' | 'COMPLETED' | 'ONGOING' | 'ARCHIVED';

const TopicListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [topicToDelete, setTopicToDelete] = useState<string | null>(null);
  const [topicToEdit, setTopicToEdit] = useState<Topic | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();

  // Use the topic filters hook
  const {
    filters,
    setSearchTerm,
    setShowArchived,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addPerson,
    removePerson,
    addState,
    removeState,
    addHeatmapStatus,
    removeHeatmapStatus,
    addImportance,
    removeImportance,
    clearAllFilters,
    applyFilters
  } = useTopicFilters();

  // Capture the initial view mode
  const initialViewModeRef = useRef(filters.viewMode);
  const isInitialLoadRef = useRef(true);
  
  // Combined effect for handling view mode and navigation
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const viewSegment = pathSegments[pathSegments.length - 1];
    const isFilterPath = ['all', 'backlog', 'active', 'complete'].includes(viewSegment);

    // Determine the current view mode from the URL
    const urlViewMode = 
      viewSegment === "heatmap" || location.pathname.includes("/topics/heatmap") 
        ? 'heatmap' 
        : viewSegment === "board" || location.pathname.includes("/topics/board") 
          ? 'board' 
          : 'table';

    // On initial load, check if we need to redirect
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;

    const isRootTopicsPath = 
      location.pathname === '/topics/' || 
      location.pathname === '/topics' ||
      (
          ['all', 'backlog', 'active', 'complete'].some(
          path => location.pathname === path
        ) && 
        !location.pathname.includes('/board') && 
        !location.pathname.includes('/heatmap')
      );
    
      // Only redirect if:
      // 1. We're on a root path
      // 2. The initial view mode is different from the URL view mode
      // 3. The initial view mode is not 'table' (default)
      if (isRootTopicsPath && urlViewMode !== initialViewModeRef.current && initialViewModeRef.current !== 'table') {
        let basePath = '/topics';
        if (isFilterPath) {
          basePath = `/topics/${viewSegment}`;
        }
        
        const redirectPath = initialViewModeRef.current === 'heatmap' 
          ? `${basePath}/heatmap`
          : initialViewModeRef.current === 'board'
            ? `${basePath}/board`
            : basePath;
            
        navigate(redirectPath, { replace: true });
        return;
      }
    }

    // Update view mode based on URL if not a filter path
    if (!isFilterPath && !showModal && filters.viewMode !== urlViewMode) {
      setViewMode(urlViewMode);
    }
  }, [location.pathname, showModal, filters.viewMode, setViewMode, navigate]);

  useEffect(() => {
    if (slug && topics.length > 0) {
      const topic = topics.find((topic) => topic.slug === slug);
      if (topic) {
        setTopicToEdit(topic);
        setShowModal(true);
      }
    }
  }, [slug, topics]);

  // Apply path-based filters for special routes
  useEffect(() => {
    const path = location.pathname;
    console.log('Applying path-based filters for:', path);
    
    // Extract the current view mode segment from the path
    let currentViewSegment = '';
    if (path.includes('/heatmap')) {
      currentViewSegment = '/heatmap';
    } else if (path.includes('/board')) {
      currentViewSegment = '/board';
    }
    
    // Only apply path-based filters if explicitly navigating to filter pages
    // but preserve the current view mode (board, heatmap, or table)
    if (path.includes("/topics/backlog")) {
      removeState("all");
      addState("IDENTIFIED");
      setShowCompleted(false);
    } else if (path.includes("/topics/active")) {
      removeState("all");
      addState("IN_PROGRESS");
      addState("ONGOING");
      setShowCompleted(false);
    } else if (path.includes("/topics/complete")) {
      removeState("all");
      addState("COMPLETED");
      setShowCompleted(true);
    } else if (path.includes("/topics/all")) {
      // Clear state filters to show all
      removeState("IDENTIFIED");
      removeState("IN_PROGRESS");
      removeState("ONGOING");
      removeState("COMPLETED");
    }
  }, [location.pathname, addState, removeState, setShowCompleted]);

  const toggleViewMode = (mode: 'table' | 'heatmap' | 'board') => {
    setViewMode(mode);
    
    // Build URL path preserving any filter segments
    let basePath = '/topics';
    const pathSegments = location.pathname.split('/');
    const filterSegment = pathSegments.filter(seg => ['all', 'backlog', 'active', 'complete'].includes(seg))[0];
    
    if (filterSegment) {
      basePath = `/topics/${filterSegment}`;
    }
    
    // Add view segment if not table (default)
    const path = mode === 'table' 
      ? basePath 
      : `${basePath}/${mode}`;
      
    navigate(path);
  };

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Topic title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: TopicState,
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Topic state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Topic tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePersons = (
    slug: string,
    newPersons: Person[],
    onEdit: (slug: string, updatedTopic: Partial<Topic>) => void
  ) => {
    onEdit(slug, { persons: newPersons });
    setSnackbarMessage('Topic persons updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setTopicToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (topicToDelete) {
      onDelete(topicToDelete);
      setShowDeleteDialog(false);
      setTopicToDelete(null);
      setSnackbarMessage('Topic deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleAddNew = () => {
    setTopicToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default to modal if none is present
    }
  
    navigate(`/topics/new?${searchParams.toString()}`);
  };

  const handleEditTopic = (topic: Topic) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/topics/${topic.slug}?${searchParams.toString()}`);
    setTopicToEdit(topic);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTopicToEdit(null);
  
    // Navigate to the appropriate view based on viewMode
    switch (filters.viewMode) {
      case 'heatmap':
        navigate('/topics/heatmap');
        break;
      case 'board':
        navigate('/topics/board');
        break;
      default:
        navigate('/topics');  // Default to the list/table view
        break;
    }
  };
  
  const handleSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('success');
    setShowSnackbar(true);
    setShowModal(false);
    
    // Navigate to the path that corresponds to the current view mode
    const path = filters.viewMode === 'heatmap' 
      ? '/topics/heatmap' 
      : filters.viewMode === 'board' 
        ? '/topics/board' 
        : '/topics/';
    navigate(path);
  };

  const handleError = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarType('error');
    setShowSnackbar(true);
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
    if (value === filters.sortBy) {
      setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortDirection('asc');
    }
  };

  const sortOptions = [
    { label: 'Title', value: 'title' },
    { label: 'Importance', value: 'importance' },
    { label: 'State', value: 'state' },
    { label: 'Heatmap Status', value: 'heatmap_status' },
    { label: 'Created At', value: 'created_at' },
  ];

  const computePersonFrequencies = (topics: Topic[]): PersonCount[] => {
    const personCounts = new Map<string, number>();
    
    topics.forEach(topic => {
      topic.persons.forEach((person: Person) => {
        const count = personCounts.get(person.name) || 0;
        personCounts.set(person.name, count + 1);
      });
    });

    return Array.from(personCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  const handlePersonSelect = (personName: string) => {
    addPerson(personName);
  };

  const handlePersonDeselect = (personName: string) => {
    removePerson(personName);
  };

  const computeTopicStateFrequencies = (topics: Topic[]): { name: string; count: number }[] => {
    const stateCounts = new Map<string, number>();
    
    topics.forEach(topic => {
      if (topic.state) {
        const count = stateCounts.get(topic.state) || 0;
        stateCounts.set(topic.state, count + 1);
      }
    });

    return Array.from(stateCounts.entries()).map(([state, count]) => ({
      name: state,
      count
    }));
  };

  const computeHeatmapStatusFrequencies = (topics: Topic[]): { name: string; count: number }[] => {
    const statusCounts = new Map<string, number>();
    
    topics.forEach(topic => {
      if (topic.heatmap_status) {
        const count = statusCounts.get(topic.heatmap_status) || 0;
        statusCounts.set(topic.heatmap_status, count + 1);
      }
    });

    return Array.from(statusCounts.entries()).map(([status, count]) => ({
      name: status,
      count
    }));
  };

  const computeImportanceFrequencies = (topics: Topic[]): { name: string; count: number }[] => {
    const importanceCounts = new Map<string, number>();
    
    topics.forEach(topic => {
      if (topic.importance) {
        const count = importanceCounts.get(topic.importance.toString()) || 0;
        importanceCounts.set(topic.importance.toString(), count + 1);
      }
    });

    return Array.from(importanceCounts.entries()).map(([importance, count]) => ({
      name: importance,
      count
    }));
  };

  const handleTopicStateSelect = (state: string) => {
    addState(state);
  };

  const handleTopicStateDeselect = (state: string) => {
    removeState(state);
  };

  const handleHeatmapStatusSelect = (status: string) => {
    addHeatmapStatus(status);
  };

  const handleHeatmapStatusDeselect = (status: string) => {
    removeHeatmapStatus(status);
  };

  const handleImportanceSelect = (importance: string) => {
    addImportance(importance);
  };

  const handleImportanceDeselect = (importance: string) => {
    removeImportance(importance);
  };

  const handleTagSelect = (tagName: string) => {
    addTag(tagName);
  };

  const handleTagDeselect = (tagName: string) => {
    removeTag(tagName);
  };

  const computeTagFrequencies = (topics: Topic[]): TagFilterType[] => {
    const tagCounts = new Map<string, number>();
    
    topics.forEach(topic => {
      topic.tags.forEach((tag: Tag) => {
        const count = tagCounts.get(tag.name) || 0;
        tagCounts.set(tag.name, count + 1);
      });
    });

    return Array.from(tagCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINTS.TABLET);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => {
          navigate('/topics/');
          setViewMode('table');
        }}
        variant={filters.viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Grid"
        onClick={() => {
          navigate('/topics/heatmap');
          setViewMode('heatmap');
        }}
        variant={filters.viewMode === 'heatmap' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Heatmap View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => {
          navigate('/topics/board');
          setViewMode('board');
        }}
        variant={filters.viewMode === 'board' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Board View"
        iconColor="white"
      />
    </>
  );

  return (
    <div>
      <h1 className="m-4">{filters.viewMode === 'heatmap' ? "Topics - Heatmap" : filters.viewMode === 'board' ? "Topics - Board" : "Topics"}</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={setSearchTerm}
        onAddNew={handleAddNew}
        onShowArchive={() => setShowArchived(!filters.showArchived)}
        onShowComplete={() => setShowCompleted(!filters.showCompleted)}
        showArchived={filters.showArchived}
        showCompleted={filters.showCompleted}
        searchPlaceholder="Search Topics..."
        addButtonLabel="Add New Topic"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={filters.sortBy}
        sortDirection={filters.sortDirection}
        onDirectionToggle={() => setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc')}
        additionalButtons={additionalButtons}
        tags={computeTagFrequencies(topics)}
        selectedTags={filters.selectedTags}
        onTagSelect={handleTagSelect}
        onTagDeselect={handleTagDeselect}
        persons={computePersonFrequencies(topics)}
        selectedPersons={filters.selectedPersons}
        onPersonSelect={handlePersonSelect}
        onPersonDeselect={handlePersonDeselect}
        topicStates={computeTopicStateFrequencies(topics)}
        selectedTopicStates={filters.selectedTopicStates}
        onTopicStateSelect={handleTopicStateSelect}
        onTopicStateDeselect={handleTopicStateDeselect}
        heatmapStatuses={computeHeatmapStatusFrequencies(topics)}
        selectedHeatmapStatuses={filters.selectedHeatmapStatuses}
        onHeatmapStatusSelect={handleHeatmapStatusSelect}
        onHeatmapStatusDeselect={handleHeatmapStatusDeselect}
        importances={computeImportanceFrequencies(topics)}
        selectedImportances={filters.selectedImportances}
        onImportanceSelect={handleImportanceSelect}
        onImportanceDeselect={handleImportanceDeselect}
        onClearAllFilters={clearAllFilters}
        showFilterButton={true}
      />

      <TopicContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading topics...</p>
            </div>
          );
          
          if (error) return <div>{error}</div>;

          if (data) {
            setTopics(data);
          }

          // Use the applyFilters function from the hook
          const filteredAndSortedTopics = applyFilters(data || []);

          return (
            <>
              {showModal && (
                <TopicModal
                  isOpen={showModal}
                  onClose={handleCloseModal}
                  onSuccess={handleSuccess}
                  onError={handleError}
                  initialData={topicToEdit ? { ...topicToEdit } : undefined}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this topic?</p>
              </Modal>

              {filters.viewMode === 'heatmap' ? (
                <TopicHeatmapView
                  topics={filteredAndSortedTopics}
                  onOpenTopic={(topic) => {
                    setTopicToEdit(topic);
                    setShowModal(true);
                    navigate(`/topics/${topic.slug}`);
                  }}
                  onEdit={(slug, updatedTopic) => onEdit(slug, updatedTopic)}
                />
              ) : filters.viewMode === 'board' ? (
                <TopicBoardView
                  topics={filteredAndSortedTopics}
                  onOpenTopic={(topic) => {
                    setTopicToEdit(topic);
                    setShowModal(true);
                    navigate(`/topics/${topic.slug}`);
                  }}
                  onEdit={(slug, updatedTopic) => onEdit(slug, updatedTopic)}
                />
              ) : (
                isMobile ? (
                  <TopicTableMobile
                    data={filteredAndSortedTopics}
                    onEdit={handleEditTopic}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState as TopicState, onEdit)}
                  />
                ) : (
                  <TopicTable
                    data={filteredAndSortedTopics}
                    key={filteredAndSortedTopics.map((topic: Topic) => topic.slug).join()}
                    onEdit={handleEditTopic}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState as TopicState, onEdit)}
                    onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                    onUpdatePersons={(slug, newPersons) => handleUpdatePersons(slug, newPersons, onEdit)}
                    onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                  />
                )
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default TopicListPage;

