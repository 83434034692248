import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { priorityOptions } from '../../utils/entitiesOptions';

export interface PriorityCount {
  name: string;
  count: number;
}

interface ProjectPriorityFilterProps {
  priorities: PriorityCount[];
  selectedPriorities: string[];
  onPrioritySelect: (priority: string) => void;
  onPriorityDeselect: (priority: string) => void;
  className?: string;
}

export const ProjectPriorityFilter: React.FC<ProjectPriorityFilterProps> = ({
  priorities,
  selectedPriorities,
  onPrioritySelect,
  onPriorityDeselect,
  className = '',
}) => {
  const mappedOptions = priorityOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={mappedOptions}
      selectedValues={selectedPriorities}
      onSelect={onPrioritySelect}
      onDeselect={onPriorityDeselect}
      title="Filter by Priority"
      variant="secondary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 