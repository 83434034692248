import React from 'react';
import { Project } from '../../api/project/types';
import { TableBadgeComponent, TableActionButtons } from '../ui';
import { projectStateOptions } from '../../utils/entitiesOptions';

interface ProjectTableMobileProps {
  data: Project[];
  onEdit: (project: Project) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Project['state']) => void;
}

// Helper function to get the label from options based on the value
const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const ProjectTableMobile: React.FC<ProjectTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((project) => (
        <div 
          key={project.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(project)}
          >
            {project.title}
          </div>
          <div className="flex items-center justify-between">
            <TableBadgeComponent
              label={getLabelForValue(project.state, projectStateOptions)}
              type="projectState"
              onUpdate={(newState) => onUpdateState(project.slug, newState as Project['state'])}
              options={projectStateOptions}
              isEditable={true}
            />
            <TableActionButtons
              onEdit={() => onEdit(project)}
              onDelete={() => onDelete(project.slug)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}; 