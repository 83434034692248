import { Project, ProjectsSnapshot, ProjectsSnapshotListItem, CreateProjectsSnapshot, UpdateProjectsSnapshot } from './types';
import { fetchWithAuth } from '../fetchWithAuth';
import { getCompanyId } from '../../utils/utils';

const API_URL = process.env.REACT_APP_API_BASE_URL;

export interface FetchProjectsParams {
  personId?: string;
  teamId?: string;  // New parameter for filtering by team
  startDate?: string;
  endDate?: string;
}

// Fetch all Projects with optional teamId or personId filtering
export const fetchProjects = async (
  params: FetchProjectsParams,
  navigate: (path: string) => void
): Promise<Project[]> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const queryParams = [];
  
  // Append team_id if provided, otherwise append person_id
  if (params.teamId) {
    queryParams.push(`team_id=${params.teamId}`);
  } else if (params.personId) {
    queryParams.push(`person_id=${params.personId}`);
  }
  
  if (params.startDate) queryParams.push(`start_date=${params.startDate}`);
  if (params.endDate) queryParams.push(`end_date=${params.endDate}`);

  let url = `${API_URL}projects/`;
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await fetchWithAuth(url, {}, navigate);
  
  if (!response.ok) throw new Error('Failed to fetch Projects');

  const projects = await response.json();
  
  return projects.map((project: any) => ({
    ...project,
    team: project.team_detail || null,
  }));
};

// Fetch a single Project by slug
export const fetchProject = async (
  slug: string,
  navigate: (path: string) => void
): Promise<Project> => {
  const company_id = getCompanyId();
  if (!company_id) {
    throw new Error('Company ID not found.');
  }

  const response = await fetchWithAuth(`${API_URL}projects/${slug}/`, {}, navigate);
  
  if (!response.ok) throw new Error(`Failed to fetch Project with slug ${slug}`);

  const project = await response.json();
  
  return { ...project, team: project.team_detail || null };
};


// Create a new Project
export const createProject = async (
  projectData: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>,
  navigate: (path: string) => void
): Promise<Project> => {
  const { company, ...restProjectData } = projectData;

  const response = await fetchWithAuth(`${API_URL}projects/`, {
    method: 'POST',
    body: JSON.stringify({
      person_ids: restProjectData.persons.map((person) => person.id),
      tag_ids: restProjectData.tags.map((tag) => tag.id),
      owner_id: restProjectData.owner?.id,
      ...restProjectData,
      team: restProjectData.team?.id,
    }),
  }, navigate);

  if (!response.ok) throw new Error('Failed to create Project');
  return response.json();
};

// Edit an existing Project (using PATCH)
export const editProject = async (
  slug: string,
  updatedProject: Partial<Project>,
  navigate: (path: string) => void
): Promise<Project> => {
  const { company, persons, owner, ...restUpdatedProject } = updatedProject;

  const response = await fetchWithAuth(`${API_URL}projects/${slug}/`, {
    method: 'PATCH',
    body: JSON.stringify({
      ...restUpdatedProject,
      person_ids: persons?.map((person) => person.id),      
      owner_id: owner?.id,
      tags: restUpdatedProject.tags?.map((tag) => tag.id),
      topics: restUpdatedProject.topics?.map((topic) => topic.id),
      team: restUpdatedProject.team?.id,
    }),
  }, navigate);

  if (!response.ok) throw new Error(`Failed to edit Project with slug ${slug}`);
  return response.json();
};

// Delete an existing Project
export const deleteProject = async (slug: string, navigate: (path: string) => void): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(`${API_URL}projects/${slug}/`, {
    method: 'DELETE',
  }, navigate);

  if (!response.ok) throw new Error(`Failed to delete Project with slug ${slug}`);
};

// Fetch all Snapshots with optional filtering
export const fetchSnapshots = async (
  params: { status?: string; startDate?: string; endDate?: string; createdBy?: string },
  navigate: (path: string) => void
): Promise<ProjectsSnapshotListItem[]> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const queryParams = [];
  if (params.status) queryParams.push(`status=${params.status}`);
  if (params.startDate) queryParams.push(`start_date=${params.startDate}`);
  if (params.endDate) queryParams.push(`end_date=${params.endDate}`);
  if (params.createdBy) queryParams.push(`created_by=${params.createdBy}`);

  let url = `${API_URL}projects-snapshots/`;
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`;
  }

  const response = await fetchWithAuth(url, {}, navigate);

  if (!response.ok) throw new Error('Failed to fetch Snapshots');

  const snapshots = await response.json();

  return snapshots.map((snapshot: any) => ({
    ...snapshot,
    snapshot_projects: Array.isArray(snapshot.snapshot_projects)
      ? snapshot.snapshot_projects.map((project: any) => ({
          ...project,
          team: project.team_detail || null,
        }))
      : [],
  }));
};


// Fetch a single Snapshot by slug
export const fetchSnapshot = async (
  slug: string,
  navigate: (path: string) => void
): Promise<ProjectsSnapshot> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${slug}/`,
    {},
    navigate
  );

  if (!response.ok) throw new Error(`Failed to fetch Snapshot with slug ${slug}`);

  const snapshot = await response.json();

  return {
    ...snapshot,
    snapshot_projects: snapshot.snapshot_projects.map((project: any) => ({
      ...project,
      team: project.team_detail || null,
    })),
  };
};

// Create a new Snapshot
export const createSnapshot = async (
  snapshotData: CreateProjectsSnapshot,
  navigate: (path: string) => void
): Promise<ProjectsSnapshot> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/`,
    {
      method: 'POST',
      body: JSON.stringify(snapshotData),
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to create Snapshot');
  return response.json();
};

// Update an existing Snapshot
export const updateSnapshot = async (
  slug: string,
  updateData: UpdateProjectsSnapshot,
  navigate: (path: string) => void
): Promise<ProjectsSnapshot> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${slug}/`,
    {
      method: 'PATCH',
      body: JSON.stringify(updateData),
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to update Snapshot with slug ${slug}`);
  return response.json();
};

// Send a Snapshot
export const sendSnapshot = async (
  slug: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${slug}/send/`,
    {
      method: 'POST',
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to send Snapshot with slug ${slug}`);
};

// Archive a Snapshot
export const archiveSnapshot = async (
  slug: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${slug}/archive/`,
    {
      method: 'POST',
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to archive Snapshot with slug ${slug}`);
};

// Add/Update SnapshotProject
export const updateSnapshotProject = async (
  snapshotSlug: string,
  projectData: { project_id: string; summary?: string },
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${snapshotSlug}/projects/`,
    {
      method: 'POST',
      body: JSON.stringify(projectData),
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to update snapshot project');
};

// Remove Project from Snapshot
export const removeSnapshotProject = async (
  snapshotSlug: string,
  projectId: string,
  navigate: (path: string) => void
): Promise<void> => {
  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${snapshotSlug}/projects/${projectId}/`,
    {
      method: 'DELETE',
    },
    navigate
  );

  if (!response.ok) throw new Error('Failed to remove project from snapshot');
};

// Delete an existing Snapshot
export const deleteSnapshot = async (
  slug: string,
  navigate: (path: string) => void
): Promise<void> => {
  const company_id = getCompanyId();
  if (!company_id) throw new Error('Company ID not found.');

  const response = await fetchWithAuth(
    `${API_URL}projects-snapshots/${slug}/`,
    {
      method: 'DELETE',
    },
    navigate
  );

  if (!response.ok) throw new Error(`Failed to delete Snapshot with slug ${slug}`);
};

// Utility function to build snapshot URL
export const getSnapshotUrl = (slug: string): string => {
  return `${API_URL}projects-snapshots/${slug}/`;
};

// Helper to check if snapshot is editable
export const isSnapshotEditable = (snapshot: ProjectsSnapshot): boolean => {
  return snapshot.status === 'draft';
};
