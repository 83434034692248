import React from 'react';
import { Project } from '../../api/project/types';
import { ContextMenu, ContextMenuItem, ContextMenuGroup } from '../ui/ContextMenu';
import { projectStateOptions, pulseOptions, effortOptions, priorityOptions } from '../../utils/entitiesOptions';
import { IconName } from '../ui';

interface ProjectContextMenuProps {
  project: Project | null;
  position: { x: number; y: number } | null;
  onClose: () => void;
  onUpdate: (projectSlug: string, updates: Partial<Project>) => void;
  onOpenProject: (project: Project) => void;
}

const ProjectContextMenu: React.FC<ProjectContextMenuProps> = ({
  project,
  position,
  onClose,
  onUpdate,
  onOpenProject,
}) => {
  if (!project || !position) return null;

  const handleStateChange = (newState: Project['state']) => {
    onUpdate(project.slug, { state: newState });
  };

  const handlePulseChange = (newPulse: Project['pulse']) => {
    onUpdate(project.slug, { pulse: newPulse });
  };

  const handleEffortChange = (newEffort: Project['effort']) => {
    onUpdate(project.slug, { effort: newEffort });
  };

  const handlePriorityChange = (newPriority: number) => {
    onUpdate(project.slug, { priority: newPriority as 1 | 2 | 3 | 4 | 5 });
  };

  // Map for state icons
  const stateIcons: Record<string, IconName> = {
    CANDIDATE: 'Compass',
    BACKLOG: 'Clipboard',
    DEFINITION_AND_ANALYSIS: 'Layers',
    DEVELOPMENT_IN_PROGRESS: 'Code',
    TESTING: 'CheckSquare',
    ROLLING_OUT: 'Truck',
    DONE: 'CheckCircle',
  };

  // Map for pulse icons
  const pulseIcons: Record<string, IconName> = {
    GREEN: 'Heart',
    YELLOW: 'AlertTriangle',
    ORANGE: 'AlertOctagon',
    RED: 'XCircle',
  };

  // Map for effort icons
  const effortIcons: Record<string, IconName> = {
    XS: 'ArrowDown',
    S: 'ArrowDownLeft',
    M: 'ArrowRight',
    L: 'ArrowUpRight',
    XL: 'ArrowUp',
  };

  // Map for priority icons
  const priorityIcons: Record<string, IconName> = {
    '1': 'SignalZero',
    '2': 'SignalLow',
    '3': 'SignalMedium',
    '4': 'SignalHigh',
    '5': 'Signal',
  };

  const stateItems: ContextMenuItem[] = projectStateOptions.map(option => ({
    id: `state-${option.value}`,
    label: option.label,
    icon: stateIcons[option.value],
    onClick: () => handleStateChange(option.value as Project['state']),
    isSelected: project.state === option.value,
  }));

  const pulseItems: ContextMenuItem[] = pulseOptions.map(option => ({
    id: `pulse-${option.value}`,
    label: option.label,
    icon: pulseIcons[option.value],
    onClick: () => handlePulseChange(option.value as Project['pulse']),
    isSelected: project.pulse === option.value,
  }));

  const effortItems: ContextMenuItem[] = effortOptions.map(option => ({
    id: `effort-${option.value}`,
    label: option.label,
    icon: effortIcons[option.value],
    onClick: () => handleEffortChange(option.value as Project['effort']),
    isSelected: project.effort === option.value,
  }));

  const priorityItems: ContextMenuItem[] = priorityOptions.map(option => ({
    id: `priority-${option.value}`,
    label: option.label,
    icon: priorityIcons[option.value],
    onClick: () => handlePriorityChange(Number(option.value)),
    isSelected: project.priority === Number(option.value),
  }));

  const menuGroups: ContextMenuGroup[] = [
    {
      title: 'Actions',
      items: [
        {
          id: 'edit',
          label: 'Edit Project',
          icon: 'Edit' as IconName,
          onClick: () => onOpenProject(project),
        },
        { 
          id: 'state',
          label: 'Change State',
          icon: stateIcons[project.state] || 'Circle',
          submenu: stateItems
        },
        {
          id: 'pulse',
          label: 'Health Status',
          icon: pulseIcons[project.pulse] || 'Circle',
          submenu: pulseItems
        },
        {
          id: 'effort',
          label: 'Effort',
          icon: effortIcons[project.effort] || 'Circle',
          submenu: effortItems
        },
        {
          id: 'priority',
          label: 'Priority',
          icon: priorityIcons[String(project.priority)] || 'Circle',
          submenu: priorityItems
        }
      ]
    }
  ];

  return (
    <ContextMenu
      groups={menuGroups}
      position={position}
      onClose={onClose}
    />
  );
};

export default ProjectContextMenu; 