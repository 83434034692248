import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { heatmapStatusOptions } from '../../utils/entitiesOptions';

export interface HeatmapStatusCount {
  name: string;
  count: number;
}

interface HeatmapStatusFilterProps {
  statuses: HeatmapStatusCount[];
  selectedStatuses: string[];
  onStatusSelect: (status: string) => void;
  onStatusDeselect: (status: string) => void;
  className?: string;
}

export const HeatmapStatusFilter: React.FC<HeatmapStatusFilterProps> = ({
  statuses,
  selectedStatuses,
  onStatusSelect,
  onStatusDeselect,
  className = '',
}) => {
  const statusOptions = heatmapStatusOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={statusOptions}
      selectedValues={selectedStatuses}
      onSelect={onStatusSelect}
      onDeselect={onStatusDeselect}
      title="Filter by Heatmap Status"
      variant="secondary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 