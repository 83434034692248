import React from 'react';
import { Person } from '../../api/person/types';
import { TableBadgeComponent, TableActionButtons, Icon } from '../ui';

interface PersonTableMobileProps {
  data: Person[];
  onEdit: (person: Person) => void;
  onDelete: (slug: string) => void;
  onUpdatePersonType: (slug: string, newPersonType: Person['person_type']) => void;
  onViewMindMap: (slug: string) => void;
}

const personTypeOptions = [
  { label: 'Team Member', value: 'team_member' },
  { label: 'Stakeholder', value: 'stakeholder' },
  { label: 'External Partner', value: 'external_partner' },
  { label: 'Advisor', value: 'advisor' },
  { label: 'Client', value: 'client' },
  { label: 'Other', value: 'other' },
];

export const PersonTableMobile: React.FC<PersonTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdatePersonType,
  onViewMindMap,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((person) => (
        <div 
          key={person.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(person)}
          >
            {person.name}
          </div>
          {person.email && (
            <div className="text-xs text-gray-500">
              {person.email}
            </div>
          )}
          <div className="flex items-center justify-between">
            <TableBadgeComponent
              label={person.person_type.replace('_', ' ')}
              type="state"
              onUpdate={(newPersonType) => onUpdatePersonType(person.slug, newPersonType as Person['person_type'])}
              options={personTypeOptions}
              isEditable={true}
            />
            <div className="flex items-center gap-2">
              <TableActionButtons
                onEdit={() => onEdit(person)}
                onDelete={() => onDelete(person.slug)}
              />
              <button
                onClick={() => onViewMindMap(person.slug)}
                className="p-2 text-primary hover:text-primary-dark rounded-full hover:bg-gray-100"
                title="View Mind Map"
              >
                <Icon name="Share2" size="sm" className="text-primary" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 