import { useState, useCallback, MouseEvent } from 'react';

interface ContextMenuPosition {
  x: number;
  y: number;
}

export const useContextMenu = () => {
  const [position, setPosition] = useState<ContextMenuPosition | null>(null);
  const [contextTarget, setContextTarget] = useState<any>(null);

  const handleContextMenu = useCallback((event: MouseEvent, target?: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    setPosition({
      x: event.clientX,
      y: event.clientY
    });
    
    if (target) {
      setContextTarget(target);
    }
  }, []);

  const closeContextMenu = useCallback(() => {
    setPosition(null);
    setContextTarget(null);
  }, []);

  return {
    position,
    contextTarget,
    handleContextMenu,
    closeContextMenu
  };
};

export default useContextMenu; 