import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { importanceOptions } from '../../utils/entitiesOptions';

export interface ImportanceCount {
  name: string;
  count: number;
}

interface ImportanceFilterProps {
  importances: ImportanceCount[];
  selectedImportances: string[];
  onImportanceSelect: (importance: string) => void;
  onImportanceDeselect: (importance: string) => void;
  className?: string;
}

export const ImportanceFilter: React.FC<ImportanceFilterProps> = ({
  importances,
  selectedImportances,
  onImportanceSelect,
  onImportanceDeselect,
  className = '',
}) => {
  const mappedOptions = importanceOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={mappedOptions}
      selectedValues={selectedImportances}
      onSelect={onImportanceSelect}
      onDeselect={onImportanceDeselect}
      title="Filter by Importance"
      variant="secondary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 