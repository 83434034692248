import React from 'react';
import { Topic } from '../../api/topic/types';
import { TableBadgeComponent, TableActionButtons } from '../ui';
import { topicStateOptions, heatmapStatusOptions, importanceOptions } from '../../utils/entitiesOptions';

interface TopicTableMobileProps {
  data: Topic[];
  onEdit: (topic: Topic) => void;
  onDelete: (id: string) => void;
  onUpdateState: (topicId: string, newState: string) => void;
}

// Helper function to get the label from options based on the value
const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const TopicTableMobile: React.FC<TopicTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((topic) => (
        <div 
          key={topic.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(topic)}
          >
            {topic.title}
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <TableBadgeComponent
                label={getLabelForValue(topic.state, topicStateOptions)}
                type="state"
                onUpdate={(newState) => onUpdateState(topic.slug, newState)}
                options={topicStateOptions}
                isEditable={true}
              />
              <TableBadgeComponent
                label={getLabelForValue(topic.heatmap_status, heatmapStatusOptions)}
                type="heatmap_status"
                onUpdate={(newStatus) => onUpdateState(topic.slug, newStatus)}
                options={heatmapStatusOptions}
                isEditable={true}
              />
            </div>
            <div className="flex items-center justify-between">
              <TableBadgeComponent
                label={getLabelForValue(topic.importance?.toString(), importanceOptions)}
                type="importance"
                onUpdate={(newImportance) => onUpdateState(topic.slug, newImportance)}
                options={importanceOptions}
                isEditable={true}
              />
              <TableActionButtons
                onEdit={() => onEdit(topic)}
                onDelete={() => onDelete(topic.slug)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 