import React, { useState, useRef, useEffect } from 'react';
import { Icon } from './Icon';
import { SelectOption } from '../Select';

interface EnumFilterProps {
  options: SelectOption[];
  selectedValues: string[];
  onSelect: (value: string) => void;
  onDeselect: (value: string) => void;
  className?: string;
  variant?: 'primary' | 'secondary';
  title: string;
  defaultExpanded?: boolean;
}

export const EnumFilter: React.FC<EnumFilterProps> = ({
  options,
  selectedValues,
  onSelect,
  onDeselect,
  className = '',
  variant = 'primary',
  title,
  defaultExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = (value: string) => {
    if (selectedValues.includes(value)) {
      onDeselect(value);
    } else {
      onSelect(value);
    }
  };

  if (options.length === 0) {
    return null;
  }

  const getVariantClasses = (isSelected: boolean) => {
    if (variant === 'primary') {
      return isSelected 
        ? 'bg-primary text-white hover:bg-primary-dark' 
        : 'bg-background border border-neutral hover:border-primary hover:text-primary';
    }
    return isSelected 
      ? 'bg-secondary text-white hover:bg-secondary-dark' 
      : 'bg-background border border-neutral hover:border-secondary hover:text-secondary';
  };

  const getBadgeClasses = () => {
    return variant === 'primary' 
      ? 'bg-primary text-white' 
      : 'bg-secondary text-white';
  };

  const getHeaderIconColor = () => {
    if (selectedValues.length > 0) {
      return variant === 'primary' ? 'primary' : 'secondary';
    }
    return 'text';
  };

  return (
    <div ref={ref} className={`relative bg-white rounded-lg border border-neutral ${className}`}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={`w-full px-4 py-2 flex items-center justify-between text-xs font-medium
          ${selectedValues.length > 0 
            ? variant === 'primary' 
              ? 'text-primary' 
              : 'text-secondary'
            : 'text-text'
          }
        `}
      >
        <div className="flex items-center gap-2">
          {options[0]?.icon && (
            <span className={selectedValues.length > 0 
              ? variant === 'primary' 
                ? 'text-primary' 
                : 'text-secondary'
              : 'text-text'
            }>
              {options[0].icon}
            </span>
          )}
          <span>{title}</span>
          {selectedValues.length > 0 && (
            <span className={`text-xxs px-1.5 py-0.5 rounded-full ${getBadgeClasses()}`}>
              {selectedValues.length}
            </span>
          )}
        </div>
        <Icon 
          name={isExpanded ? "ChevronUp" : "ChevronDown"}
          size="sm"
          color="text"
          className="transition-transform duration-200"
        />
      </button>

      {isExpanded && (
        <div className="absolute z-50 mt-1 bg-white border border-neutral rounded-lg shadow-lg max-h-[300px] overflow-y-auto w-[300px] -left-1">
          <div className="p-3">
            <div className="flex flex-wrap gap-2">
              {options.map((option) => {
                const isSelected = selectedValues.includes(option.value);
                return (
                  <button
                    key={option.value}
                    onClick={() => handleItemClick(option.value)}
                    className={`
                      inline-flex items-center px-2 py-1 rounded-md text-xs
                      transition-all duration-200 ease-in-out
                      ${getVariantClasses(isSelected)}
                    `}
                  >
                    {option.icon && (
                      <span className={`mr-1.5 ${isSelected ? 'text-white' : 'text-text'}`}>
                        {option.icon}
                      </span>
                    )}
                    <span>{option.label}</span>
                    {isSelected && (
                      <Icon 
                        name="X" 
                        size="sm"
                        color="white" 
                        className="ml-1.5 hover:scale-110 transition-transform"
                      />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}; 