import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SnapshotsContainer } from '../containers/SnapshotsContainer';
import { SnapshotTable } from '../components/snapshot/SnapshotTable';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { ProjectsSnapshotListItem } from '../api/project/types';
import { SortOption } from '../components/ui/data/TableHeader';

const SnapshotsListPage: React.FC = () => {
  // State
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [snapshotToDelete, setSnapshotToDelete] = useState<string | null>(null);
  const [snapshotToView, setSnapshotToView] = useState<ProjectsSnapshotListItem | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [snapshots, setSnapshots] = useState<ProjectsSnapshotListItem[]>([]);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  // Effect to open detail view if slug is present
  useEffect(() => {
    if (slug && snapshots.length > 0) {
      const snapshot = snapshots.find((s) => s.slug === slug);
      if (snapshot) {
        setSnapshotToView(snapshot);
      }
    }
  }, [slug, snapshots]);

  // Sort options
  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'Created At', value: 'created_at' },
  ];

  // Handlers
  const handleDelete = (slug: string) => {
    setSnapshotToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => Promise<void>) => {
    if (snapshotToDelete) {
      onDelete(snapshotToDelete)
        .then(() => {
          setShowDeleteDialog(false);
          setSnapshotToDelete(null);
          setSnackbarMessage('Snapshot deleted successfully');
          setSnackbarType('success');
          setShowSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage('Failed to delete snapshot');
          setSnackbarType('error');
          setShowSnackbar(true);
        });
    }
  };

  const handleView = (snapshot: ProjectsSnapshotListItem) => {
    navigate(`/snapshots/${snapshot.slug}`);
  };

  const handleSend = async (onSend: (slug: string) => Promise<void>, slug: string) => {
    try {
      await onSend(slug);
      setSnackbarMessage('Snapshot sent successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Failed to send snapshot');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleArchive = async (onArchive: (slug: string) => Promise<void>, slug: string) => {
    try {
      await onArchive(slug);
      setSnackbarMessage('Snapshot archived successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Failed to archive snapshot');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    navigate('/snapshots/create');  // Navigate to the CreateSnapshotPage when the button is clicked
  };

  const handleSortChange = (value: string) => {
    setSortBy(value);
  };

  // Sorting and filtering logic
  const filterAndSortSnapshots = (snapshots: ProjectsSnapshotListItem[]): ProjectsSnapshotListItem[] => {
    let filtered = snapshots.filter(snapshot =>
      snapshot.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filtered.sort((a, b) => {
      let result = 0;
      switch (sortBy) {
        case 'title':
          result = a.title.localeCompare(b.title);
          break;
        case 'created_at':
          result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
          break;
        default:
          break;
      }
      return sortDirection === 'asc' ? result : -result;
    });
  };

  return (
    <div>
      <h1 className='m-4'>Project Snapshots</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Snapshots..."
        addButtonLabel="Create New Snapshot"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        defaultSortValue="created_at"
      />

      <SnapshotsContainer
        render={({ data, loading, error, onCreate, onDelete, onSend, onArchive }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading snapshots...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          if (snapshots.length === 0 && data.length > 0) {
            setSnapshots(data);
          }

          const filteredAndSortedSnapshots = filterAndSortSnapshots(data);

          return (
            <>
              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this snapshot?</p>
              </Modal>

              {/* Snapshot Table */}
              <SnapshotTable
                data={filteredAndSortedSnapshots}
                onView={handleView}
                onDelete={(slug) => handleDelete(slug)}
                onSend={(slug) => handleSend(onSend, slug)}
                onArchive={(slug) => handleArchive(onArchive, slug)}
              />
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default SnapshotsListPage;
