import { ReactNode } from 'react';
import { SelectOption } from '../components/ui/Select'; 
import { Icon, IconName } from '../components/ui';

// Entity styling for SidePanelItem
interface EntityStyle {
  icon: {
    name: IconName;
    className: string;
  };
  prefix: string;
  getStateInfo: (state: string) => { label: string; icon: ReactNode | null };
}

export const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const getStateLabels = (states: string[], options: { value: string; label: string }[]) => {
  return states.map(state => {
    const option = options.find(opt => opt.value === state);
    return option ? option.label : state;
  });
};

export const severityColors: Record<string, string> = {
  CRITICAL: 'border-l-red-500',
  HIGH: 'border-l-amber-500',
  MEDIUM: 'border-l-yellow-500',
  LOW: 'border-l-blue-500'
};

// Topic states
export const topicStateOptions: SelectOption[] = [
  { value: 'IDENTIFIED', label: 'Identified', icon: <Icon name="Flag" size="sm" /> },
  { value: 'IN_PROGRESS', label: 'In Progress', icon: <Icon name="Loader" size="sm" /> },
  { value: 'ONGOING', label: 'Ongoing', icon: <Icon name="RefreshCcw" size="sm" /> },
  { value: 'COMPLETED', label: 'Completed', icon: <Icon name="CheckCircle" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
];

// Project states
export const projectStateOptions: SelectOption[] = [
  { value: 'CANDIDATE', label: 'Candidate', icon: <Icon name="Compass" size="sm" /> },
  { value: 'BACKLOG', label: 'Backlog', icon: <Icon name="Clipboard" size="sm" /> },
  { value: 'DEFINITION_AND_ANALYSIS', label: 'Definition & Analysis', icon: <Icon name="Layers" size="sm" /> },
  { value: 'DEVELOPMENT_IN_PROGRESS', label: 'Development in Progress', icon: <Icon name="Code" size="sm" /> },
  { value: 'TESTING', label: 'Testing', icon: <Icon name="CheckSquare" size="sm" /> },
  { value: 'ROLLING_OUT', label: 'Rolling Out', icon: <Icon name="Truck" size="sm" /> },
  { value: 'DONE', label: 'Done', icon: <Icon name="CheckCircle" size="sm" /> },
];

// Task states
export const taskStateOptions: SelectOption[] = [
  { value: 'IDENTIFIED', label: 'Identified', icon: <Icon name="Flag" size="sm" /> },
  { value: 'IN_PROGRESS', label: 'In Progress', icon: <Icon name="Loader" size="sm" /> },
  { value: 'COMPLETED', label: 'Completed', icon: <Icon name="CheckCircle" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
  { value: 'BLOCKED', label: 'Blocked', icon: <Icon name="Ban" size="sm" /> },
];

// Task priority options
export const taskPriorityOptions: SelectOption[] = [
  { value: 'LOW', label: 'Low', icon: <Icon name="ArrowDown" size="sm" /> },
  { value: 'MEDIUM', label: 'Medium', icon: <Icon name="ArrowRight" size="sm" /> },
  { value: 'HIGH', label: 'High', icon: <Icon name="ArrowUp" size="sm" /> },
  { value: 'URGENT', label: 'Urgent', icon: <Icon name="AlertTriangle" size="sm" /> },
];

// Meeting occurrence states
export const meetingOccurrenceStateOptions: SelectOption[] = [
  { value: 'SCHEDULED', label: 'Scheduled', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'IN_PROGRESS', label: 'In Progress', icon: <Icon name="Loader" size="sm" /> },
  { value: 'COMPLETED', label: 'Completed', icon: <Icon name="CheckCircle" size="sm" /> },
];

// Dial stages for tasks
export const dialStageOptions: SelectOption[] = [
  { value: 'MENTION', label: 'Mention', icon: <Icon name="MessageSquare" size="sm" /> },
  { value: 'INVITATION', label: 'Invitation', icon: <Icon name="Mail" size="sm" /> },
  { value: 'CONVERSATION', label: 'Conversation', icon: <Icon name="MessageCircle" size="sm" /> },
  { value: 'BOUNDARY', label: 'Boundary', icon: <Icon name="Shield" size="sm" /> },
  { value: 'LIMIT', label: 'Limit', icon: <Icon name="Slash" size="sm" /> },
];

// Pulse options for projects (health status)
export const pulseOptions: SelectOption[] = [
  { value: 'GREEN', label: 'Green', icon: <Icon name="Heart" size="sm" /> },
  { value: 'YELLOW', label: 'Yellow', icon: <Icon name="AlertTriangle" size="sm" /> },
  { value: 'ORANGE', label: 'Orange', icon: <Icon name="AlertOctagon" size="sm" /> },
  { value: 'RED', label: 'Red', icon: <Icon name="XCircle" size="sm" /> },
];

// Adding icons to heatmapStatusOptions
export const heatmapStatusOptions: SelectOption[] = [
  { value: 'GREEN', label: 'Green', icon: <Icon name="Activity" size="sm" /> },
  { value: 'YELLOW', label: 'Yellow', icon: <Icon name="AlertTriangle" size="sm" /> },
  { value: 'ORANGE', label: 'Orange', icon: <Icon name="AlertOctagon" size="sm" /> },
  { value: 'RED', label: 'Red', icon: <Icon name="XCircle" size="sm" /> },
];

// Adding icons to importanceOptions (shared between entities and projects)
export const importanceOptions: SelectOption[] = [
  { value: '1', label: 'Very Low', icon: <Icon name="SignalZero" size="sm" /> },
  { value: '2', label: 'Low', icon: <Icon name="SignalLow" size="sm" /> },
  { value: '3', label: 'Medium', icon: <Icon name="SignalMedium" size="sm" /> },
  { value: '4', label: 'High', icon: <Icon name="SignalHigh" size="sm" /> },
  { value: '5', label: 'Critical', icon: <Icon name="Signal" size="sm" /> },
];

// Adding icons to effortOptions for Projects
export const effortOptions: SelectOption[] = [
  { value: 'XS', label: 'Extra Small', icon: <Icon name="ArrowDown" size="sm" /> },
  { value: 'S', label: 'Small', icon: <Icon name="ArrowDownLeft" size="sm" /> },
  { value: 'M', label: 'Medium', icon: <Icon name="ArrowRight" size="sm" /> },
  { value: 'L', label: 'Large', icon: <Icon name="ArrowUpRight" size="sm" /> },
  { value: 'XL', label: 'Extra Large', icon: <Icon name="ArrowUp" size="sm" /> },
];

// Adding icons to priorityOptions for Projects
export const priorityOptions: SelectOption[] = [
  { value: '1', label: 'Very Low', icon: <Icon name="SignalZero" size="sm" /> },
  { value: '2', label: 'Low', icon: <Icon name="SignalLow" size="sm" /> },
  { value: '3', label: 'Medium', icon: <Icon name="SignalMedium" size="sm" /> },
  { value: '4', label: 'High', icon: <Icon name="SignalHigh" size="sm" /> },
  { value: '5', label: 'Critical', icon: <Icon name="Signal" size="sm" /> },
];

// Meeting recurrence options
export const recurrenceOptions: SelectOption[] = [
  { value: 'DAILY', label: 'Daily', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'WEEKLY', label: 'Weekly', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'BI_WEEKLY', label: 'Bi-Weekly', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'MONTHLY', label: 'Monthly', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'QUARTERLY', label: 'Quarterly', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'YEARLY', label: 'Yearly', icon: <Icon name="Calendar" size="sm" /> },
  { value: 'CUSTOM', label: 'Custom', icon: <Icon name="Calendar" size="sm" /> },
];

// Meeting duration options
export const durationOptions: SelectOption[] = [
  { value: '15m', label: '15 minutes', icon: <Icon name="Clock" size="sm" /> },
  { value: '30m', label: '30 minutes', icon: <Icon name="Clock" size="sm" /> },
  { value: '45m', label: '45 minutes', icon: <Icon name="Clock" size="sm" /> },
  { value: '1h', label: '1 hour', icon: <Icon name="Clock" size="sm" /> },
  { value: '1h30m', label: '1 hour 30 minutes', icon: <Icon name="Clock" size="sm" /> },
  { value: '2h', label: '2 hours', icon: <Icon name="Clock" size="sm" /> },
  { value: '3h', label: '3 hours', icon: <Icon name="Clock" size="sm" /> },
  { value: '4h', label: '4 hours', icon: <Icon name="Clock" size="sm" /> },
  { value: '1d', label: '1 day', icon: <Icon name="Clock" size="sm" /> },
];

// Strategy states with icons
export const strategyStateOptions: SelectOption[] = [
  { value: 'DRAFT', label: 'Draft', icon: <Icon name="Edit" size="sm" /> },
  { value: 'IN_REVIEW', label: 'In Review', icon: <Icon name="FileSearch" size="sm" /> },
  { value: 'APPROVED', label: 'Approved', icon: <Icon name="CheckCircle2" size="sm" /> },
  { value: 'ARCHIVED', label: 'Archived', icon: <Icon name="Archive" size="sm" /> },
];

// Utility function to get label and icon by value
const findOptionByValue = (options: SelectOption[], value: string) =>
  options.find(option => option.value === value);

// Helper functions to get friendly name and icon for each entity
export const getTopicStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(topicStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getProjectStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(projectStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getTaskStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(taskStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const getDialStageLabelAndIcon = (stage: string) => {
  const option = findOptionByValue(dialStageOptions, stage);
  return option ? { label: option.label, icon: option.icon } : { label: stage, icon: null };
};

export const getPulseLabelAndIcon = (status: string) => {
  const option = findOptionByValue(pulseOptions, status);
  return option ? { label: option.label, icon: option.icon } : { label: status, icon: null };
};

export const getHeatmapStatusLabelAndIcon = (status: string) => {
  const option = findOptionByValue(heatmapStatusOptions, status);
  return option ? { label: option.label, icon: option.icon } : { label: status, icon: null };
};

export const getImportanceLabelAndIcon = (importance: string) => {
  const option = findOptionByValue(importanceOptions, importance);
  return option ? { label: option.label, icon: option.icon } : { label: importance, icon: null };
};

export const getEffortLabelAndIcon = (effort: string) => {
  const option = findOptionByValue(effortOptions, effort);
  return option ? { label: option.label, icon: option.icon } : { label: effort, icon: null };
};

export const getPriorityLabelAndIcon = (priority: string) => {
  const option = findOptionByValue(priorityOptions, priority);
  return option ? { label: option.label, icon: option.icon } : { label: priority, icon: null };
};

export const getStrategyStateLabelAndIcon = (state: string) => {
  const option = findOptionByValue(strategyStateOptions, state);
  return option ? { label: option.label, icon: option.icon } : { label: state, icon: null };
};

export const entityStyles: Record<string, EntityStyle> = {
  TASK: {
    icon: { name: 'Check', className: 'text-blue-500' },
    prefix: '[Task]',
    getStateInfo: getTaskStateLabelAndIcon
  },
  TOPIC: {
    icon: { name: 'MessageCircle', className: 'text-purple-500' },
    prefix: '[Topic]',
    getStateInfo: getTopicStateLabelAndIcon
  },
  PROJECT: {
    icon: { name: 'Briefcase', className: 'text-green-500' },
    prefix: '[Proj]',
    getStateInfo: getProjectStateLabelAndIcon
  },
  STRATEGY: {
    icon: { name: 'Target', className: 'text-amber-500' },
    prefix: '[Strategy]',
    getStateInfo: getStrategyStateLabelAndIcon
  },
  GOAL: {
    icon: { name: 'Flag', className: 'text-amber-500' },
    prefix: '[Goal]',
    getStateInfo: getStrategyStateLabelAndIcon
  }
};