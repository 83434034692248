import { useState, useEffect, useMemo } from 'react';
import { Topic } from '../api/topic/types';
import LocalStorageUtils from '../utils/localStorageUtils';

/**
 * Interface defining all the filter options available for topics
 */
export interface TopicFilters {
  searchTerm: string;
  showArchived: boolean;
  showCompleted: boolean;
  selectedTags: string[];
  selectedPersons: string[];
  selectedTopicStates: string[];
  selectedHeatmapStatuses: string[];
  selectedImportances: string[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  viewMode: 'table' | 'heatmap' | 'board';
}

// Storage key for topic filters
const TOPIC_FILTERS_STORAGE_KEY = 'topicListFilters';

/**
 * Default values for filters when none are saved in localStorage
 */
const defaultFilters: TopicFilters = {
  searchTerm: '',
  showArchived: false,
  showCompleted: false,
  selectedTags: [],
  selectedPersons: [],
  selectedTopicStates: [],
  selectedHeatmapStatuses: [],
  selectedImportances: [],
  sortBy: 'created_at',
  sortDirection: 'desc',
  viewMode: 'table',
};

/**
 * Loads topic filters from localStorage using LocalStorageUtils
 * @returns The saved filters or default values if none exist
 */
const loadFiltersFromStorage = (): TopicFilters => {
  try {
    const savedFilters = LocalStorageUtils.getItem<TopicFilters>(TOPIC_FILTERS_STORAGE_KEY);
    console.log('Loaded topic filters from storage:', savedFilters);
    if (savedFilters) {
      // Ensure viewMode is a valid value
      const validViewModes = ['table', 'heatmap', 'board'];
      if (!validViewModes.includes(savedFilters.viewMode)) {
        console.warn('Invalid view mode detected:', savedFilters.viewMode);
        savedFilters.viewMode = defaultFilters.viewMode;
      }
      
      return {
        ...defaultFilters,  // Ensure all properties exist
        ...savedFilters     // Override with saved values
      };
    }
  } catch (error) {
    console.error('Error loading topic filters from storage:', error);
  }
  return defaultFilters;
};

/**
 * Saves topic filters to localStorage using LocalStorageUtils
 * @param filters - The filters to save
 */
const saveFiltersToStorage = (filters: TopicFilters) => {
  try {
    console.log('Saving topic filters to storage:', filters);
    LocalStorageUtils.setItem(TOPIC_FILTERS_STORAGE_KEY, filters);
  } catch (error) {
    console.error('Error saving topic filters to storage:', error);
  }
};

/**
 * Hook for managing topic filters with persistence in localStorage
 * 
 * Provides methods to:
 * - Access current filter values
 * - Update individual filters
 * - Add/remove tags, persons, states, etc.
 * - Clear all filters
 * - Apply filters to a topic list
 * 
 * @returns An object containing filter state and methods to manipulate it
 */
export const useTopicFilters = () => {
  // Load saved filters on initial render
  const initialFilters = useMemo(() => loadFiltersFromStorage(), []);
  
  // State for all filters
  const [filters, setFilters] = useState<TopicFilters>(initialFilters);

  // Listen for storage events from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === TOPIC_FILTERS_STORAGE_KEY && event.newValue) {
        try {
          console.log('Storage changed in another tab, updating topic filters');
          const updatedFilters = JSON.parse(event.newValue) as TopicFilters;
          setFilters(prev => {
            // Only update if different to avoid loops
            if (JSON.stringify(prev) !== event.newValue) {
              return updatedFilters;
            }
            return prev;
          });
        } catch (error) {
          console.error('Error handling storage event:', error);
        }
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    saveFiltersToStorage(filters);
  }, [filters]);

  /**
   * Updates a specific filter value
   * @param key - The filter key to update
   * @param value - The new value for the filter
   */
  const updateFilter = <K extends keyof TopicFilters>(key: K, value: TopicFilters[K]) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  /**
   * Resets all filters to their default values
   */
  const clearAllFilters = () => {
    setFilters(defaultFilters);
  };

  // Helper functions for common filter operations
  const setSearchTerm = (value: string) => updateFilter('searchTerm', value);
  const setShowArchived = (value: boolean) => updateFilter('showArchived', value);
  const setShowCompleted = (value: boolean) => updateFilter('showCompleted', value);
  const setSortBy = (value: string) => updateFilter('sortBy', value);
  const setSortDirection = (value: 'asc' | 'desc') => updateFilter('sortDirection', value);
  const setViewMode = (value: 'table' | 'heatmap' | 'board') => {
    console.log(`Setting topic view mode to: ${value}`);
    // Update the view mode in state and immediately save to localStorage
    const updatedFilters = { ...filters, viewMode: value };
    setFilters(updatedFilters);
    
    // Directly save to localStorage to ensure it's saved immediately
    saveFiltersToStorage(updatedFilters);
  };
  
  /**
   * Adds a tag to the selected tags filter if not already included
   * @param tag - The tag name to add
   */
  const addTag = (tag: string) => {
    if (!filters.selectedTags.includes(tag)) {
      updateFilter('selectedTags', [...filters.selectedTags, tag]);
    }
  };
  
  /**
   * Removes a tag from the selected tags filter
   * @param tag - The tag name to remove
   */
  const removeTag = (tag: string) => {
    updateFilter('selectedTags', filters.selectedTags.filter(t => t !== tag));
  };
  
  /**
   * Adds a person to the selected persons filter if not already included
   * @param person - The person name to add
   */
  const addPerson = (person: string) => {
    if (!filters.selectedPersons.includes(person)) {
      updateFilter('selectedPersons', [...filters.selectedPersons, person]);
    }
  };
  
  /**
   * Removes a person from the selected persons filter
   * @param person - The person name to remove
   */
  const removePerson = (person: string) => {
    updateFilter('selectedPersons', filters.selectedPersons.filter(p => p !== person));
  };
  
  /**
   * Adds a state to the selected states filter if not already included
   * @param state - The state value to add
   */
  const addState = (state: string) => {
    if (!filters.selectedTopicStates.includes(state)) {
      updateFilter('selectedTopicStates', [...filters.selectedTopicStates, state]);
    }
  };
  
  /**
   * Removes a state from the selected states filter
   * @param state - The state value to remove
   */
  const removeState = (state: string) => {
    updateFilter('selectedTopicStates', filters.selectedTopicStates.filter(s => s !== state));
  };

  /**
   * Adds a heatmap status to the selected filter if not already included
   * @param status - The status value to add
   */
  const addHeatmapStatus = (status: string) => {
    if (!filters.selectedHeatmapStatuses.includes(status)) {
      updateFilter('selectedHeatmapStatuses', [...filters.selectedHeatmapStatuses, status]);
    }
  };
  
  /**
   * Removes a heatmap status from the selected filter
   * @param status - The status value to remove
   */
  const removeHeatmapStatus = (status: string) => {
    updateFilter('selectedHeatmapStatuses', filters.selectedHeatmapStatuses.filter(s => s !== status));
  };

  /**
   * Adds an importance to the selected filter if not already included
   * @param importance - The importance value to add
   */
  const addImportance = (importance: string) => {
    if (!filters.selectedImportances.includes(importance)) {
      updateFilter('selectedImportances', [...filters.selectedImportances, importance]);
    }
  };
  
  /**
   * Removes an importance from the selected filter
   * @param importance - The importance value to remove
   */
  const removeImportance = (importance: string) => {
    updateFilter('selectedImportances', filters.selectedImportances.filter(i => i !== importance));
  };
  
  /**
   * Applies all current filters to a topic list and returns the filtered and sorted result
   * @param topics - The topic list to filter and sort
   * @returns The filtered and sorted topic list
   */
  const applyFilters = (topics: Topic[]): Topic[] => {
    return topics
      .filter(topic => 
        // Text search filter
        topic.title.toLowerCase().includes(filters.searchTerm.toLowerCase()) &&
        // Archive filter
        (filters.showArchived || topic.state !== 'ARCHIVED') &&
        // Complete filter
        (filters.showCompleted || topic.state !== 'COMPLETED') &&
        // Tag filter
        (filters.selectedTags.length === 0 || filters.selectedTags.every(tag => 
          topic.tags.some(topicTag => topicTag.name === tag)
        )) &&
        // Person filter
        (filters.selectedPersons.length === 0 || filters.selectedPersons.every(personName => 
          topic.persons.some(topicPerson => topicPerson.name === personName)
        )) &&
        // State filter
        (filters.selectedTopicStates.length === 0 || filters.selectedTopicStates.includes(topic.state)) &&
        // Heatmap status filter
        (filters.selectedHeatmapStatuses.length === 0 || filters.selectedHeatmapStatuses.includes(topic.heatmap_status)) &&
        // Importance filter
        (filters.selectedImportances.length === 0 || filters.selectedImportances.includes(topic.importance?.toString()))
      )
      .sort((a, b) => {
        let result = 0;
        
        switch (filters.sortBy) {
          case 'title':
            result = a.title.localeCompare(b.title);
            break;
          case 'importance':
            result = (a.importance || 0) - (b.importance || 0);
            break;
          case 'state':
            result = a.state.localeCompare(b.state);
            break;
          case 'heatmap_status':
            result = (a.heatmap_status || '').localeCompare(b.heatmap_status || '');
            break;
          case 'created_at':
            result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            break;
          default:
            break;
        }
        
        return filters.sortDirection === 'asc' ? result : -result;
      });
  };

  // Return all filter values and methods
  return {
    filters,
    setSearchTerm,
    setShowArchived,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addPerson,
    removePerson,
    addState,
    removeState,
    addHeatmapStatus,
    removeHeatmapStatus,
    addImportance,
    removeImportance,
    clearAllFilters,
    applyFilters,
  };
}; 