import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';
import { pulseOptions } from '../../utils/entitiesOptions';

export interface PulseCount {
  name: string;
  count: number;
}

interface ProjectPulseFilterProps {
  pulses: PulseCount[];
  selectedPulses: string[];
  onPulseSelect: (pulse: string) => void;
  onPulseDeselect: (pulse: string) => void;
  className?: string;
}

export const ProjectPulseFilter: React.FC<ProjectPulseFilterProps> = ({
  pulses,
  selectedPulses,
  onPulseSelect,
  onPulseDeselect,
  className = '',
}) => {
  const mappedOptions = pulseOptions.map(option => ({
    value: option.value,
    label: option.label,
    icon: option.icon,
  }));

  return (
    <EnumFilter
      options={mappedOptions}
      selectedValues={selectedPulses}
      onSelect={onPulseSelect}
      onDeselect={onPulseDeselect}
      title="Filter by Pulse"
      variant="secondary"
      defaultExpanded={false}
      className={className}
    />
  );
}; 