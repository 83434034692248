import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Icon, IconName } from '../';
import { useUser } from '../../../contexts/UserContext';
import { usePerson } from '../../../utils/usePerson';
import { Logo } from '../../Logo';
import { logout } from '../../../services/authService';
import { BREAKPOINTS } from '../../../utils/breakpoints';
import LocalStorageUtils from '../../../utils/localStorageUtils';

interface MenuItemProps {
  to?: string;
  icon: IconName;
  label: string;
  children?: MenuItemProps[];
  onClick?: () => void;
  isCandidate?: boolean;
}

interface SidebarMenuProps {
  initialOpenMenus?: string[];
}

const MenuItem: React.FC<MenuItemProps & {
  isOpen: boolean;
  toggleOpen: (label: string) => void;
  isTopLevel?: boolean;
  collapsed: boolean;
  isCandidate?: boolean;
}> = ({ to, icon, label, children, isOpen, toggleOpen, isTopLevel = false, collapsed, onClick, isCandidate }) => {
  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleOpen(label);
  };

  if (children) {
    return (
      <div className="mb-1">
        <div className={`w-full flex items-center justify-between px-3 py-2 rounded-md hover:bg-gray-700 ${
          isCandidate ? 'opacity-75' : ''
        }`}>
          <NavLink
            to={to!}
            className="flex items-center space-x-2"
          >
            <Icon name={icon} size="sm" color="white" />
            {!collapsed && (
              <span className={`text-sm ${isTopLevel ? 'font-semibold' : 'font-medium'} ${
                isCandidate ? 'italic font-light' : ''
              } text-gray-200`}>{label}</span>
            )}
          </NavLink>
          {!collapsed && (
            <button onClick={handleToggle} className="ml-2">
              <Icon name={isOpen ? "ChevronUp" : "ChevronDown"} size="sm" color="white" />
            </button>
          )}
        </div>
        {isOpen && !collapsed && (
          <div className="ml-3 mt-1 space-y-1">
            {children.map((child, index) => (
              <MenuItem 
                key={index} 
                {...child} 
                isOpen={false} 
                toggleOpen={toggleOpen} 
                collapsed={collapsed}
                isCandidate={isCandidate || child.isCandidate} 
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={`w-full flex items-center space-x-2 px-3 py-2 rounded-md transition-all text-gray-300 hover:bg-gray-700 hover:text-white`}
      >
        <Icon name={icon} size="sm" color="white" />
        {!collapsed && (
          <span className={`${isTopLevel ? 'text-sm font-semibold' : 'text-xs font-medium'} text-gray-200`}>{label}</span>
        )}
      </button>
    );
  }

  return (
    <NavLink
      to={to!}
      className={({ isActive }) =>
        `flex items-center space-x-2 px-3 py-2 rounded-md transition-all ${
          isActive
            ? 'bg-gray-700 text-white'
            : 'text-gray-300 hover:bg-gray-700 hover:text-white'
        } ${isCandidate ? 'opacity-75' : ''}`
      }
    >
      {({ isActive }) => (
        <>
          <Icon name={icon} size="sm" color="white" />
          {!collapsed && (
            <span className={`${isTopLevel ? 'text-sm font-semibold' : 'text-xs font-medium'} ${
              isCandidate ? 'italic font-light' : ''
            } text-gray-200`}>{label}</span>
          )}
        </>
      )}
    </NavLink>
  );
};

const UserInfo: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { personId } = useUser();
  const navigate = useNavigate();
  const { data: person, isLoading } = usePerson(personId);

  return (
    <div className="flex items-center space-x-2 px-3 py-2 text-gray-300">
      <div className="flex-shrink-0">
        <Icon name="User" size="sm" color="white" />
      </div>
      {!collapsed && (
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium truncate">
            {isLoading ? 'Loading...' : person?.name || 'Unknown User'}
          </p>
          {person?.email && (
            <p className="text-xs text-gray-500 truncate">
              {person.email}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ initialOpenMenus = [] }) => {
  const [openMenu, setOpenMenu] = useState<string | null>(initialOpenMenus[0] || null);
  const [collapsed, setCollapsed] = useState(() => {
    const savedCollapsed = LocalStorageUtils.getItem<boolean>('sidebarCollapsed');
    return savedCollapsed ?? window.innerWidth < BREAKPOINTS.TABLET;
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);
  const navigate = useNavigate();
  const { clearSession } = useUser();

  const toggleOpen = (label: string) => {
    setOpenMenu(prev => (prev === label ? null : label));
  };

  const toggleCollapse = () => {
    if (isMobile) {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    } else {
      const newCollapsedState = !collapsed;
      setCollapsed(newCollapsedState);
      LocalStorageUtils.setItem('sidebarCollapsed', newCollapsedState);
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth < BREAKPOINTS.TABLET;
      setIsMobile(isMobileView);
      if (isMobileView) {
        setCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.getElementById('sidebar-menu');
      const burgerButton = document.getElementById('burger-button');
      
      if (isMobileMenuOpen && sidebar && burgerButton && 
          !sidebar.contains(event.target as Node) && 
          !burgerButton.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const menuItems: MenuItemProps[] = [
    { to: "/tasks/board", icon: "Home", label: "Dashboard" },
    {
      to: "/persons",
      icon: "Users",
      label: "Persons",
      children: [
        { to: "/persons/all", icon: "User", label: "Person List" },
        { to: "/teams", icon: "Users", label: "Team List View" },
        { to: "/persons/import", icon: "Upload", label: "Import" },
        { to: "/network", icon: "Network", label: "Network View" },
      ]
    },
    {
      to: "/tasks/all",
      icon: "ListChecks",
      label: "Tasks",
      children: [
        { to: "/tasks/all", icon: "List", label: "List View" },
        { to: "/tasks/board", icon: "Kanban", label: "Board View" },
        { to: "/tasks/overdue", icon: "AlertCircle", label: "Over Due" },
        { to: "/tasks/assigned", icon: "UserCheck", label: "Assigned to me" },
      ]
    },
    {
      to: "/topics",
      icon: "Library",
      label: "Topics",
      children: [
        { to: "/topics/all", icon: "Library", label: "All" },
        { to: "/topics/backlog", icon: "Inbox", label: "Backlog" },
        { to: "/topics/active", icon: "Play", label: "Active" },
        { to: "/topics/complete", icon: "CheckCircle", label: "Complete" },
        { to: "/topics/heatmap", icon: "Grid", label: "Heatmap View" }
      ]
    },
    {
      to: "/projects",
      icon: "Layers",
      label: "Projects",
      children: [
        { to: "/projects/all", icon: "Layers", label: "All" },
        { to: "/projects/timeline", icon: "ChartGantt", label: "Timeline View" },
        { to: "/projects/active", icon: "Play", label: "Active" },
        { to: "/projects/completed", icon: "CheckCircle", label: "Completed" },
        { to: "/snapshots", icon: "Camera", label: "Snapshots" }
      ]
    },
    {
      to: "/meeting-occurrences",
      icon: "Calendar",
      label: "Meetings",
      children: [
        { to: "/meeting-types", icon: "UserRoundCog", label: "Meeting Types" },
        { to: "/meeting-occurrences", icon: "UsersRound", label: "Meetings" },
        { to: "/meetings/upcoming", icon: "Clock", label: "Upcoming" },
        { to: "/meetings/past", icon: "Archive", label: "Past" },
      ]
    },
    {
      to: "/integrations/list",
      icon: "Plug",
      label: "Integrations",
      children: [
        { to: "/integrations/list", icon: "Plug", label: "Integration List" },
        { to: "/integrations/projects/import", icon: "Import", label: "Project Import" },
      ]
    },
    { to: "/tags", icon: "Tag", label: "Tags" },
    {
      icon: "Lightbulb",
      label: "Candidates",
      isCandidate: true,
      children: [
        {
          to: "/strategies",
          icon: "Flag",
          label: "Strategies",
          isCandidate: true,
          children: [
            { to: "/strategies/all", icon: "Flag", label: "All", isCandidate: true },
            { to: "/strategies/active", icon: "Play", label: "Active", isCandidate: true },
            { to: "/strategies/approved", icon: "CheckCircle", label: "Approved", isCandidate: true },
          ]
        },
        {
          to: "/goals",
          icon: "Goal",
          label: "Goals",
          isCandidate: true,
        },
        {
          to: "/network",
          icon: "Network",
          label: "Network",
          isCandidate: true,
        }
      ]
    }
  ];
  

  return (
    <>
      {/* Mobile Burger Menu Button */}
      {isMobile && (
        <button
          id="burger-button"
          onClick={toggleCollapse}
          className="fixed top-2 left-2 z-50 p-1.5 rounded-md bg-gray-800 text-white hover:bg-gray-700 shadow-lg"
        >
          <Icon name={isMobileMenuOpen ? "X" : "Menu"} size="sm" color="white" />
        </button>
      )}

      {/* Backdrop for mobile */}
      {isMobile && isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
      )}

      {/* Sidebar */}
      <div
        id="sidebar-menu"
        className={`${
          isMobile
            ? `fixed top-16 left-0 max-h-[calc(100vh-6rem)] z-40 transform transition-transform duration-300 ${
                isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
              }`
            : 'h-screen'
        } bg-gray-800 flex flex-col shadow-sm transition-all duration-300 ${
          collapsed && !isMobile ? 'w-12' : 'w-64'
        } overflow-hidden rounded-tr-xl rounded-br-xl`}
      >
        {/* Header Section */}
        <div className={`flex items-center border-b border-gray-700 ${
          collapsed && !isMobile ? 'justify-center px-2 py-2' : 'justify-between py-2 px-4'
        }`}>
          {(!collapsed || isMobile) && (
            <div className="flex items-center space-x-3">
              <Logo variant="dark" size="sm" className="flex-shrink-0" />
              <h1 className="text-base font-semibold text-white">Align</h1>
            </div>
          )}
          {!isMobile && (
            <button 
              onClick={toggleCollapse} 
              className="text-white hover:bg-gray-700 p-1 rounded-md"
            >
              <Icon name={collapsed ? "ChevronRight" : "ChevronLeft"} size="sm" color="white" />
            </button>
          )}
        </div>

        {/* Navigation Section */}
        <nav className="flex-1 px-2 py-1 space-y-0.5 overflow-y-auto">
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              {...item}
              isOpen={openMenu === item.label}
              toggleOpen={toggleOpen}
              isTopLevel={true}
              collapsed={collapsed && !isMobile}
            />
          ))}
        </nav>

        {/* Footer Section */}
        <div className="border-t border-gray-700 flex flex-col items-center py-1">
          <UserInfo collapsed={collapsed && !isMobile} />
          <MenuItem
            to="/integrations/list"
            icon="Settings"
            label="Settings"
            isOpen={false}
            toggleOpen={toggleOpen}
            isTopLevel={true}
            collapsed={collapsed && !isMobile}
          />
          <MenuItem
            icon="LogOut"
            label="Logout"
            isOpen={false}
            toggleOpen={toggleOpen}
            isTopLevel={true}
            collapsed={collapsed && !isMobile}
            onClick={handleLogout}
          />
        </div>
      </div>
    </>
  );
};

export default SidebarMenu;