import React, { useState, useEffect, useRef, ReactNode, useLayoutEffect } from 'react';
import { Icon, IconName } from './';

export interface ContextMenuItem {
  id: string;
  label: string;
  icon?: IconName;
  disabled?: boolean;
  onClick?: () => void;
  separator?: boolean;
  submenu?: ContextMenuItem[];
  className?: string;
}

export interface ContextMenuGroup {
  title?: string;
  items: ContextMenuItem[];
}

interface ContextMenuProps {
  groups: ContextMenuGroup[];
  position: { x: number; y: number } | null;
  onClose: () => void;
  className?: string;
}

interface SubmenuState {
  id: string;
  position: { x: number; y: number };
}

export const ContextMenu: React.FC<ContextMenuProps> = ({ 
  groups, 
  position, 
  onClose,
  className = '' 
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRefs = useRef<Map<string, HTMLDivElement>>(new Map());
  const [activeSubmenus, setActiveSubmenus] = useState<SubmenuState[]>([]);
  
  // Reset active submenus when context menu position changes
  useEffect(() => {
    setActiveSubmenus([]);
  }, [position]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if click is on the main menu
      const isClickInMainMenu = menuRef.current && menuRef.current.contains(event.target as Node);
      
      // Check if click is on any active submenu
      let isClickInSubmenus = false;
      submenuRefs.current.forEach((submenuRef) => {
        if (submenuRef.contains(event.target as Node)) {
          isClickInSubmenus = true;
        }
      });
      
      if (!isClickInMainMenu && !isClickInSubmenus) {
        // If click is outside both main menu and all submenus, close everything
        onClose();
      } else if (!isClickInSubmenus && activeSubmenus.length > 0) {
        // If click is on main menu but outside submenus, close all submenus
        setActiveSubmenus([]);
      }
    };

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (activeSubmenus.length > 0) {
          // Close the last opened submenu first
          setActiveSubmenus(prev => prev.slice(0, -1));
        } else {
          onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose, activeSubmenus]);

  if (!position) return null;

  const isSubmenuActive = (itemId: string) => {
    return activeSubmenus.some(submenu => submenu.id === itemId);
  };

  const handleItemClick = (item: ContextMenuItem, event: React.MouseEvent) => {
    if (item.disabled) return;
    
    if (item.submenu) {
      event.stopPropagation();
      
      // Check if this submenu is already active
      const isActive = isSubmenuActive(item.id);
      
      if (isActive) {
        // Close this submenu and any deeper ones
        setActiveSubmenus(prev => 
          prev.filter(submenu => submenu.id !== item.id)
        );
      } else {
        // Calculate submenu position based on parent item
        const itemElement = event.currentTarget as HTMLElement;
        if (itemElement) {
          const rect = itemElement.getBoundingClientRect();
          
          // Position directly adjacent to the parent (no gap)
          const newSubmenuPosition = {
            x: rect.right-40,
            y: rect.top
          };
          
          // Add this submenu to active submenus
          setActiveSubmenus([{ id: item.id, position: newSubmenuPosition }]);
        }
      }
    } else if (item.onClick) {
      item.onClick();
      onClose();
    }
  };

  const MenuItem: React.FC<{ 
    item: ContextMenuItem,
    depth?: number
  }> = ({ item, depth = 0 }) => {
    if (item.separator) {
      return <div className="h-px bg-gray-200 my-1" />;
    }

    const isActive = isSubmenuActive(item.id);
    
    return (
      <div
        id={`context-menu-item-${item.id}`}
        className={`
          flex items-center justify-between px-3 py-1.5 text-sm rounded-md 
          ${!item.disabled ? 'hover:bg-gray-100 cursor-pointer' : 'opacity-50 cursor-not-allowed'} 
          ${isActive ? 'bg-gray-100' : ''}
          ${item.className || ''}
        `}
        onClick={(e) => handleItemClick(item, e)}
      >
        <div className="flex items-center space-x-2">
          {item.icon && <Icon name={item.icon} size="sm" />}
          <span>{item.label}</span>
        </div>
        {item.submenu && (
          <Icon name="ChevronRight" size="sm" />
        )}
      </div>
    );
  };

  const renderSubmenu = (activeSubmenu: SubmenuState) => {
    // Find the parent item
    let parentItem: ContextMenuItem | undefined;
    
    for (const group of groups) {
      const foundItem = findItemById(group.items, activeSubmenu.id);
      if (foundItem) {
        parentItem = foundItem;
        break;
      }
    }
    
    if (!parentItem || !parentItem.submenu) return null;

    // Calculate submenu position to ensure it doesn't go off-screen
    const { innerWidth, innerHeight } = window;
    const { x, y } = activeSubmenu.position;
    
    // Estimate submenu width and height (can be refined with actual measurements)
    const estimatedWidth = 200;
    const estimatedHeight = parentItem.submenu.length * 36;
    
    let submenuX = x;
    let submenuY = y;
    
    // Adjust horizontally if menu would go off-screen
    if (x + estimatedWidth > innerWidth) {
      // Position to the left of the parent item instead, directly adjacent
      const parentElement = document.getElementById(`context-menu-item-${activeSubmenu.id}`);
      if (parentElement) {
        const parentRect = parentElement.getBoundingClientRect();
        submenuX = parentRect.left - estimatedWidth;
      } else {
        submenuX = Math.max(0, x - estimatedWidth);
      }
    }
    
    // Adjust vertically if menu would go off-screen
    if (y + estimatedHeight > innerHeight) {
      // Adjust up so the bottom doesn't go off-screen
      submenuY = Math.max(0, innerHeight - estimatedHeight - 5);
    }

    return (
      <div 
        ref={el => {
          if (el) submenuRefs.current.set(activeSubmenu.id, el);
        }}
        className="absolute bg-white border border-gray-200 rounded-md shadow-lg py-1 min-w-[180px] z-50"
        style={{ 
          top: `${submenuY}px`, 
          left: `${submenuX}px`,
          // Add subtle scale-in transition
          animation: 'scaleIn 0.15s ease-out forwards',
        }}
      >
        {parentItem.submenu.map((subItem) => (
          <MenuItem key={subItem.id} item={subItem} depth={1} />
        ))}
      </div>
    );
  };

  // Helper function to find an item by ID in a nested structure
  const findItemById = (items: ContextMenuItem[], id: string): ContextMenuItem | undefined => {
    for (const item of items) {
      if (item.id === id) {
        return item;
      }
      if (item.submenu) {
        const found = findItemById(item.submenu, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  // Adjust position to ensure the menu doesn't go off-screen
  const adjustedPosition = () => {
    if (!position || !menuRef.current) return position;
    
    const { x, y } = position;
    const { innerWidth, innerHeight } = window;
    const menuRect = menuRef.current.getBoundingClientRect();
    
    let adjustedX = x;
    let adjustedY = y;
    
    // Adjust horizontally if menu would go off-screen
    if (x + menuRect.width > innerWidth) {
      adjustedX = innerWidth - menuRect.width - 10;
    }
    
    // Adjust vertically if menu would go off-screen
    if (y + menuRect.height > innerHeight) {
      adjustedY = innerHeight - menuRect.height - 10;
    }
    
    return { x: adjustedX, y: adjustedY };
  };

  const pos = adjustedPosition();

  return (
    <>
      <style dangerouslySetInnerHTML={{
        __html: `
          @keyframes scaleIn {
            from { transform: scale(0.95); opacity: 0; }
            to { transform: scale(1); opacity: 1; }
          }
        `
      }} />
      <div 
        ref={menuRef}
        className={`absolute bg-white border border-gray-200 rounded-md shadow-lg py-1 min-w-[180px] z-50 ${className}`}
        style={{ 
          top: pos ? `${pos.y}px` : '0px', 
          left: pos ? `${pos.x}px` : '0px',
          animation: 'scaleIn 0.15s ease-out forwards',
        }}
      >
        {groups.map((group, groupIndex) => (
          <div key={groupIndex} className="mb-1 last:mb-0">
            {group.title && (
              <div className="px-3 py-1 text-xs font-medium text-gray-500 select-none">
                {group.title}
              </div>
            )}
            <div>
              {group.items.map((item) => (
                <MenuItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        ))}
      </div>
      
      {/* Render active submenus */}
      {activeSubmenus.map((submenu) => renderSubmenu(submenu))}
    </>
  );
};

export default ContextMenu; 