import React from 'react';
import { FrequencyFilter, FrequencyItem } from '../ui/base/FrequencyFilter';

export type Tag = FrequencyItem;

interface TagFilterProps {
  tags: Tag[];
  selectedTags: string[];
  onTagSelect: (tagName: string) => void;
  onTagDeselect: (tagName: string) => void;
  className?: string;
  defaultExpanded?: boolean;
}

export const TagFilter: React.FC<TagFilterProps> = ({
  tags,
  selectedTags,
  onTagSelect,
  onTagDeselect,
  className = '',
  defaultExpanded = false,
}) => {
  return (
    <FrequencyFilter
      items={tags}
      selectedItems={selectedTags}
      onItemSelect={onTagSelect}
      onItemDeselect={onTagDeselect}
      className={className}
      variant="primary"
      icon="Tag"
      title="Filter by Tag"
      defaultExpanded={defaultExpanded}
    />
  );
}; 