import React from 'react';
import { Strategy } from '../../api/strategy/types';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import { 
  TableActionButtons, 
  TableBadgeComponent, 
  TablePersonComponent, 
  TablePersonsComponent, 
  TableTagsComponent,
  TableInlineTextInput,
} from '../ui';
import { strategyStateOptions } from '../../utils/entitiesOptions';

interface StrategyTableMobileProps {
  data: Strategy[];
  onEdit: (strategy: Strategy) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Strategy['state']) => void;
  onUpdateTitle: (slug: string, newTitle: string) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdateCollaborators: (slug: string, newCollaborators: Person[]) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
}

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const foundOption = options.find(option => option.value === value);
  return foundOption ? foundOption.label : value;
};

export const StrategyTableMobile: React.FC<StrategyTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateTitle,
  onUpdateTags,
  onUpdateCollaborators,
  onUpdateOwner,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((strategy) => (
        <div 
          key={strategy.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div className="flex flex-col space-y-1">
            <div className="flex items-center justify-between">
              <div className="flex-grow">
                <div className="text-sm font-medium cursor-pointer hover:text-primary">
                  <TableInlineTextInput
                    initialValue={strategy.title}
                    onSave={(newTitle) => onUpdateTitle(strategy.slug, newTitle)}
                    onCancel={() => {}}
                  />
                </div>
              </div>
              <TableBadgeComponent
                label={getLabelForValue(strategy.state, strategyStateOptions)}
                type="strategyState"
                onUpdate={(newState) => onUpdateState(strategy.slug, newState as Strategy['state'])}
                options={strategyStateOptions}
                isEditable={strategy.state !== 'APPROVED'}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-500">Owner:</span>
              <TablePersonComponent
                person={strategy.owner ?? null}
                isEditable={strategy.state !== 'APPROVED'}
                onUpdate={(newOwner) => onUpdateOwner(strategy.slug, newOwner)}
              />
            </div>

            <div className="flex items-center justify-between text-xs text-gray-500">
              <span>Last Updated:</span>
              <span>{new Date(strategy.updated_at).toLocaleDateString()}</span>
            </div>

            <div className="flex items-center justify-end">
              <TableActionButtons
                onEdit={() => onEdit(strategy)}
                onDelete={() => onDelete(strategy.slug)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 