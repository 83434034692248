import React from 'react';
import { Task } from '../../api/task/types';
import { Table, TableDateComponent, TableActionButtons, TableBadgeComponent, TablePersonsComponent, TableTagsComponent, TablePersonComponent, TableInlineTextInput } from '../ui';
import { taskStateOptions, dialStageOptions, taskPriorityOptions } from '../../utils/entitiesOptions'; // Import from entitiesOptions
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import TaskContextMenu from './TaskContextMenu';
import useContextMenu from '../../hooks/useContextMenu';

interface TaskTableProps {
  data: Task[];
  onEdit: (task: Task) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Task['state']) => void;
  onUpdateDialStage: (slug: string, newDialStage: Task['dial_stage']) => void;
  onUpdatePriority: (slug: string, newPriority: Task['priority']) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  onUpdateStartDate: (slug: string, newDate: string) => void;
  onUpdateDueDate: (slug: string, newDate: string) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdateTitle: (slug: string, newTitle: string) => void;
}

// Utility function to get the human-readable label from task state options
const getTaskStateLabel = (state: string): string => {
  const foundOption = taskStateOptions.find(option => option.value === state);
  return foundOption ? foundOption.label : 'Unknown';
};

// Utility function to get the human-readable label from dial stage options
const getDialStageLabel = (dialStage: string): string => {
  const foundOption = dialStageOptions.find(option => option.value === dialStage);
  return foundOption ? foundOption.label : 'Unknown';
};

// Utility function to get the human-readable label from priority options
const getTaskPriorityLabel = (priority: string): string => {
  const foundOption = taskPriorityOptions.find(option => option.value === priority);
  return foundOption ? foundOption.label : 'Unknown';
};

export const TaskTable: React.FC<TaskTableProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateDialStage,
  onUpdatePriority,
  onUpdateOwner,
  onUpdateStartDate,
  onUpdateDueDate,
  onUpdateTags,
  onUpdateTitle
}) => {
  const { position, contextTarget, handleContextMenu, closeContextMenu } = useContextMenu();

  // Handle updates from context menu
  const handleContextMenuUpdate = (taskSlug: string, updates: Partial<Task>) => {
    if (updates.state) {
      onUpdateState(taskSlug, updates.state);
    }
    if (updates.dial_stage) {
      onUpdateDialStage(taskSlug, updates.dial_stage);
    }
    if (updates.priority) {
      onUpdatePriority(taskSlug, updates.priority);
    }
    closeContextMenu();
  };

  return (
    <div className="relative" onContextMenu={(e) => e.preventDefault()}>
      <Table
        data={data}
        columns={[
          {
            header: 'Title',
            accessor: 'title',
            component: (row) => (
              <div
                onClick={() => onEdit(row)}
                className="cursor-pointer hover:underline"
              >
                <TableInlineTextInput
                  initialValue={row.title}
                  onSave={(newTitle) => onUpdateTitle(row.slug, newTitle)}
                  onCancel={() => {}}
                />
              </div>
            ),
          },        
          {
            header: 'State',
            component: (row) => (
              <TableBadgeComponent
                label={getTaskStateLabel(row.state)}  // Use human-readable label
                type="state"
                onUpdate={(newState) => onUpdateState(row.slug, newState as Task['state'])}
                options={taskStateOptions}  // Use options from entitiesOptions
                isEditable={true}
              />
            ),
          },
          {
            header: 'Priority',
            component: (row) => (
              <TableBadgeComponent
                label={getTaskPriorityLabel(row.priority)}
                type="priority"
                onUpdate={(newPriority) => onUpdatePriority(row.slug, newPriority as Task['priority'])}
                options={taskPriorityOptions}
                isEditable={true}
              />
            ),
          },
          {
            header: 'Dial Stage',
            component: (row) => (
              <TableBadgeComponent
                label={getDialStageLabel(row.dial_stage)}  // Use human-readable label
                type="dial_stage"
                onUpdate={(newDialStage) => onUpdateDialStage(row.slug, newDialStage as Task['dial_stage'])}
                options={dialStageOptions}  // Use options from entitiesOptions
                isEditable={true}
              />
            ),
          },
          {
            header: 'Owner',
            component: (row) => (
              <TablePersonComponent
                person={row.owner}
                isEditable={true}
                onUpdate={(newOwner) => onUpdateOwner(row.slug, newOwner)}
              />
            ),
          },
          {
            header: 'Start Date',
            component: (row) => (
              <TableDateComponent
                dateString={row.start_date}
                isEditable={true}
                onUpdate={(newDate) => onUpdateStartDate(row.slug, newDate)}
              />
            ),
          },
          {
            header: 'Due Date',
            component: (row) => (
              <TableDateComponent
                dateString={row.due_date}
                isEditable={true}
                onUpdate={(newDate) => onUpdateDueDate(row.slug, newDate)}
              />
            ),
          },
          {
            header: 'Tags',
            component: (row) => (
              <TableTagsComponent
                tags={row.tags}
                isEditable={true}
                onUpdate={(newTags) => onUpdateTags(row.slug, newTags)}
              />
            ),
          },
          {
            header: 'Created At',
            component: (row) => <TableDateComponent dateString={row.created_at} showTime={false} />,
          },
          {
            header: 'Actions',
            component: (row) => (
              <TableActionButtons
                onEdit={() => onEdit(row)}
                onDelete={() => onDelete(row.slug)}
              />
            ),
          },
        ]}
        onRowContextMenu={(e, row) => handleContextMenu(e, row)}
      />

      {/* Context Menu */}
      <TaskContextMenu
        task={contextTarget}
        position={position}
        onClose={closeContextMenu}
        onUpdate={handleContextMenuUpdate}
      />
    </div>
  );
};
