import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TaskContainer from '../containers/TaskContainer';
import { TaskTable } from '../components/task/TaskTable';
import { TaskTableMobile } from '../components/task/TaskTableMobile';
import { TaskModal } from '../components/task/TaskModal';
import { Modal, TableHeader, Snackbar, Button } from '../components/ui';
import { Task } from '../api/task/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { SeparatorHorizontal } from '../components/ui';
import { useUser } from '../contexts/UserContext';
import { TaskBoardView } from '../components/task/TaskBoardView'
import { TaskStateBoardView } from '../components/task/TaskStateBoardView';
import { Project } from '../api/project/types';
import { Topic } from '../api/topic/types';
import { Tag as TagFilterType } from '../components/tag/TagFilter';
import { PersonCount } from '../components/person/PersonsFilter';
import { taskStateOptions, taskPriorityOptions } from '../utils/entitiesOptions';
import { BREAKPOINTS } from '../utils/breakpoints';
import { useTaskFilters } from '../hooks/useTaskFilters';

const TaskListPage: React.FC = () => {
  const { personId } = useUser(); // Use user ID for the assigned filter
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<string | null>(null);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);
  
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  
  // Use the task filters hook
  const {
    filters,
    setSearchTerm,
    setShowArchived,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addOwner,
    removeOwner,
    addState,
    removeState,
    addPriority,
    removePriority,
    clearAllFilters,
    applyFilters
  } = useTaskFilters();

  // Capture the initial view mode
  const initialViewModeRef = useRef(filters.viewMode);
  const isInitialLoadRef = useRef(true);
  
  // Combined effect for handling view mode and navigation
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const viewSegment = pathSegments[pathSegments.length - 1];
    const isFilterPath = ['all', 'backlog', 'active', 'complete'].includes(viewSegment);

    // Determine the current view mode from the URL
    const urlViewMode = 
      viewSegment === "board" || location.pathname.includes("/tasks/board") 
        ? 'weekly' 
        : viewSegment === "state" || location.pathname.includes("/tasks/state") 
          ? 'state' 
          : 'table';

    // On initial load, check if we need to redirect
    if (isInitialLoadRef.current) {
      isInitialLoadRef.current = false;

      const isRootTasksPath = 
        location.pathname === '/tasks/' || 
        location.pathname === '/tasks' ||
        (
          ['all', 'backlog', 'active', 'complete'].some(
            path => location.pathname === path
          ) && 
          !location.pathname.includes('/board') && 
          !location.pathname.includes('/state')
        );

      // Only redirect if:
      // 1. We're on a root path
      // 2. The initial view mode is different from the URL view mode
      // 3. The initial view mode is not 'table' (default)
      if (isRootTasksPath && urlViewMode !== initialViewModeRef.current && initialViewModeRef.current !== 'table') {
        let basePath = '/tasks';
        if (isFilterPath) {
          basePath = `/tasks/${viewSegment}`;
        }

        const redirectPath = initialViewModeRef.current === 'weekly' 
          ? `${basePath}/board`
          : initialViewModeRef.current === 'state'
            ? `${basePath}/state`
            : basePath;

        navigate(redirectPath, { replace: true });
        return;
      }
    }

    // Update view mode based on URL if not a filter path
    if (!isFilterPath && !showModal && filters.viewMode !== urlViewMode) {
      setViewMode(urlViewMode);
    }
  }, [location.pathname, showModal, filters.viewMode, setViewMode, navigate]);

  useEffect(() => {
    if (slug && tasks.length > 0) {
      const task = tasks.find((task) => task.slug === slug);
      if (task) {
        setTaskToEdit(task);
        setShowModal(true);
      }
    }
  }, [slug, tasks]);

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => {
          navigate('/tasks/');
          setViewMode('table');
        }}
        variant={filters.viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => {
          navigate('/tasks/board');
          setViewMode('weekly');
        }}
        variant={filters.viewMode === 'weekly' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Weekly View"
        iconColor="white"
      />
      <Button
        icon="Columns"
        onClick={() => {
          navigate('/tasks/state');
          setViewMode('state');
        }}
        variant={filters.viewMode === 'state' ? 'primary' : 'secondary'}
        iconSize="md"
        title="State View"
        iconColor="white"
      />
    </>
  );
  
  // Apply path-based filters for special routes
  useEffect(() => {
    const path = location.pathname;
    console.log('Applying path-based filters for:', path);
    
    // Extract the current view mode segment from the path
    let currentViewSegment = '';
    if (path.includes('/board')) {
      currentViewSegment = '/board';
    } else if (path.includes('/state')) {
      currentViewSegment = '/state';
    }
    
    // Only apply path-based filters if explicitly navigating to filter pages
    // but preserve the current view mode (board, state, or table)
    if (path.includes("/tasks/backlog")) {
      removeState("all");
      addState("BACKLOG");
      setShowCompleted(false);
    } else if (path.includes("/tasks/active")) {
      removeState("all");
      addState("IN_PROGRESS");
      setShowCompleted(false);
    } else if (path.includes("/tasks/complete")) {
      removeState("all");
      addState("COMPLETED");
      setShowCompleted(true);
    } else if (path.includes("/tasks/all")) {
      // Clear state filters to show all
      removeState("BACKLOG");
      removeState("IN_PROGRESS");
      removeState("COMPLETED");
    }
  }, [location.pathname, addState, removeState, setShowCompleted]);

  const handleShowCompletedToggle = () => {
    setShowCompleted(!filters.showCompleted);
    if (!filters.showCompleted) {
      removeState("all");
      addState("COMPLETED");
    } else {
      removeState("COMPLETED");
    }
  };

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Task title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: Task['state'],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Task state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateDialStage = (
    slug: string,
    newDialStage: Task['dial_stage'],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { dial_stage: newDialStage });
    setSnackbarMessage('Task dial stage updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePriority = (
    slug: string,
    newPriority: Task['priority'],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { priority: newPriority });
    setSnackbarMessage('Task priority updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateOwner = (
    slug: string,
    newOwner: Person | null,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { owner: newOwner || undefined });
    setSnackbarMessage('Task owner updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const validateDates = (task: Task, field: 'start_date' | 'due_date', newDate: string): boolean => {
    const startDate = field === 'start_date' ? newDate : task.start_date;
    const dueDate = field === 'due_date' ? newDate : task.due_date;

    if (startDate && dueDate && new Date(startDate) > new Date(dueDate)) {
      setSnackbarMessage('Start date cannot be after due date');
      setSnackbarType('error');
      setShowSnackbar(true);
      return false;
    }
    return true;
  };

  const handleUpdateStartDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    const task = tasks.find(t => t.slug === slug);
    if (task && validateDates(task, 'start_date', newDate)) {
      onEdit(slug, { start_date: newDate });
      setSnackbarMessage('Task start date updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
  };

  const handleUpdateDueDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    const task = tasks.find(t => t.slug === slug);
    if (task && validateDates(task, 'due_date', newDate)) {
      onEdit(slug, { due_date: newDate });
      setSnackbarMessage('Task due date updated successfully');
      setSnackbarType('success');
      setShowSnackbar(true);
    }
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedTask: Partial<Task>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Task tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setTaskToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (taskToDelete) {
      onDelete(taskToDelete);
      setShowDeleteDialog(false);
      setTaskToDelete(null);
      setSnackbarMessage('Task deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleEditTask = (task: Task) => {
    const searchParams = new URLSearchParams(location.search);
    // If there's no mode parameter, set a default (e.g., 'c' for modal)
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
    navigate(`/tasks/${task.slug}?${searchParams.toString()}`);
    setTaskToEdit(task);
    setShowModal(true);
  };
  
  const handleAddNew = () => {
    setTaskToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default to modal
    }
    navigate(`/tasks/new?${searchParams.toString()}`);
  };

  const handleSortChange = (value: string) => {
    if (value === filters.sortBy) {
      setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Dial Stage', value: 'dial_stage' },
    { label: 'Due Date', value: 'due_date' },
    { label: 'Created Date', value: 'created_at' },
  ];

  // Add computeTagFrequencies function
  const computeTagFrequencies = (tasks: Task[]): TagFilterType[] => {
    const tagCounts = new Map<string, number>();
    
    tasks.forEach(task => {
      task.tags.forEach((tag: Tag) => {
        const count = tagCounts.get(tag.name) || 0;
        tagCounts.set(tag.name, count + 1);
      });
    });

    return Array.from(tagCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  // Add tag selection handlers
  const handleTagSelect = (tagName: string) => {
    addTag(tagName);
  };

  const handleTagDeselect = (tagName: string) => {
    removeTag(tagName);
  };

  const computeOwnerFrequencies = (tasks: Task[]): PersonCount[] => {
    const ownerCounts = new Map<string, number>();
    
    tasks.forEach(task => {
      if (task.owner?.name) {
        const count = ownerCounts.get(task.owner.name) || 0;
        ownerCounts.set(task.owner.name, count + 1);
      }
    });

    return Array.from(ownerCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  const handleOwnerSelect = (personName: string) => {
    addOwner(personName);
  };

  const handleOwnerDeselect = (personName: string) => {
    removeOwner(personName);
  };

  // Add handlers for state and priority filters
  const handleStateSelect = (value: string) => {
    addState(value);
  };

  const handleStateDeselect = (value: string) => {
    removeState(value);
  };

  const handlePrioritySelect = (value: string) => {
    addPriority(value);
  };

  const handlePriorityDeselect = (value: string) => {
    removePriority(value);
  };

  // Add computeStateFrequencies function
  const computeStateFrequencies = (tasks: Task[]): { name: string; count: number }[] => {
    const stateCounts = new Map<string, number>();
    
    tasks.forEach(task => {
      if (task.state) {
        const count = stateCounts.get(task.state) || 0;
        stateCounts.set(task.state, count + 1);
      }
    });

    return Array.from(stateCounts.entries()).map(([state, count]) => ({
      name: state,
      count
    }));
  };

  // Add computePriorityFrequencies function
  const computePriorityFrequencies = (tasks: Task[]): { name: string; count: number }[] => {
    const priorityCounts = new Map<string, number>();
    
    tasks.forEach(task => {
      if (task.priority) {
        const count = priorityCounts.get(task.priority) || 0;
        priorityCounts.set(task.priority, count + 1);
      }
    });

    return Array.from(priorityCounts.entries()).map(([priority, count]) => ({
      name: priority,
      count
    }));
  };

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINTS.TABLET);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <h1 className="m-4">
        {filters.viewMode === 'table' 
          ? "Tasks" 
          : filters.viewMode === 'weekly'
          ? "Tasks - Weekly View"
          : "Tasks - State View"}
      </h1>
      <SeparatorHorizontal />

      <div className="flex flex-col gap-4">
        <TableHeader
          onSearchChange={handleSearchChange}
          onAddNew={handleAddNew}
          onShowArchive={() => setShowArchived(!filters.showArchived)}
          onShowComplete={handleShowCompletedToggle}
          searchPlaceholder="Search Tasks..."
          addButtonLabel="Add New Task"
          showArchived={filters.showArchived}
          sortOptions={sortOptions}
          onSortChange={handleSortChange}
          currentSortValue={filters.sortBy}
          sortDirection={filters.sortDirection}
          onDirectionToggle={() => setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc')}
          additionalButtons={additionalButtons}
          tags={computeTagFrequencies(tasks)}
          selectedTags={filters.selectedTags}
          onTagSelect={handleTagSelect}
          onTagDeselect={handleTagDeselect}
          owners={computeOwnerFrequencies(tasks)}
          selectedOwners={filters.selectedOwner}
          onOwnerSelect={handleOwnerSelect}
          onOwnerDeselect={handleOwnerDeselect}
          states={computeStateFrequencies(tasks)}
          selectedStates={filters.selectedState}
          onStateSelect={handleStateSelect}
          onStateDeselect={handleStateDeselect}
          priorities={computePriorityFrequencies(tasks)}
          selectedPriorities={filters.selectedPriority}
          onPrioritySelect={handlePrioritySelect}
          onPriorityDeselect={handlePriorityDeselect}
          onClearAllFilters={clearAllFilters}
          showFilterButton={true}
        />
      </div>

      <TaskContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading tasks...</p>
            </div>
          );
          
          if (error) return <div>{error}</div>;

          if (tasks.length === 0 && data.length > 0) {
            setTasks(data);
          }

          // Use the applyFilters function from the hook
          const filteredAndSortedTasks = applyFilters(data);

          return (
            <>
              {showModal && (
                <TaskModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    // Redirect to the path that corresponds to the current view mode 
                    const path = filters.viewMode === 'weekly' 
                      ? '/tasks/board' 
                      : filters.viewMode === 'state' 
                        ? '/tasks/state' 
                        : '/tasks/';
                    navigate(path);
                  }}
                  initialData={taskToEdit ? { ...taskToEdit } : undefined}
                  onSuccess={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('success');
                    setShowSnackbar(true);
                  }}
                  onError={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this task?</p>
              </Modal>

              {filters.viewMode === 'table' ? (
                isMobile ? (
                  <TaskTableMobile
                    data={filteredAndSortedTasks}
                    onEdit={handleEditTask}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                  />
                ) : (
                  <TaskTable
                    data={filteredAndSortedTasks}
                    key={filteredAndSortedTasks.map((task) => task.slug).join()}
                    onEdit={handleEditTask}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                    onUpdateDialStage={(slug, newDialStage) => handleUpdateDialStage(slug, newDialStage, onEdit)}
                    onUpdatePriority={(slug, newPriority) => handleUpdatePriority(slug, newPriority, onEdit)}
                    onUpdateOwner={(slug, newOwner) => handleUpdateOwner(slug, newOwner, onEdit)}
                    onUpdateStartDate={(slug, newDate) => handleUpdateStartDate(slug, newDate, onEdit)}
                    onUpdateDueDate={(slug, newDate) => handleUpdateDueDate(slug, newDate, onEdit)}
                    onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                    onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                  />
                )
              ) : filters.viewMode === 'weekly' ? (
                <TaskBoardView 
                  tasks={filteredAndSortedTasks}
                  onOpenTask={(task) => {
                    setTaskToEdit(task);
                    setShowModal(true);
                    navigate(`/tasks/${task.slug}`);
                  }}
                  onEdit={onEdit}
                />
              ) : (
                <TaskStateBoardView
                  tasks={filteredAndSortedTasks}
                  onOpenTask={(task) => {
                    setTaskToEdit(task);
                    setShowModal(true);
                    navigate(`/tasks/${task.slug}`);
                  }}
                  onEdit={onEdit}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default TaskListPage;
