import React from 'react';
import { Task } from '../../api/task/types';
import { TableBadgeComponent, TableActionButtons } from '../ui';
import { taskStateOptions } from '../../utils/entitiesOptions';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';

interface TaskTableMobileProps {
  data: Task[];
  onEdit: (task: Task) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Task['state']) => void;
}

// Utility function to get the human-readable label from task state options
const getTaskStateLabel = (state: string): string => {
  const foundOption = taskStateOptions.find(option => option.value === state);
  return foundOption ? foundOption.label : 'Unknown';
};

export const TaskTableMobile: React.FC<TaskTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((task) => (
        <div 
          key={task.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(task)}
          >
            {task.title}
          </div>
          <div className="flex items-center justify-between">
            <TableBadgeComponent
              label={getTaskStateLabel(task.state)}
              type="state"
              onUpdate={(newState) => onUpdateState(task.slug, newState as Task['state'])}
              options={taskStateOptions}
              isEditable={true}
            />
            <TableActionButtons
              onEdit={() => onEdit(task)}
              onDelete={() => onDelete(task.slug)}
            />
          </div>
        </div>
      ))}
    </div>
  );
}; 