import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { ModalContent, SelectSingle } from '../ui';
import { Goal } from '../../api/goal/types';
import { Person } from '../../api/person/types';
import { Tag } from '../../api/tag/types';
import { Team } from '../../api/team/types';
import TeamSelect from '../team/TeamSelect';
import PersonSelectMulti from '../person/PersonSelectMulti';
import PersonSelect from '../person/PersonSelect';
import TagSelect from '../tags/TagSelect';
import LogEntryActivityFeed from '../logEntry/LogEntryActivityFeed';
import { Icon } from '../ui/base/Icon';
import EditableProgressBar from '../ui/EditableProgressBar';
import FavoriteContainer from '../../containers/FavoriteContainer';
import { useDescriptionDraft } from '../../hooks/useDescriptionDraft';

interface GoalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (goalData: Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>, slug?: string) => void;
  onEdit: (slug: string, updatedGoal: Partial<Goal>) => void;
  onDelete?: (slug: string) => void;
  initialData?: Omit<Goal, 'created_at' | 'updated_at'>;
}

const goalStateOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'ACHIEVED', label: 'Achieved' },
  { value: 'MISSED', label: 'Missed' },
  { value: 'CANCELED', label: 'Canceled' }
];

const timeframeOptions = [
  { value: 'ANNUAL', label: 'Annual' },
  { value: 'QUARTERLY', label: 'Quarterly' }
];

const quarterOptions = [
  { value: '1', label: 'Q1' },
  { value: '2', label: 'Q2' },
  { value: '3', label: 'Q3' },
  { value: '4', label: 'Q4' }
] as const;

const currentYear = new Date().getFullYear();
const yearOptions = Array.from({ length: 5 }, (_, i) => {
  const year = currentYear + i;
  return {
    value: year.toString(),
    label: year.toString()
  };
});

export const GoalModal: React.FC<GoalModalProps> = ({
  isOpen,
  onClose,
  onSave,
  onEdit,
  onDelete,
  initialData,
}) => {
  const {
    description,
    hasUnsavedDraft,
    handleDescriptionChange,
    clearDraft
  } = useDescriptionDraft({
    entityType: 'goal',
    entityId: initialData?.id,
    initialDescription: initialData?.description || '',
    isOpen
  });

  const [goalData, setGoalData] = useState<Partial<Goal>>({
    title: '',
    description: '',
    timeframe: 'ANNUAL',
    year: new Date().getFullYear(),
    quarter: null,
    state: 'DRAFT',
    progress: 0,
    owner: null,
    team: null,
    participants: [],
    projects: [],
    topics: [],
    tags: [],
    company: '',
    visibility: 'private',
    editable_visibility: false,
  });

  const debouncedEdit = useCallback(
    debounce((slug: string, updates: Partial<Goal>) => {
      onEdit(slug, updates);
    }, 500),
    [onEdit]
  );

  // Update goalData when description changes
  useEffect(() => {
    setGoalData(prev => ({
      ...prev,
      description
    }));
  }, [description]);

  // Update goalData with initialData
  useEffect(() => {
    if (initialData) {
      setGoalData(prev => ({
        ...initialData,
        description // Use description from the hook instead of initialData.description
      }));
    }
  }, [initialData, description]);

  const handleChange = useCallback(
    (field: keyof Goal, value: any) => {
      setGoalData(prev => ({ ...prev, [field]: value }));

      if (initialData?.slug) {
        if (field === 'title' || field === 'progress') {
          debouncedEdit(initialData.slug, { [field]: value });
        } else {
          onEdit(initialData.slug, { [field]: value });
        }
      }
    },
    [initialData, debouncedEdit, onEdit]
  );

  const handleSave = () => {
    if (!goalData.title) return alert('Please provide a title');
    if (!goalData.owner) return alert('Please select an owner');
    if (goalData.timeframe === 'QUARTERLY' && !goalData.quarter) {
      return alert('Please select a quarter');
    }
    
    clearDraft();
    onSave(goalData as Omit<Goal, 'id' | 'created_at' | 'updated_at' | 'slug'>, initialData?.slug);
    onClose();
  };

  const handleDelete = () => {
    if (initialData?.slug && onDelete) {
      clearDraft();
      onDelete(initialData.slug);
      onClose();
    }
  };

  const handleVisibilityToggle = (isPublic: boolean) => {
    handleChange('visibility', isPublic ? 'public' : 'private');
  };

  useEffect(() => {
    return () => {
      debouncedEdit.cancel();
    };
  }, [debouncedEdit]);

  if (!isOpen) return null;

  return (
    <FavoriteContainer
      type="goals.goal"
      render={({ onToggle, isFavorited }) => (
        <ModalContent
          showFavorite={!!initialData}
          isFavorited={!!initialData && isFavorited(initialData.id)}
          onFavoriteToggle={() => initialData?.id && onToggle('goals.goal', initialData.id)}
          
          title={goalData.title || ''}
          description={description}
          showWarning={hasUnsavedDraft}
          warningTooltip={hasUnsavedDraft ? 'This goal has unsaved changes to its description' : ''}
          onCancel={onClose}
          onCreate={handleSave}
          onDelete={initialData ? handleDelete : undefined}
          onTitleChange={(newTitle) => setGoalData(prev => ({ ...prev, title: newTitle }))}
          onDescriptionChange={handleDescriptionChange}
          actionButtonLabel={initialData ? 'Save Changes' : 'Create Goal'}
          showFooterButtons={true}
          selects={[
            {
              component: SelectSingle,
              props: {
                key: "timeframe-select",
                id: "timeframe-select",
                name: "timeframe",
                options: timeframeOptions,
                placeholder: 'Select Timeframe',
                defaultIcon: <Icon name="Clock" size="sm" />,
                defaultValue: goalData.timeframe,
                onSelect: (value: string) => {
                  handleChange('timeframe', value);
                  if (value === 'ANNUAL') {
                    handleChange('quarter', null);
                  }
                },
              },
            },
            {
              component: SelectSingle,
              props: {
                key: "year-select",
                id: "year-select",
                name: "year",
                options: yearOptions,
                placeholder: 'Select Year',
                defaultIcon: <Icon name="Calendar" size="sm" />,
                defaultValue: goalData.year?.toString(),
                onSelect: (value: string) => handleChange('year', parseInt(value)),
              },
            },
            ...(goalData.timeframe === 'QUARTERLY' ? [{
              component: SelectSingle,
              props: {
                key: "quarter-select",
                id: "quarter-select",
                name: "quarter",
                options: quarterOptions,
                placeholder: 'Select Quarter',
                defaultIcon: <Icon name="Clock" size="sm" />,
                defaultValue: goalData.quarter?.toString() || '',
                onSelect: (value: string) => handleChange('quarter', parseInt(value)),
              },
            }] : []),
            {
              component: SelectSingle,
              props: {
                key: "state-select",
                id: "state-select",
                name: "state",
                options: goalStateOptions,
                placeholder: 'Select State',
                defaultIcon: <Icon name="Target" size="sm" />,
                defaultValue: goalData.state,
                onSelect: (value: string) => handleChange('state', value),
              },
            },
            {
              component: PersonSelect,
              props: {
                name: "owner",
                value: goalData.owner?.id || '',
                onChange: (person: Person) => handleChange('owner', person),
                enableSearch: true,
                placeholder: 'Select an owner',
                showAssignButton: true,
              },
            },
            {
              component: TeamSelect,
              props: {
                name: "team",
                value: goalData.team?.id || '',
                onChange: (team: Team) => handleChange('team', team),
                placeholder: 'Select a team',
                enableSearch: true,
              },
            },
            {
              component: PersonSelectMulti,
              props: {
                name: "participants",
                value: goalData.participants?.map(p => p.id) || [],
                onChange: (persons: Person[]) => handleChange('participants', persons),
                enableSearch: true,
                placeholder: 'Select participants',
              },
            },
            {
              component: TagSelect,
              props: {
                name: "tags",
                value: goalData.tags?.map(t => t.id) || [],
                onChange: (tags: Tag[]) => handleChange('tags', tags),
                enableSearch: true,
                placeholder: 'Select tags',
              },
            },
            {
              component: EditableProgressBar,
              props: {
                value: goalData.progress || 0,
                onChange: (value: number) => handleChange('progress', value),
                label: 'Progress'
              },
            }
          ]}
          activityFeed={initialData ? 
            <LogEntryActivityFeed objectId={initialData.id} objectType="goal" /> 
            : null}
          showActivityFeed={!!initialData}
          onVisibilityToggle={handleVisibilityToggle}
          initialVisibility={goalData.visibility}
          isVisibilityEditable={goalData.editable_visibility}
          showSidePanel={false}
          isSidePanelInitiallyCollapsed={true}
        />
      )}
    />
  );
};