import React, { useState, useRef, useEffect } from 'react';
import { Icon, IconName } from './Icon';

export interface FrequencyItem {
  name: string;
  count: number;
}

interface FrequencyFilterProps {
  items: FrequencyItem[];
  selectedItems: string[];
  onItemSelect: (itemName: string) => void;
  onItemDeselect: (itemName: string) => void;
  className?: string;
  variant?: 'primary' | 'secondary';
  icon: IconName;
  title: string;
  defaultExpanded?: boolean;
}

export const FrequencyFilter: React.FC<FrequencyFilterProps> = ({
  items,
  selectedItems,
  onItemSelect,
  onItemDeselect,
  className = '',
  variant = 'primary',
  icon,
  title,
  defaultExpanded = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Sort items by frequency (count) in descending order
  const sortedItems = [...items].sort((a, b) => b.count - a.count);

  const handleItemClick = (itemName: string) => {
    if (selectedItems.includes(itemName)) {
      onItemDeselect(itemName);
    } else {
      onItemSelect(itemName);
    }
  };

  if (items.length === 0) {
    return null;
  }

  const getVariantClasses = (isSelected: boolean) => {
    if (variant === 'primary') {
      return isSelected 
        ? 'bg-primary text-white hover:bg-primary-dark' 
        : 'bg-background border border-neutral hover:border-primary hover:text-primary';
    }
    return isSelected 
      ? 'bg-secondary text-white hover:bg-secondary-dark' 
      : 'bg-background border border-neutral hover:border-secondary hover:text-secondary';
  };

  const getBadgeClasses = (isSelected: boolean) => {
    if (variant === 'primary') {
      return isSelected 
        ? 'bg-primary-dark text-white' 
        : 'bg-neutral text-text';
    }
    return isSelected 
      ? 'bg-secondary-dark text-white' 
      : 'bg-neutral text-text';
  };

  const getHeaderIconColor = () => {
    if (selectedItems.length > 0) {
      return variant === 'primary' ? 'primary' : 'secondary';
    }
    return 'text';
  };

  return (
    <div ref={ref} className={`relative bg-white rounded-lg border border-neutral ${className}`}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className={`w-full px-4 py-2 flex items-center justify-between text-xs font-medium
          ${selectedItems.length > 0 
            ? variant === 'primary' 
              ? 'text-primary' 
              : 'text-secondary'
            : 'text-text'
          }
        `}
      >
        <div className="flex items-center gap-2">
          <Icon 
            name={icon}
            size="sm"
            color={getHeaderIconColor()}
          />
          <span>{title}</span>
          {selectedItems.length > 0 && (
            <span className={`
              text-xxs px-1.5 py-0.5 rounded-full
              ${variant === 'primary' ? 'bg-primary' : 'bg-secondary'} text-white
            `}>
              {selectedItems.length}
            </span>
          )}
        </div>
        <Icon 
          name={isExpanded ? "ChevronUp" : "ChevronDown"}
          size="sm"
          color="text"
          className="transition-transform duration-200"
        />
      </button>

      {isExpanded && (
        <div className="absolute z-50 mt-1 bg-white border border-neutral rounded-lg shadow-lg max-h-[300px] overflow-y-auto w-[300px] -left-1">
          <div className="p-3">
            <div className="flex flex-wrap gap-2">
              {sortedItems.map((item) => {
                const isSelected = selectedItems.includes(item.name);
                return (
                  <button
                    key={item.name}
                    onClick={() => handleItemClick(item.name)}
                    className={`
                      inline-flex items-center px-2 py-1 rounded-md text-xs
                      transition-all duration-200 ease-in-out
                      ${getVariantClasses(isSelected)}
                    `}
                  >
                    <Icon 
                      name={icon}
                      size="sm"
                      color={isSelected ? "white" : "text"}
                      className="mr-1"
                    />
                    <span className="mr-1">{item.name}</span>
                    <span className={`text-xxs px-1.5 py-0.5 rounded-full ml-1 
                      ${getBadgeClasses(isSelected)}
                    `}>
                      {item.count}
                    </span>
                    {isSelected && (
                      <Icon 
                        name="X" 
                        size="sm"
                        color="white" 
                        className="ml-1 hover:scale-110 transition-transform"
                      />
                    )}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}; 