import React, { useState, useEffect, useRef } from 'react';
import { Button, SeparatorHorizontal, InputTitle, MarkDownEditor, Icon, ToggleSwitch, Tooltip } from '../../ui';
import VisibilityToggle from '../utilities/VisibilityToggle';
import Lifecycle from '../data/Lifecycle';
import { useLocation, useNavigate } from 'react-router-dom';

interface ModalContentProps {
  modalTitle?: string;
  title?: string;
  description?: string;
  selects?: Array<{ component: React.FC<any>; props: any }>;
  formFields?: Array<{ component: React.FC<any>; props: any }>;
  onCancel?: () => void;
  onCreate?: () => void;
  onDelete?: () => void;
  onTitleChange?: (value: string) => void;
  onTitleBlur?: () => void;
  onDescriptionChange?: (value: string) => void;
  onDescriptionBlur?: () => void;
  isEdit?: boolean;
  actionButtonLabel?: string;
  showDescription?: boolean;
  showTitle?: boolean;
  showActivityFeed?: boolean;
  activityFeed?: React.ReactNode;
  children?: React.ReactNode;
  showSidePanel?: boolean;
  sidePanelContent?: React.ReactNode;
  isSidePanelInitiallyCollapsed?: boolean;
  parentObjectType?: 'topic' | 'project';
  parentObjectId?: string;
  descriptionPlaceholder?: string;
  titleInputPlaceholder?: string;
  showCreateMoreToggle?: boolean;
  onCreateMoreChange?: (value: boolean) => void;
  resetForm?: () => void;
  showFooterButtons?: boolean;
  initialVisibility?: 'public' | 'private';
  onVisibilityToggle?: (isPublic: boolean) => void;
  isVisibilityEditable?: boolean;
  lifecycleStates?: string[];
  currentState?: string;
  showLifecycle?: boolean;
  descriptionSize?: "small" | "medium" | "large";
  showFavorite?: boolean;
  isFavorited?: boolean;
  onFavoriteToggle?: () => void;
  isNestedModal?: boolean;
  showWarning?: boolean;
  warningTooltip?: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  modalTitle = "",
  title = "",
  description = "",
  descriptionSize = "medium",
  selects = [],
  formFields = [],
  onCancel,
  onCreate,
  onDelete,
  onTitleChange,
  onTitleBlur,
  onDescriptionChange,
  onDescriptionBlur,
  isEdit = false,
  actionButtonLabel = "Submit",
  showDescription = true,
  showTitle = true,
  showActivityFeed = false,
  activityFeed,
  children,
  showSidePanel = false,
  sidePanelContent,
  isSidePanelInitiallyCollapsed = true,
  parentObjectType,
  parentObjectId,
  descriptionPlaceholder = "Add content...",
  titleInputPlaceholder = "Add title...",
  showCreateMoreToggle = false,
  onCreateMoreChange,
  resetForm,
  showFooterButtons = true,
  initialVisibility = 'private',
  onVisibilityToggle,
  isVisibilityEditable = false,
  lifecycleStates,
  currentState,
  showLifecycle = false,
  showFavorite = false,
  isFavorited = false,
  onFavoriteToggle,
  isNestedModal = false,
  showWarning = false,
  warningTooltip,
}) => {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [createMore, setCreateMore] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);
  const [localDescription, setLocalDescription] = useState(description);
  const location = useLocation();
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [isSelectsExpanded, setIsSelectsExpanded] = useState(false);

  const descriptionHeightClass = descriptionSize === "small"
    ? "min-h-30"
    : descriptionSize === "medium"
      ? "min-h-60"
      : "min-h-80";

  useEffect(() => {
    if (isEdit || (!localTitle && !localDescription)) {
      setLocalTitle(title);
      setLocalDescription(description);
    }
  }, [title, description, isEdit]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint in Tailwind
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleCreateMoreChange = (checked: boolean) => {
    setCreateMore(checked);
    if (onCreateMoreChange) {
      onCreateMoreChange(checked);
    }
  };

  useEffect(() => {
    setIsSidePanelOpen(!isSidePanelInitiallyCollapsed);
  }, [showSidePanel, isSidePanelInitiallyCollapsed]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onCancel) {
        onCancel();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEdit, onCancel]);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (onDelete) {
      onDelete();
    }
    setIsDeleteModalOpen(false);
  };

  const handleSubmit = () => {
    if (onCreate) {
      onCreate();
    }
    if (createMore && resetForm) {
      resetForm();
      setLocalTitle("");
      setLocalDescription("");
    }
  };

  return (
    <div
      onClick={(e) => {
        if (isNestedModal) {
          onCancel?.();
        }
      }}
      className={`
        ${isNestedModal ? 'fixed' : 'absolute'}
        inset-0
        flex justify-center items-center
        ${!isNestedModal ? 'mt-3 lg:mt-3' : ''} 
        ${isNestedModal ? 'z-[100]' : 'z-50'}
      `}
    >
      {/* Modal backdrop */}
      {isNestedModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
      )}
      {!isNestedModal && (
        <div className="absolute inset-0 bg-gray-800 z-40" />
      )}

      <div
        ref={modalRef}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
        className={`
          bg-white rounded-lg shadow-3xl z-50 flex flex-col overflow-hidden
          ${isNestedModal
            ? 'w-[60vw] max-w-[800px] min-w-[650px] max-h-[80vh]'
            : 'absolute top-0 right-3 bottom-3 left-3'} // Always fullscreen with margin
        `}
      >

        {/* Modal Header */}
        <div className="flex justify-between items-center border-b border-gray-200 p-4 relative leading-[2.25rem]">
          <div className="flex items-center space-x-4 w-full">
            <h2 className="text-xl font-semibold">{modalTitle}</h2>
            
            {showFavorite && onFavoriteToggle && (
              <button 
                onClick={onFavoriteToggle} 
                className="text-gray-500 hover:text-gray-800"
                title={isFavorited ? "Remove from favorites" : "Add to favorites"}
              >
                <Icon 
                  name={isFavorited ? "Star" : "StarOff"} 
                  size="md"
                  className={isFavorited ? "text-yellow-400 fill-yellow-400" : ""}
                />
              </button>
            )}

            {onVisibilityToggle && (
              <VisibilityToggle
                initialVisibility={initialVisibility}
                onToggle={onVisibilityToggle}
                disabled={!isVisibilityEditable}
              />
            )}

            {showLifecycle && lifecycleStates && currentState && !isMobile && (
              <div className="flex justify-center flex-1 px-2 sm:px-4">
                <div className="max-w-screen-md w-full">
                  <Lifecycle lifecycleStates={lifecycleStates} currentState={currentState} size="small" showActiveLabel={false} />
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center space-x-2 relative">
            
            {showWarning && (
              <Tooltip 
                content={warningTooltip || ''} 
                position="left" 
                variant="default"
              >
                <div className="relative px-2">
                  <Icon 
                    name="AlertTriangle" 
                    size="md" 
                    className="text-yellow-500 hover:text-yellow-600"
                  />
                </div>
              </Tooltip>
            )}

            {showSidePanel && (
              <button onClick={toggleSidePanel} className="text-gray-500 hover:text-gray-800">
                <Icon name={isSidePanelOpen ? "PanelRightClose" : "PanelRightOpen"} size="md" color="primary" />
              </button>
            )}

            {onDelete && (
              <button onClick={openDeleteModal} className="text-gray-500 hover:text-gray-800">
                <Icon name="Trash2" size="md" />
              </button>
            )}
            <button onClick={onCancel} className="text-gray-500 hover:text-gray-800">
              <Icon name="X" size="md" />
            </button>
          </div>
        </div>

        {/* Modal Body */}
        <div className="flex flex-grow overflow-hidden">
          {/* Main Content */}
          <div className={`flex-grow overflow-y-auto p-6 ${isSidePanelOpen ? 'w-2/3' : 'w-full'}`}>
            {/* Title Input */}
            {showTitle && (
              <div className="mb-4">
                <InputTitle
                  placeholder={titleInputPlaceholder}
                  value={localTitle}
                  onChange={(value) => {
                    setLocalTitle(value);
                    if (onTitleChange) onTitleChange(value);
                  }}
                  onBlur={onTitleBlur}
                />
              </div>
            )}

            {/* Dynamic Form Fields */}
            <div className="mb-4 flex flex-col gap-4">
              {formFields.map((field, index) => {
                const FormComponent = field.component;
                return <FormComponent key={index} {...field.props} />;
              })}
            </div>

            {/* Dynamic Select Components - Collapsible on Mobile */}
            {selects.length > 0 && (
              <div className="mb-4">
                {isMobile ? (
                  <div className="border rounded-lg overflow-visible">
                    <button
                      onClick={() => setIsSelectsExpanded(!isSelectsExpanded)}
                      className="w-full flex items-center justify-between p-3 bg-gray-50 hover:bg-gray-100"
                    >
                      <span className="text-sm text-gray-700">Additional Options</span>
                      <Icon
                        name={isSelectsExpanded ? "ChevronUp" : "ChevronDown"}
                        size="sm"
                        className="text-gray-500"
                      />
                    </button>
                    <div className={`${isSelectsExpanded ? 'block' : 'hidden'} p-4 relative z-50`}>
                      <div className="flex flex-wrap gap-4">
                        {selects.map((select, index) => {
                          const SelectComponent = select.component;
                          return (
                            <div key={index} className="w-full">
                              <SelectComponent {...select.props} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-4">
                    {selects.map((select, index) => {
                      const SelectComponent = select.component;
                      return <SelectComponent key={index} {...select.props} />;
                    })}
                  </div>
                )}
              </div>
            )}

            {/* Separator - only show if there's content after it */}
            {(showDescription || children) && (
              <SeparatorHorizontal className="my-6" />
            )}

            {/* Description Input */}
            {showDescription && (
              <div className={`mb-4 ${descriptionHeightClass}`}>
                <MarkDownEditor
                  placeholder={descriptionPlaceholder}
                  initialContent={localDescription}
                  onChange={(value) => {
                    setLocalDescription(value);
                    if (onDescriptionChange) onDescriptionChange(value);
                  }}
                  onBlur={onDescriptionBlur}
                  parentObjectType={parentObjectType}
                  parentObjectId={parentObjectId}
                  menuType='combined'
                />
              </div>
            )}

            {/* Render Children Here */}
            {children}

            {/* Footer */}
            <div className="border-t border-gray-200 p-4">
              <div className="flex items-center justify-between">
                {/* Create More Toggle Switch */}
                {showCreateMoreToggle && (
                  <div className="flex items-center">
                    <ToggleSwitch
                      checked={createMore}
                      onChange={handleCreateMoreChange}
                      label="Create more"
                      labelPosition="right"
                    />
                  </div>
                )}

                {/* Footer Buttons */}
                {showFooterButtons && (
                  <div className="flex space-x-4">
                    <Button
                      label="Cancel"
                      variant="secondary"
                      buttonStyle="outlined"
                      onClick={onCancel}
                    />
                    <Button
                      label={actionButtonLabel}
                      variant="primary"
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Activity Feed */}
            {showActivityFeed && (
              <div className="mt-4">
                {activityFeed}
              </div>
            )}
          </div>

          {/* Side Panel */}
          {isSidePanelOpen && (
            <div className="w-1/3 h-full overflow-y-auto flex flex-col border-l border-gray-200">
              {sidePanelContent}
            </div>
          )}
        </div>

        {/* Delete Modal */}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-60">
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
              <p>Are you sure you want to delete this item?</p>
              <div className="mt-4 flex justify-end space-x-2">
                <Button label="Cancel" variant="secondary" onClick={closeDeleteModal} />
                <Button label="Delete" variant="danger" onClick={handleDeleteConfirm} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModalContent;
