import { useState, useEffect, useMemo } from 'react';
import { Project } from '../api/project/types';
import LocalStorageUtils from '../utils/localStorageUtils';

/**
 * Interface defining all the filter options available for projects
 */
export interface ProjectFilters {
  searchTerm: string;
  showCompleted: boolean;
  selectedTags: string[];
  selectedParticipants: string[];
  selectedOwner: string[];
  selectedProjectStates: string[];
  selectedEfforts: string[];
  selectedProjectPriorities: string[];
  selectedPulses: string[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  viewMode: 'table' | 'timeline' | 'heatmap' | 'board';
}

// Storage key for project filters
const PROJECT_FILTERS_STORAGE_KEY = 'projectListFilters';

/**
 * Default values for filters when none are saved in localStorage
 */
const defaultFilters: ProjectFilters = {
  searchTerm: '',
  showCompleted: false,
  selectedTags: [],
  selectedParticipants: [],
  selectedOwner: [],
  selectedProjectStates: [],
  selectedEfforts: [],
  selectedProjectPriorities: [],
  selectedPulses: [],
  sortBy: 'priority',
  sortDirection: 'desc',
  viewMode: 'table',
};

/**
 * Loads project filters from localStorage using LocalStorageUtils
 * @returns The saved filters or default values if none exist
 */
const loadFiltersFromStorage = (): ProjectFilters => {
  try {
    const savedFilters = LocalStorageUtils.getItem<ProjectFilters>(PROJECT_FILTERS_STORAGE_KEY);
    console.log('Loaded project filters from storage:', savedFilters);
    if (savedFilters) {
      // Ensure viewMode is a valid value
      const validViewModes = ['table', 'timeline', 'heatmap', 'board'];
      if (!validViewModes.includes(savedFilters.viewMode)) {
        console.warn('Invalid view mode detected:', savedFilters.viewMode);
        savedFilters.viewMode = defaultFilters.viewMode;
      }
      
      return {
        ...defaultFilters,  // Ensure all properties exist
        ...savedFilters     // Override with saved values
      };
    }
  } catch (error) {
    console.error('Error loading project filters from storage:', error);
  }
  return defaultFilters;
};

/**
 * Saves project filters to localStorage using LocalStorageUtils
 * @param filters - The filters to save
 */
const saveFiltersToStorage = (filters: ProjectFilters) => {
  try {
    console.log('Saving project filters to storage:', filters);
    LocalStorageUtils.setItem(PROJECT_FILTERS_STORAGE_KEY, filters);
  } catch (error) {
    console.error('Error saving project filters to storage:', error);
  }
};

/**
 * Hook for managing project filters with persistence in localStorage
 * 
 * Provides methods to:
 * - Access current filter values
 * - Update individual filters
 * - Add/remove tags, participants, states, etc.
 * - Clear all filters
 * - Apply filters to a project list
 * 
 * @returns An object containing filter state and methods to manipulate it
 */
export const useProjectFilters = () => {
  // Load saved filters on initial render
  const initialFilters = useMemo(() => loadFiltersFromStorage(), []);
  
  // State for all filters
  const [filters, setFilters] = useState<ProjectFilters>(initialFilters);

  // Listen for storage events from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === PROJECT_FILTERS_STORAGE_KEY && event.newValue) {
        try {
          console.log('Storage changed in another tab, updating project filters');
          const updatedFilters = JSON.parse(event.newValue) as ProjectFilters;
          setFilters(prev => {
            // Only update if different to avoid loops
            if (JSON.stringify(prev) !== event.newValue) {
              return updatedFilters;
            }
            return prev;
          });
        } catch (error) {
          console.error('Error handling storage event:', error);
        }
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    saveFiltersToStorage(filters);
  }, [filters]);

  /**
   * Updates a specific filter value
   * @param key - The filter key to update
   * @param value - The new value for the filter
   */
  const updateFilter = <K extends keyof ProjectFilters>(key: K, value: ProjectFilters[K]) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  /**
   * Resets all filters to their default values
   */
  const clearAllFilters = () => {
    setFilters(defaultFilters);
  };

  // Helper functions for common filter operations
  const setSearchTerm = (value: string) => updateFilter('searchTerm', value);
  const setShowCompleted = (value: boolean) => updateFilter('showCompleted', value);
  const setSortBy = (value: string) => updateFilter('sortBy', value);
  const setSortDirection = (value: 'asc' | 'desc') => updateFilter('sortDirection', value);
  const setViewMode = (value: 'table' | 'timeline' | 'heatmap' | 'board') => {
    console.log(`Setting project view mode to: ${value}`);
    // Only update the filters state - the useEffect will handle saving to localStorage
    setFilters(prev => ({ ...prev, viewMode: value }));
  };
  
  /**
   * Adds a tag to the selected tags filter if not already included
   * @param tag - The tag name to add
   */
  const addTag = (tag: string) => {
    if (!filters.selectedTags.includes(tag)) {
      updateFilter('selectedTags', [...filters.selectedTags, tag]);
    }
  };
  
  /**
   * Removes a tag from the selected tags filter
   * @param tag - The tag name to remove
   */
  const removeTag = (tag: string) => {
    updateFilter('selectedTags', filters.selectedTags.filter(t => t !== tag));
  };
  
  /**
   * Adds a participant to the selected participants filter if not already included
   * @param participant - The participant name to add
   */
  const addParticipant = (participant: string) => {
    if (!filters.selectedParticipants.includes(participant)) {
      updateFilter('selectedParticipants', [...filters.selectedParticipants, participant]);
    }
  };
  
  /**
   * Removes a participant from the selected participants filter
   * @param participant - The participant name to remove
   */
  const removeParticipant = (participant: string) => {
    updateFilter('selectedParticipants', filters.selectedParticipants.filter(p => p !== participant));
  };
  
  /**
   * Adds an owner to the selected owners filter if not already included
   * @param owner - The owner name to add
   */
  const addOwner = (owner: string) => {
    if (!filters.selectedOwner.includes(owner)) {
      updateFilter('selectedOwner', [...filters.selectedOwner, owner]);
    }
  };
  
  /**
   * Removes an owner from the selected owners filter
   * @param owner - The owner name to remove
   */
  const removeOwner = (owner: string) => {
    updateFilter('selectedOwner', filters.selectedOwner.filter(o => o !== owner));
  };
  
  /**
   * Adds a project state to the selected states filter if not already included
   * @param state - The state value to add
   */
  const addProjectState = (state: string) => {
    if (!filters.selectedProjectStates.includes(state)) {
      updateFilter('selectedProjectStates', [...filters.selectedProjectStates, state]);
    }
  };
  
  /**
   * Removes a project state from the selected states filter
   * @param state - The state value to remove
   */
  const removeProjectState = (state: string) => {
    updateFilter('selectedProjectStates', filters.selectedProjectStates.filter(s => s !== state));
  };

  /**
   * Adds an effort to the selected efforts filter if not already included
   * @param effort - The effort value to add
   */
  const addEffort = (effort: string) => {
    if (!filters.selectedEfforts.includes(effort)) {
      updateFilter('selectedEfforts', [...filters.selectedEfforts, effort]);
    }
  };
  
  /**
   * Removes an effort from the selected efforts filter
   * @param effort - The effort value to remove
   */
  const removeEffort = (effort: string) => {
    updateFilter('selectedEfforts', filters.selectedEfforts.filter(e => e !== effort));
  };
  
  /**
   * Adds a priority to the selected priorities filter if not already included
   * @param priority - The priority value to add
   */
  const addProjectPriority = (priority: string) => {
    if (!filters.selectedProjectPriorities.includes(priority)) {
      updateFilter('selectedProjectPriorities', [...filters.selectedProjectPriorities, priority]);
    }
  };
  
  /**
   * Removes a priority from the selected priorities filter
   * @param priority - The priority value to remove
   */
  const removeProjectPriority = (priority: string) => {
    updateFilter('selectedProjectPriorities', filters.selectedProjectPriorities.filter(p => p !== priority));
  };
  
  /**
   * Adds a pulse to the selected pulses filter if not already included
   * @param pulse - The pulse value to add
   */
  const addPulse = (pulse: string) => {
    if (!filters.selectedPulses.includes(pulse)) {
      updateFilter('selectedPulses', [...filters.selectedPulses, pulse]);
    }
  };
  
  /**
   * Removes a pulse from the selected pulses filter
   * @param pulse - The pulse value to remove
   */
  const removePulse = (pulse: string) => {
    updateFilter('selectedPulses', filters.selectedPulses.filter(p => p !== pulse));
  };
  
  /**
   * Applies all current filters to a project list and returns the filtered and sorted result
   * @param projects - The project list to filter and sort
   * @returns The filtered and sorted project list
   */
  const applyFilters = (projects: Project[]): Project[] => {
    return projects
      .filter(project => 
        project.title.toLowerCase().includes(filters.searchTerm.toLowerCase()) &&
        (filters.showCompleted || project.state !== 'DONE') &&
        (filters.selectedTags.length === 0 || filters.selectedTags.every(tag => 
          project.tags.some(projectTag => projectTag.name === tag)
        )) &&
        (filters.selectedParticipants.length === 0 || filters.selectedParticipants.every(participant => 
          project.persons.some(person => person.name === participant)
        )) &&
        (filters.selectedOwner.length === 0 || filters.selectedOwner.every(ownerName => 
          project.owner?.name === ownerName
        )) &&
        (filters.selectedProjectStates.length === 0 || filters.selectedProjectStates.includes(project.state)) &&
        (filters.selectedEfforts.length === 0 || filters.selectedEfforts.includes(project.effort?.toString())) &&
        (filters.selectedProjectPriorities.length === 0 || filters.selectedProjectPriorities.includes(project.priority?.toString())) &&
        (filters.selectedPulses.length === 0 || filters.selectedPulses.includes(project.pulse))
      )
      .sort((a, b) => {
        let result = 0;
        
        switch (filters.sortBy) {
          case 'title':
            result = a.title.localeCompare(b.title);
            break;
          case 'priority':
            const aPriority = Number(a.priority ?? 0);
            const bPriority = Number(b.priority ?? 0);
            result = aPriority - bPriority;
            break;
          case 'state':
            result = a.state.localeCompare(b.state);
            break;
          case 'created_at':
            result = new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            break;
          case 'start_date':
            const aDate = a.start_date ? new Date(a.start_date).getTime() : 0;
            const bDate = b.start_date ? new Date(b.start_date).getTime() : 0;
            result = aDate - bDate;
            break;
          case 'target_date':
            const aTargetDate = a.target_date ? new Date(a.target_date).getTime() : 0;
            const bTargetDate = b.target_date ? new Date(b.target_date).getTime() : 0;
            result = aTargetDate - bTargetDate;
            break;
          default:
            break;
        }
        
        return filters.sortDirection === 'asc' ? result : -result;
      });
  };

  // Return all filter values and methods
  return {
    filters,
    setSearchTerm,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addParticipant,
    removeParticipant,
    addOwner,
    removeOwner,
    addProjectState,
    removeProjectState,
    addEffort,
    removeEffort,
    addProjectPriority,
    removeProjectPriority,
    addPulse,
    removePulse,
    clearAllFilters,
    applyFilters,
  };
}; 