import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MeetingOccurrenceContainer from '../containers/MeetingOccurrenceContainer';
import MeetingOccurrenceTable from '../components/meeting/MeetingOccurrenceTable';
import { MeetingOccurrenceTableMobile } from '../components/meeting/MeetingOccurrenceTableMobile';
import MeetingOccurrenceModal from '../components/meeting/MeetingOccurrenceModal';
import { Modal, TableHeader, Snackbar, Button, SeparatorHorizontal } from '../components/ui';
import { MeetingOccurrence } from '../api/meeting/types';
import { MeetingType } from '../api/meeting/types';
import { MeetingOccurrenceStartModal } from '../components/meeting/MeetingOccurrenceStartModal';
import { SortOption } from '../components/ui/data/TableHeader';
import { MeetingStateFilter, MeetingStateCount } from '../components/meeting/MeetingStateFilter';
import { BREAKPOINTS } from '../utils/breakpoints';

const MeetingOccurrenceListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [occurrenceToDelete, setOccurrenceToDelete] = useState<string | null>(null);
  const [meetingToEdit, setMeetingToEdit] = useState<MeetingOccurrence | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [occurrences, setOccurrences] = useState<MeetingOccurrence[]>([]);
  const [showStartModal, setShowStartModal] = useState(false);
  const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
  const [sortBy, setSortBy] = useState<string>('date');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [selectedStates, setSelectedStates] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    if (slug && occurrences.length > 0) {
      const occurrence = occurrences.find((occ) => occ.slug === slug);
      if (occurrence) {
        setMeetingToEdit(occurrence);
        setShowModal(true);
      }
    }
  }, [slug, occurrences]);

  // Update meetingTypes when occurrences change
  useEffect(() => {
    if (occurrences.length > 0) {
      const uniqueTypes = Array.from(
        new Set(
          occurrences
            .map(occ => occ.meeting_type)
            .filter((type): type is MeetingType => 
              typeof type === 'object' && 
              type !== null && 
              'id' in type
            )
        )
      );
      setMeetingTypes(uniqueTypes);
    }
  }, [occurrences]);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINTS.TABLET);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDelete = (slug: string) => {
    setOccurrenceToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (occurrenceToDelete) {
      onDelete(occurrenceToDelete);
      setShowDeleteDialog(false);
      setOccurrenceToDelete(null);
      setSnackbarMessage('Meeting Occurrence deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handlePlanNew = () => {
    setMeetingToEdit(null);
    setShowModal(true);
  };

  const handleEditOccurrence = (occurrence: MeetingOccurrence) => {
    navigate(`/meeting-occurrences/${occurrence.slug}`);
    setMeetingToEdit(occurrence);
    setShowModal(true);
  };

  const handleStartNewMeeting = () => {
    setShowStartModal(true);
  };

  // Handler to open the MeetingOccurrenceModal in add or edit mode
  const handleStartMeeting = (meetingTypeId: string, meetingOccurrenceId?: string) => {
    if (meetingOccurrenceId) {
      // Open in edit mode by finding the meeting occurrence by its ID
      const occurrenceToEdit = occurrences.find(occ => occ.id === meetingOccurrenceId);
      if (occurrenceToEdit) {
        setMeetingToEdit(occurrenceToEdit);
      } else {
        console.error(`Could not find occurrence with id ${meetingOccurrenceId}`);
        return; // Exit the function if we can't find the occurrence
      }
    } else {
      // Open in add mode, create a minimal MeetingOccurrence object
      const newOccurrence: MeetingOccurrence = {
        id: '',
        title: '',
        slug: '',
        state: 'SCHEDULED',
        meeting_type: {
          id: meetingTypeId,
          name: '',
          slug: '',
          company: 'default-company-id'
        } as MeetingType,
        date: new Date().toISOString().split('T')[0],
        description: '',
        attendees: [],
        tags: [],
        tasks: [],
        projects: [],
        topic_notes: [],
        is_adhoc: false,
        created_at: '',
        updated_at: '',
        created_by: null,
      };
      setMeetingToEdit(newOccurrence);
    }
    setShowModal(true);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'Date', value: 'date' },
    { label: 'Created At', value: 'created_at' },
  ];

  const computeStateFrequencies = (occurrences: MeetingOccurrence[]): MeetingStateCount[] => {
    const stateCounts = new Map<string, number>();
    
    occurrences.forEach(occurrence => {
      if (occurrence.state) {
        const count = stateCounts.get(occurrence.state) || 0;
        stateCounts.set(occurrence.state, count + 1);
      }
    });

    return Array.from(stateCounts.entries()).map(([state, count]) => ({
      state: state as 'SCHEDULED' | 'IN_PROGRESS' | 'COMPLETED',
      count
    }));
  };

  const handleStateSelect = (state: string) => {
    setSelectedStates([...selectedStates, state]);
  };

  const handleStateDeselect = (state: string) => {
    setSelectedStates(selectedStates.filter(s => s !== state));
  };

  const handleClearAllFilters = () => {
    setSelectedStates([]);
    setSearchTerm('');
  };

  const filterAndSortOccurrences = (occurrences: MeetingOccurrence[]): MeetingOccurrence[] => {
    return occurrences
      .filter(occurrence => {
        const matchesSearch = occurrence.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesState = selectedStates.length === 0 || selectedStates.includes(occurrence.state);
        return matchesSearch && matchesState;
      })
      .sort((a, b) => {
        let result = 0;
        switch (sortBy) {
          case 'title':
            result = a.title.localeCompare(b.title);
            break;
          case 'date':
            result = new Date(a.date).getTime() - new Date(b.date).getTime();
            break;
          case 'created_at':
            result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            break;
          default:
            break;
        }
        return sortDirection === 'asc' ? result : -result;
      });
  };

  return (
    <div>
      <h1 className='m-4'>Meetings Occurrrences</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handlePlanNew}
        searchPlaceholder="Search Meeting Occurrences..."
        addButtonLabel="Plan Meeting Occurrence"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        meetingStates={computeStateFrequencies(occurrences)}
        selectedMeetingStates={selectedStates}
        onMeetingStateSelect={handleStateSelect}
        onMeetingStateDeselect={handleStateDeselect}
        onClearAllFilters={handleClearAllFilters}
        showFilterButton={true}
        additionalButtons={
          <Button
            label="Start Meeting"
            onClick={handleStartNewMeeting}
            variant="secondary"
            buttonStyle="filled"
            size="medium"
            icon="Play"
            iconColor="white"
            iconSize="sm"
          />
        }
      />

      <MeetingOccurrenceContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 bg-background text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading meeting occurrences...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          // Set occurrences on initial load
          if (occurrences.length === 0 && data.length > 0) {
            setOccurrences(data);
          }

          const filteredOccurrences = filterAndSortOccurrences(data);

          const handleSaveOccurrence = async (
            occurrenceData: Omit<MeetingOccurrence, 'id' | 'created_at' | 'updated_at' | 'slug' | 'is_adhoc' | 'topic_notes'>,
            slug?: string
          ): Promise<MeetingOccurrence> => {
            try {
              let savedOccurrence: MeetingOccurrence;
              if (slug) {
                // Edit existing occurrence
                savedOccurrence = await onEdit(slug, occurrenceData);
                setSnackbarMessage('Meeting Occurrence updated successfully');
              } else {
                // Create new occurrence
                savedOccurrence = await onCreate(occurrenceData);
                setSnackbarMessage('Meeting Occurrence created successfully');
              }
          
              setSnackbarType('success');
              setShowSnackbar(true);
          
              return savedOccurrence;
            } catch (error) {
              console.error('Error saving meeting occurrence:', error);
              setSnackbarMessage('Failed to save Meeting Occurrence');
              setSnackbarType('error');
              setShowSnackbar(true);
              throw error;
            }
          };

          return (
            <>
              {/* Meeting Occurrence Modal */}
              {showModal && (
                <MeetingOccurrenceModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate('/meeting-occurrences/');
                  }}
                  onSave={meetingToEdit ? (data) => handleSaveOccurrence(data, meetingToEdit.slug) : handleSaveOccurrence}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  initialData={meetingToEdit || undefined}
                  onSuccess={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('success');
                    setShowSnackbar(true);
                  }}
                  onError={(message) => {
                    setSnackbarMessage(message);
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}

              {/* Delete Confirmation Modal */}
              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this meeting occurrence?</p>
              </Modal>

              <MeetingOccurrenceStartModal
                isOpen={showStartModal}
                onClose={() => setShowStartModal(false)}
                onStartMeeting={handleStartMeeting}
                futureMeetingOccurrences={data.filter(occurrence => new Date(occurrence.date) > new Date())}
              />

              {isMobile ? (
                <MeetingOccurrenceTableMobile
                  data={filteredOccurrences}
                  onEdit={handleEditOccurrence}
                  onDelete={handleDelete}
                />
              ) : (
                <MeetingOccurrenceTable
                  data={filteredOccurrences}
                  key={filteredOccurrences.map((meeting) => meeting.slug).join()}
                  onEdit={handleEditOccurrence}
                  onDelete={handleDelete}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default MeetingOccurrenceListPage;