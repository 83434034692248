import React from 'react';
import logoLight from '../assets/logo/align-logo-light.svg';
import logoDark from '../assets/logo/align-logo-dark.svg';

interface LogoProps {
  className?: string;
  variant?: 'light' | 'dark';
  size?: 'sm' | 'md' | 'lg';
}

export const Logo: React.FC<LogoProps> = ({ 
  className = '', 
  variant = 'light',
  size = 'md' 
}) => {
  const sizeClasses = {
    sm: 'w-8 h-8',
    md: 'w-12 h-12',
    lg: 'w-16 h-16'
  };

  return (
    <img
      src={variant === 'light' ? logoLight : logoDark}
      alt="Align"
      className={`${sizeClasses[size]} ${className}`}
    />
  );
};

export default Logo;