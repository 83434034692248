import React, { useEffect, useState } from 'react';
import { usePersons, useCreatePerson, useUpdatePerson, useDeletePerson } from '../api/person/queries';
import { Person } from '../api/person/types';

interface PersonContainerProps {
  fetchOnMount?: boolean;  // Optional prop to control whether to fetch Persons on mount
  render: (props: {
    data: Person[];
    loading: boolean;
    error: string | null;
    onCreate: (newPerson: Omit<Person, 'id' | 'created_at' | 'updated_at'>) => Promise<Person>;
    onEdit: (slug: string, updatedPerson: Partial<Omit<Person, 'created_at' | 'updated_at'>>) => Promise<Person>;
    onDelete: (slug: string) => void;
  }) => JSX.Element;
}

const PersonContainer: React.FC<PersonContainerProps> = ({ fetchOnMount = true, render }) => {
  const [persons, setPersons] = useState<Person[]>([]);
  const [loading, setLoading] = useState<boolean>(fetchOnMount);
  const [error, setError] = useState<string | null>(null);

  const { data, isLoading, isError } = usePersons();
  const createPersonMutation = useCreatePerson();
  const updatePersonMutation = useUpdatePerson();
  const deletePersonMutation = useDeletePerson();

  useEffect(() => {
    if (fetchOnMount) {
      if (isLoading) {
        setLoading(true);
      } else {
        setLoading(false);
        if (data) setPersons(data);
      }

      if (isError) {
        setError('Failed to fetch persons');
      }
    }
  }, [fetchOnMount, data, isLoading, isError]);

  // Create new person
  const handleCreate = async (newPerson: Omit<Person, 'id' | 'created_at' | 'updated_at'>): Promise<Person> => {
    const result = await createPersonMutation.mutateAsync(newPerson);
    setPersons((prevPersons) => [...prevPersons, result]);
    return result;
  };

  // Edit an existing person using slug
  const handleEdit = async (slug: string, updatedPerson: Partial<Omit<Person, 'created_at' | 'updated_at'>>): Promise<Person> => {
    const result = await updatePersonMutation.mutateAsync({ id: slug, person: updatedPerson });
    setPersons((prevPersons) =>
      prevPersons.map(person => person.slug === slug ? result : person)
    );
    return result;
  };

  // Delete a person using slug
  const handleDelete = (slug: string) => {
    deletePersonMutation.mutate(slug, {
      onSuccess: () => {
        setPersons((prevPersons) => prevPersons.filter(person => person.slug !== slug));
      },
      onError: () => setError('Failed to delete person'),
    });
  };

  return render({
    data: persons,
    loading,
    error,
    onCreate: handleCreate,
    onEdit: handleEdit,
    onDelete: handleDelete,
  });
};

export default PersonContainer;