import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ProjectContainer from '../containers/ProjectContainer';
import { ProjectTable } from '../components/project/ProjectTable';
import { ProjectTableMobile } from '../components/project/ProjectTableMobile';
import { ProjectModal } from '../components/project/ProjectModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal, Button } from '../components/ui';
import { Project } from '../api/project/types';
import { Tag } from '../api/tag/types';
import { Person } from '../api/person/types';
import { Task } from '../api/task/types';
import { Team } from '../api/team/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { ProjectTimelineView } from '../components/project/ProjectTimelineView';
import { ProjectHeatmapView } from '../components/project/ProjectHeatmapView';
import { ProjectBoardView } from '../components/project/ProjectBoardView';
import { TaskModal } from '../components/task/TaskModal';
import { useQueryClient } from 'react-query';
import TaskContainer from '../containers/TaskContainer';
import { Tag as TagFilterType } from '../components/tag/TagFilter';
import { PersonCount } from '../components/person/PersonsFilter';
import { projectStateOptions, effortOptions, priorityOptions, pulseOptions } from '../utils/entitiesOptions';
import { BREAKPOINTS } from '../utils/breakpoints';
import { useProjectFilters } from '../hooks';

const ProjectListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);
  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState<'success' | 'error'>('success');
  const [projects, setProjects] = useState<Project[]>([]);
  const [taskToEdit, setTaskToEdit] = useState<Task | null>(null);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);

  // Use the project filters hook
  const {
    filters,
    setSearchTerm,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addParticipant,
    removeParticipant,
    addOwner,
    removeOwner,
    addProjectState,
    removeProjectState,
    addEffort,
    removeEffort,
    addProjectPriority,
    removeProjectPriority,
    addPulse,
    removePulse,
    clearAllFilters,
    applyFilters
  } = useProjectFilters();

  // Capture the initial view mode and load state
  const initialViewModeRef = useRef(filters.viewMode);
  const isInitialLoadRef = useRef(true);

  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const queryClient = useQueryClient();

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINTS.TABLET);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Combined effect for handling view mode and navigation
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const viewSegment = pathSegments[pathSegments.length - 1];
    const isFilterPath = ['all', 'backlog', 'active', 'complete'].includes(viewSegment);

    // Determine the current view mode from the URL
    const urlViewMode = 
      viewSegment === "heatmap" || location.pathname.includes("/projects/heatmap") 
        ? 'heatmap' 
        : viewSegment === "board" || location.pathname.includes("/projects/board") 
          ? 'board' 
          : viewSegment === "timeline" || location.pathname.includes("/projects/timeline")
            ? 'timeline'
            : 'table';

    // Update view mode based on URL if not a filter path
    if (!isFilterPath && !showModal && filters.viewMode !== urlViewMode) {
      setViewMode(urlViewMode);
    }
  }, [location.pathname, showModal, filters.viewMode, setViewMode, navigate]);

  useEffect(() => {
    if (slug && projects.length > 0) {
      const project = projects.find((project) => project.slug === slug);
      if (project) {
        setProjectToEdit(project);
        setShowModal(true);
      }
    }
  }, [slug, projects]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/projects/active")) {
      setShowCompleted(false);
    } else if (path.includes("/projects/completed")) {
      setShowCompleted(true);
    } else if (path.includes("/projects/all")) {
      // Reset completion filter for "all" view
      setShowCompleted(false);
    }

    clearAllFilters();
  }, [location.pathname]);
  

  const handleUpdateTitle = (
    slug: string,
    newTitle: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { title: newTitle });
    setSnackbarMessage('Project title updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateState = (
    slug: string,
    newState: Project['state'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { state: newState });
    setSnackbarMessage('Project state updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateEffort = (
    slug: string,
    newEffort: Project['effort'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { effort: newEffort });
    setSnackbarMessage('Project effort updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePriority = (
    slug: string,
    newPriority: Project['priority'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { priority: newPriority });
    setSnackbarMessage('Project priority updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePulse = (
    slug: string,
    newPulse: Project['pulse'],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { pulse: newPulse });
    setSnackbarMessage('Project pulse updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTags = (
    slug: string,
    newTags: Tag[],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { tags: newTags });
    setSnackbarMessage('Project tags updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdatePersons = (
    slug: string,
    newPersons: Person[],
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { persons: newPersons });
    setSnackbarMessage('Project persons updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateStartDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { start_date: newDate });
    setSnackbarMessage('Project start date updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTargetDate = (
    slug: string,
    newDate: string,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { target_date: newDate });
    setSnackbarMessage('Project target date updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateOwner = (
    slug: string,
    newOwner: Person | null,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { owner: newOwner });
    setSnackbarMessage('Project owner updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleUpdateTeam = (
    slug: string,
    newTeam: Team | null,
    onEdit: (slug: string, updatedProject: Partial<Project>) => void
  ) => {
    onEdit(slug, { team: newTeam });
    setSnackbarMessage('Project team updated successfully');
    setSnackbarType('success');
    setShowSnackbar(true);
  };

  const handleDelete = (slug: string) => {
    setProjectToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (projectToDelete) {
      onDelete(projectToDelete);
      setShowDeleteDialog(false);
      setProjectToDelete(null);
      setSnackbarMessage('Project deleted successfully');
      setSnackbarType('error');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setProjectToEdit(null);
    setShowModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/projects/new?${searchParams.toString()}`);
  };
  

  const handleShowCompletedToggle = () => {
    setShowCompleted(!filters.showCompleted);
  };

  const handleEditProject = (project: Project) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
    setProjectToEdit(project);
    setShowModal(true);
  };
  

  const toggleViewMode = (mode: 'table' | 'heatmap' | 'board') => {
    setViewMode(mode);
    
    // Build URL path preserving any filter segments
    let basePath = '/projects';
    const pathSegments = location.pathname.split('/');
    const filterSegment = pathSegments.filter(seg => ['all', 'backlog', 'active', 'complete'].includes(seg))[0];
    
    if (filterSegment) {
      basePath = `/projects/${filterSegment}`;
    }
    
    // Add view segment if not table (default)
    const path = mode === 'table' 
      ? basePath 
      : `${basePath}/${mode}`;
      
    navigate(path);
  };

  const additionalButtons = (
    <>
      <Button
        icon="AlignJustify"
        onClick={() => toggleViewMode('table')}
        variant={filters.viewMode === 'table' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Table View"
        iconColor="white"
      />
      <Button
        icon="Grid"
        onClick={() => toggleViewMode('heatmap')}
        variant={filters.viewMode === 'heatmap' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Heatmap View"
        iconColor="white"
      />
      <Button
        icon="Layout"
        onClick={() => toggleViewMode('board')}
        variant={filters.viewMode === 'board' ? 'primary' : 'secondary'}
        iconSize="md"
        title="Board View"
        iconColor="white"
      />
    </>
  );
  

  const sortOptions: SortOption[] = [
    { label: 'Title', value: 'title' },
    { label: 'State', value: 'state' },
    { label: 'Priority', value: 'priority' },
    { label: 'Start Date', value: 'start_date' },
    { label: 'Target Date', value: 'target_date' },
    { label: 'Created At', value: 'created_at' },
  ];

  const compareDates = (dateA: string | null | undefined, dateB: string | null | undefined): number => {
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  };

  const computeTagFrequencies = (projects: Project[]): TagFilterType[] => {
    const tagCounts = new Map<string, number>();
    
    projects.forEach(project => {
      project.tags.forEach((tag: Tag) => {
        const count = tagCounts.get(tag.name) || 0;
        tagCounts.set(tag.name, count + 1);
      });
    });

    return Array.from(tagCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  const computePersonFrequencies = (projects: Project[]): PersonCount[] => {
    const personCounts = new Map<string, number>();
    
    projects.forEach(project => {
      // Count participants
      project.persons.forEach((person: Person) => {
        const count = personCounts.get(person.name) || 0;
        personCounts.set(person.name, count + 1);
      });
    });

    return Array.from(personCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  const computeOwnerFrequencies = (projects: Project[]): PersonCount[] => {
    const ownerCounts = new Map<string, number>();
    
    projects.forEach(project => {
      if (project.owner?.name) {
        const count = ownerCounts.get(project.owner.name) || 0;
        ownerCounts.set(project.owner.name, count + 1);
      }
    });

    return Array.from(ownerCounts.entries()).map(([name, count]) => ({
      name,
      count
    }));
  };

  const handleTagSelect = (tagName: string) => {
    addTag(tagName);
  };

  const handleTagDeselect = (tagName: string) => {
    removeTag(tagName);
  };

  const handleParticipantSelect = (personName: string) => {
    addParticipant(personName);
  };

  const handleParticipantDeselect = (personName: string) => {
    removeParticipant(personName);
  };

  const handleOwnerSelect = (personName: string) => {
    addOwner(personName);
  };

  const handleOwnerDeselect = (personName: string) => {
    removeOwner(personName);
  };

  const computeProjectStateFrequencies = (projects: Project[]): { name: string; count: number }[] => {
    const stateCounts = new Map<string, number>();
    
    projects.forEach(project => {
      if (project.state) {
        const count = stateCounts.get(project.state) || 0;
        stateCounts.set(project.state, count + 1);
      }
    });

    return Array.from(stateCounts.entries()).map(([state, count]) => ({
      name: state,
      count
    }));
  };

  const computeEffortFrequencies = (projects: Project[]): { name: string; count: number }[] => {
    const effortCounts = new Map<string, number>();
    
    projects.forEach(project => {
      if (project.effort) {
        const count = effortCounts.get(project.effort.toString()) || 0;
        effortCounts.set(project.effort.toString(), count + 1);
      }
    });

    return Array.from(effortCounts.entries()).map(([effort, count]) => ({
      name: effort,
      count
    }));
  };

  const computePriorityFrequencies = (projects: Project[]): { name: string; count: number }[] => {
    const priorityCounts = new Map<string, number>();
    
    projects.forEach(project => {
      if (project.priority) {
        const count = priorityCounts.get(project.priority.toString()) || 0;
        priorityCounts.set(project.priority.toString(), count + 1);
      }
    });

    return Array.from(priorityCounts.entries()).map(([priority, count]) => ({
      name: priority,
      count
    }));
  };

  const computePulseFrequencies = (projects: Project[]): { name: string; count: number }[] => {
    const pulseCounts = new Map<string, number>();
    
    projects.forEach(project => {
      if (project.pulse) {
        const count = pulseCounts.get(project.pulse) || 0;
        pulseCounts.set(project.pulse, count + 1);
      }
    });

    return Array.from(pulseCounts.entries()).map(([pulse, count]) => ({
      name: pulse,
      count
    }));
  };

  const handleProjectStateSelect = (state: string) => {
    addProjectState(state);
  };

  const handleProjectStateDeselect = (state: string) => {
    removeProjectState(state);
  };

  const handleEffortSelect = (effort: string) => {
    addEffort(effort);
  };

  const handleEffortDeselect = (effort: string) => {
    removeEffort(effort);
  };

  const handleProjectPrioritySelect = (priority: string) => {
    addProjectPriority(priority);
  };

  const handleProjectPriorityDeselect = (priority: string) => {
    removeProjectPriority(priority);
  };

  const handlePulseSelect = (pulse: string) => {
    addPulse(pulse);
  };

  const handlePulseDeselect = (pulse: string) => {
    removePulse(pulse);
  };

  const handleClearAllFilters = () => {
    clearAllFilters();
  };

  const handleSortChange = (value: string) => {
    if (value === filters.sortBy) {
      setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  return (
    <div>
      <h1 className="m-4">
        {filters.viewMode === 'heatmap'
          ? 'Projects - Heatmap'
          : filters.viewMode === 'timeline'
          ? 'Projects - Timeline'
          : filters.viewMode === 'board'
          ? 'Projects - Board'
          : 'Projects'}
      </h1>
      <SeparatorHorizontal />
      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search Projects..."
        addButtonLabel="Add New Project"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={filters.sortBy}
        sortDirection={filters.sortDirection}
        onDirectionToggle={() => setSortDirection(filters.sortDirection === 'asc' ? 'desc' : 'asc')}
        defaultSortValue="title"
        onShowComplete={handleShowCompletedToggle}
        showCompleted={filters.showCompleted}
        additionalButtons={additionalButtons}
        tags={computeTagFrequencies(projects)}
        selectedTags={filters.selectedTags}
        onTagSelect={handleTagSelect}
        onTagDeselect={handleTagDeselect}
        persons={computePersonFrequencies(projects)}
        selectedPersons={filters.selectedParticipants}
        onPersonSelect={handleParticipantSelect}
        onPersonDeselect={handleParticipantDeselect}
        owners={computeOwnerFrequencies(projects)}
        selectedOwners={filters.selectedOwner}
        onOwnerSelect={handleOwnerSelect}
        onOwnerDeselect={handleOwnerDeselect}
        projectStates={computeProjectStateFrequencies(projects)}
        selectedProjectStates={filters.selectedProjectStates}
        onProjectStateSelect={handleProjectStateSelect}
        onProjectStateDeselect={handleProjectStateDeselect}
        efforts={computeEffortFrequencies(projects)}
        selectedEfforts={filters.selectedEfforts}
        onEffortSelect={handleEffortSelect}
        onEffortDeselect={handleEffortDeselect}
        projectPriorities={computePriorityFrequencies(projects)}
        selectedProjectPriorities={filters.selectedProjectPriorities}
        onProjectPrioritySelect={handleProjectPrioritySelect}
        onProjectPriorityDeselect={handleProjectPriorityDeselect}
        pulses={computePulseFrequencies(projects)}
        selectedPulses={filters.selectedPulses}
        onPulseSelect={handlePulseSelect}
        onPulseDeselect={handlePulseDeselect}
        onClearAllFilters={handleClearAllFilters}
        showFilterButton={true}
      />

      <ProjectContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading projects...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          if (projects.length === 0 && data.length > 0) {
            setProjects(data);
          }

          const filteredAndSortedProjects = applyFilters(data);

          const handleSaveProject = (
            projectData: Omit<Project, 'id' | 'created_at' | 'updated_at' | 'slug'>,
            slug?: string
          ) => {
            if (slug) {
              onEdit(slug, projectData);
              setSnackbarMessage('Project updated successfully');
            } else {
              onCreate(projectData);
              setSnackbarMessage('Project created successfully');
            }
            setSnackbarType('success');
            setShowSnackbar(true);
          };

          return (
            <>
              {showTaskModal && taskToEdit && (
                <TaskContainer
                  projectId={taskToEdit.project_id}
                  render={({ data: tasksFromContainer, loading, error, onCreate, onEdit, onDelete }) => {
                    const fullTask = tasksFromContainer.find(t => t.id === taskToEdit.id) || taskToEdit;
                  
                    return (
                      <TaskModal
                        key={fullTask.id} // Add key to force remount when task data changes
                        isOpen={showTaskModal}
                        onClose={() => {
                          setShowTaskModal(false);
                          setTaskToEdit(null);
                        }}
                        initialData={fullTask}
                        onSuccess={(message) => {
                          setSnackbarMessage(message);
                          setSnackbarType('success');
                          setShowSnackbar(true);
                          setShowTaskModal(false);
                          queryClient.invalidateQueries(['tasks', { projectId: taskToEdit.project_id }]);
                          queryClient.invalidateQueries(['projects']);
                        }}
                        onError={(message) => {
                          setSnackbarMessage(message);
                          setSnackbarType('error');
                          setShowSnackbar(true);
                        }}
                        projectId={taskToEdit.project_id}
                      />
                    );
                  }}
                />
              )}

              {showModal && (
                <ProjectModal
                  isOpen={showModal}
                  onClose={() => {
                    setShowModal(false);
                    navigate(
                      filters.viewMode === 'timeline'
                        ? '/projects/timeline'
                        : filters.viewMode === 'heatmap'
                        ? '/projects/heatmap'
                        : filters.viewMode === 'board'
                        ? '/projects/board'
                        : '/projects/'
                    );
                  }}
                  onSave={projectToEdit ? (data) => handleSaveProject(data, projectToEdit.slug) : handleSaveProject}
                  initialData={
                    projectToEdit
                      ? {
                          ...projectToEdit,
                          topics: projectToEdit.topics || [],
                          dependencies: projectToEdit.dependencies || [],
                          progress: projectToEdit.progress || 0,
                        }
                      : undefined
                  }
                  onEdit={onEdit}
                  onDelete={(slug) => {
                    onDelete(slug); // Invoke delete in the project container
                    setSnackbarMessage('Project deleted successfully');
                    setSnackbarType('error');
                    setShowSnackbar(true);
                  }}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this project?</p>
              </Modal>

              {filters.viewMode === 'timeline' ? (
                <ProjectTimelineView
                  data={filteredAndSortedProjects}
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onOpenTask={(task) => {
                    // If you also want to preserve mode when opening a task modal, do the same:
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setTaskToEdit(task);
                    setShowTaskModal(true);
                    // If you navigate somewhere for the task, append the mode as well.
                  }}
                />
              ) : filters.viewMode === 'heatmap' ? (
                <ProjectHeatmapView
                  projects={filteredAndSortedProjects}
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(slug, updatedProject) => onEdit(slug, updatedProject)}
                />
              ) : filters.viewMode === 'board' ? (
                <ProjectBoardView
                  onOpenProject={(project) => {
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
              
                    setProjectToEdit(project);
                    setShowModal(true);
                    navigate(`/projects/${project.slug}?${searchParams.toString()}`);
                  }}
                  onEdit={(id, updatedProject) => {
                    onEdit(id, updatedProject);
                    // Force a refresh of the projects query
                    queryClient.invalidateQueries(['projects']);
                  }}
                />
              ) : (
                isMobile ? (
                  <ProjectTableMobile
                    data={filteredAndSortedProjects}
                    onEdit={handleEditProject}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                  />
                ) : (
                  <ProjectTable
                    data={filteredAndSortedProjects}
                    key={filteredAndSortedProjects.map(p => p.slug).join()}
                    onEdit={handleEditProject}
                    onDelete={(slug) => handleDelete(slug)}
                    onUpdateState={(slug, newState) => handleUpdateState(slug, newState, onEdit)}
                    onUpdateEffort={(slug, newEffort) => handleUpdateEffort(slug, newEffort, onEdit)}
                    onUpdatePriority={(slug, newPriority) => handleUpdatePriority(slug, newPriority, onEdit)}
                    onUpdatePulse={(slug, newPulse) => handleUpdatePulse(slug, newPulse, onEdit)}
                    onUpdateTags={(slug, newTags) => handleUpdateTags(slug, newTags, onEdit)}
                    onUpdatePersons={(slug, newPersons) => handleUpdatePersons(slug, newPersons, onEdit)}
                    onUpdateStartDate={(slug, newDate) => handleUpdateStartDate(slug, newDate, onEdit)}
                    onUpdateTargetDate={(slug, newDate) => handleUpdateTargetDate(slug, newDate, onEdit)}
                    onUpdateOwner={(slug, newOwner) => handleUpdateOwner(slug, newOwner, onEdit)}
                    onUpdateTitle={(slug, newTitle) => handleUpdateTitle(slug, newTitle, onEdit)}
                    onUpdateTeam={(slug, newTeam) => handleUpdateTeam(slug, newTeam, onEdit)}
                  />
                )
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default ProjectListPage;