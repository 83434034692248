import React from 'react';
import { SelectMulti } from '../ui/SelectMulti';
import TopicContainer from '../../containers/TopicContainer';
import { Topic } from '../../api/topic/types';
import { Icon } from '../ui';

interface TopicSelectMultiProps {
  value: string[];
  onChange: (selectedTopics: Topic[]) => void;
  isDisabled?: boolean;
  enableSearch?: boolean;
  placeholder?: string;
  isOpen?: boolean;
  onClose?: () => void;
  showOpenButton?: boolean;
  contentType?: string;
  personId?: string;
}

const TopicSelectMulti: React.FC<TopicSelectMultiProps> = ({
  value,
  onChange,
  isDisabled = false,
  enableSearch = true,
  placeholder = 'Select Topics',
  isOpen,
  onClose,
  showOpenButton,
  contentType,
  personId,
}) => {
  return (
    <TopicContainer
      personId={personId}
      render={({ data: topics, loading, error }) => {
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>Error: {error}</div>;
        }

        const topicOptions = topics.map((topic) => ({
          value: topic.id,
          label: topic.title,
          icon: <Icon name="Library" size="sm" color="text" />,
          slug: topic.slug,
        }));

        const handleSelect = (topicIds: string[]) => {
          const selectedTopics = topics.filter((topic) => topicIds.includes(topic.id));
          onChange(selectedTopics);
        };

        return (
          <SelectMulti
            placeholder={placeholder}
            options={topicOptions}
            defaultValues={value}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            enableSearch={enableSearch}
            defaultIcon={<Icon name="Library" size="sm" color="text" />}
            isOpen={isOpen}
            onClose={onClose}
          />
        );
      }}
    />
  );
};

export default TopicSelectMulti; 