import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import PersonContainer from '../containers/PersonContainer';
import { PersonTable } from '../components/person/PersonTable';
import { PersonTableMobile } from '../components/person/PersonTableMobile';
import { PersonModal } from '../components/person/PersonModal';
import { Modal, TableHeader, Snackbar, SeparatorHorizontal } from '../components/ui';
import { Person } from '../api/person/types';
import { SortOption } from '../components/ui/data/TableHeader';
import { BREAKPOINTS } from '../utils/breakpoints';

const PersonListPage: React.FC = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [personToDelete, setPersonToDelete] = useState<string | null>(null);
  const [personToEdit, setPersonToEdit] = useState<Person | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [sortBy, setSortBy] = useState<string>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [persons, setPersons] = useState<Person[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINTS.TABLET);

  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();

  // Effect to open modal if slug is present
  useEffect(() => {
    if (slug && persons.length > 0) {
      const person = persons.find((p) => p.slug === slug);
      if (person) {
        setPersonToEdit(person);
        setShowPersonModal(true);
      }
    }
  }, [slug, persons]);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINTS.TABLET);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDelete = (slug: string) => {
    setPersonToDelete(slug);
    setShowDeleteDialog(true);
  };

  const confirmDelete = (onDelete: (slug: string) => void) => {
    if (personToDelete) {
      onDelete(personToDelete);
      setShowDeleteDialog(false);
      setPersonToDelete(null);
      setSnackbarMessage('Person deleted successfully');
      setShowSnackbar(true);
    }
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddNew = () => {
    setPersonToEdit(null);
    setShowPersonModal(true);
  
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c'); // default mode
    }
  
    navigate(`/persons/new?${searchParams.toString()}`);
  };
  

  const handleEditPerson = (person: Person) => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('mode')) {
      searchParams.set('mode', 'c');
    }
  
    navigate(`/persons/${person.slug}?${searchParams.toString()}`);
    setPersonToEdit(person);
    setShowPersonModal(true);
  };
  

  const handleViewMindMap = (slug: string) => {
    navigate(`/persons/${slug}/mindmap`); // Navigate to the mind map route
  };

  const handleUpdatePersonType = (
    slug: string,
    newPersonType: Person['person_type'],
    onEdit: (slug: string, updatedPerson: Partial<Person>) => void
  ) => {
    onEdit(slug, { person_type: newPersonType });
    setSnackbarMessage('Person type updated successfully');
    setShowSnackbar(true);
  };

  const handleUpdateField = (
    slug: string,
    field: keyof Person,
    value: string,
    onEdit: (slug: string, updatedPerson: Partial<Person>) => void
  ) => {
    onEdit(slug, { [field]: value });
    setSnackbarMessage(`Person ${field} updated successfully`);
    setShowSnackbar(true);
  };

  const handleSortChange = (value: string) => {
    if (value === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(value);
      setSortDirection('asc');
    }
  };

  const sortOptions: SortOption[] = [
    { label: 'Name', value: 'name' },
    { label: 'Created At', value: 'created_at' },
  ];

  const filterAndSortPersons = (persons: Person[]): Person[] => {
    return persons
      .filter(person =>
        person.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        let result = 0;
        switch (sortBy) {
          case 'name':
            result = a.name.localeCompare(b.name);
            break;
          case 'created_at':
            result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            break;
          default:
            break;
        }
        return sortDirection === 'asc' ? result : -result;
      });
  };

  return (
    <div>
      <h1 className='m-4'>Persons</h1>
      <SeparatorHorizontal />

      <TableHeader
        onSearchChange={handleSearchChange}
        onAddNew={handleAddNew}
        searchPlaceholder="Search persons..."
        addButtonLabel="Add New Person"
        sortOptions={sortOptions}
        onSortChange={handleSortChange}
        currentSortValue={sortBy}
        sortDirection={sortDirection}
        onDirectionToggle={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
      />

<PersonContainer
        render={({ data, loading, error, onCreate, onEdit, onDelete }) => {
          if (loading) return (
            <div className="min-h-screen flex flex-col items-center justify-start pt-32 text-text">
              <svg
                className="animate-spin h-12 w-12 text-primary mb-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <p>Loading persons...</p>
            </div>
          );
          if (error) return <div>{error}</div>;

          if (persons.length === 0 && data.length > 0) {
            setPersons(data);
          }

          const filteredAndSortedPersons = filterAndSortPersons(data);

          return (
            <>
              {showPersonModal && (
                <PersonModal
                  isOpen={showPersonModal}
                  onClose={() => {
                    setShowPersonModal(false);
                    const searchParams = new URLSearchParams(location.search);
                    if (!searchParams.has('mode')) {
                      searchParams.set('mode', 'c');
                    }
                    navigate(`/persons/?${searchParams.toString()}`);
                  }}
                  initialData={personToEdit}
                  onSuccess={(message) => {
                    setSnackbarMessage(message);
                    setShowSnackbar(true);
                  }}
                  onError={(message) => {
                    setSnackbarMessage(message);
                    setShowSnackbar(true);
                  }}
                />
              )}

              <Modal
                isOpen={showDeleteDialog}
                title="Confirm Delete"
                onClose={() => setShowDeleteDialog(false)}
                onConfirm={() => confirmDelete(onDelete)}
              >
                <p>Are you sure you want to delete this person?</p>
              </Modal>

              {isMobile ? (
                <PersonTableMobile
                  data={filteredAndSortedPersons}
                  onEdit={handleEditPerson}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdatePersonType={(slug, newPersonType) => handleUpdatePersonType(slug, newPersonType, onEdit)}
                  onViewMindMap={handleViewMindMap}
                />
              ) : (
                <PersonTable
                  data={filteredAndSortedPersons}
                  key={filteredAndSortedPersons.map((person) => person.slug).join()}
                  onEdit={handleEditPerson}
                  onDelete={(slug) => handleDelete(slug)}
                  onUpdatePersonType={(slug, newPersonType) => handleUpdatePersonType(slug, newPersonType, onEdit)}
                  onUpdateField={(slug, field, value) => handleUpdateField(slug, field, value, onEdit)}
                  onViewMindMap={handleViewMindMap}
                />
              )}
            </>
          );
        }}
      />

      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type="success"
          onClose={() => setShowSnackbar(false)}
        />
      )}
    </div>
  );
};

export default PersonListPage;
