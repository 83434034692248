import React from 'react';
import { Goal } from '../../api/goal/types';
import { Tag } from '../../api/tag/types';
import { Person } from '../../api/person/types';
import { Team } from '../../api/team/types';
import { TableActionButtons, TableBadgeComponent, TablePersonComponent, TablePersonsComponent, TableTagsComponent } from '../ui';
import { TableTeamComponent } from '../ui/data/table-components/TableTeamComponent';

interface GoalTableMobileProps {
  data: Goal[];
  onEdit: (goal: Goal) => void;
  onDelete: (slug: string) => void;
  onUpdateState: (slug: string, newState: Goal['state']) => void;
  onUpdateProgress: (slug: string, newProgress: number) => void;
  onUpdateTags: (slug: string, newTags: Tag[]) => void;
  onUpdateParticipants: (slug: string, newParticipants: Person[]) => void;
  onUpdateOwner: (slug: string, newOwner: Person | null) => void;
  onUpdateTeam: (slug: string, newTeam: Team | null) => void;
}

const goalStateOptions = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'ACHIEVED', label: 'Achieved' },
  { value: 'MISSED', label: 'Missed' },
  { value: 'CANCELED', label: 'Canceled' }
];

const timeframeOptions = [
  { value: 'ANNUAL', label: 'Annual' },
  { value: 'QUARTERLY', label: 'Quarterly' }
];

const getLabelForValue = (value: string, options: { value: string; label: string }[]): string => {
  const option = options.find(opt => opt.value === value);
  return option?.label || value;
};

export const GoalTableMobile: React.FC<GoalTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateState,
  onUpdateProgress,
  onUpdateTags,
  onUpdateParticipants,
  onUpdateOwner,
  onUpdateTeam,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((goal) => (
        <div 
          key={goal.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(goal)}
          >
            {goal.title}
          </div>

          <div className="flex items-center gap-2 text-xs text-gray-500">
            <TableBadgeComponent
              label={getLabelForValue(goal.timeframe, timeframeOptions)}
              type="timeframe"
              options={timeframeOptions}
              isEditable={false}
            />
            <span>{goal.year}</span>
            {goal.timeframe === 'QUARTERLY' && (
              <span>Q{goal.quarter}</span>
            )}
          </div>

          <div className="flex items-center justify-between">
            <TableBadgeComponent
              label={getLabelForValue(goal.state, goalStateOptions)}
              type="goal_state"
              onUpdate={(newState) => onUpdateState(goal.slug, newState as Goal['state'])}
              options={goalStateOptions}
              isEditable={true}
            />
          </div>

          <div className="w-full space-y-1">
            <div className="w-full bg-gray-200 rounded h-2">
              <div 
                className="bg-blue-600 h-2 rounded" 
                style={{ width: `${goal.progress}%` }}
              />
            </div>
            <div className="text-xs text-gray-500 text-center">
              {goal.progress}%
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-500">Owner:</span>
              <TablePersonComponent
                person={goal.owner ?? null}
                isEditable={true}
                onUpdate={(newOwner) => onUpdateOwner(goal.slug, newOwner)}
              />
            </div>

            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-500">Team:</span>
              <TableTeamComponent
                team={goal.team ?? null}
                isEditable={true}
                onUpdate={(newTeam) => onUpdateTeam(goal.slug, newTeam)}
              />
            </div>

            <div className="flex items-center justify-end">
              <TableActionButtons
                onEdit={() => onEdit(goal)}
                onDelete={() => onDelete(goal.slug)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 