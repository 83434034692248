import React from 'react';
import { Tag } from '../../api/tag/types';
import { TableActionButtons, Avatar, ColorPalette, Icon } from '../ui';

interface TagsTableMobileProps {
  data: Tag[];
  onEdit: (tag: Tag) => void;
  onDelete: (id: string) => void;
  onUpdateName: (id: string, newName: string) => void;
  onUpdateColor: (id: string, newColor: string) => void;
}

export const TagsTableMobile: React.FC<TagsTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateColor,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((tag) => (
        <div 
          key={tag.id}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <ColorPalette
                currentColor={tag.color}
                onChange={(newColor) => onUpdateColor(tag.id, newColor)}
              />
              <div 
                className="text-sm font-medium cursor-pointer hover:text-primary"
                onClick={() => onEdit(tag)}
              >
                {tag.name}
              </div>
            </div>
            <div className="flex items-center gap-2">
              <TableActionButtons
                onDelete={() => onDelete(tag.id)}
              />
              <button
                onClick={() => window.location.href = `/tags/${tag.id}/network`}
                className="p-2 text-primary hover:text-primary-dark rounded-full hover:bg-gray-100"
                title="View Tag Network"
              >
                <Icon name="Share2" size="sm" className="text-primary" />
              </button>
            </div>
          </div>
          
        </div>
      ))}
    </div>
  );
}; 