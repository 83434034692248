import React, { useState, useCallback, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import Board from '../ui/data/Board';
import { useProjects } from '../../api/project/queries';
import { Project } from '../../api/project/types';
import {
  getPriorityLabelAndIcon,
  getEffortLabelAndIcon,
  getPulseLabelAndIcon,
  projectStateOptions,
} from '../../utils/entitiesOptions';
import ProjectContextMenu from './ProjectContextMenu';
import useContextMenu from '../../hooks/useContextMenu';
import { useQueryClient } from 'react-query';

interface ProjectBoardViewProps {
  onOpenProject: (project: Project) => void; // Opens ProjectModal with selected project
  onEdit: (slug: string, updatedProject: Partial<Project>) => void;
}

interface Badge {
  label: string;
  style?: string;
  icon?: React.ReactNode;
}

interface BoardItem {
  id: string; // Use slug as identifier
  title: string;
  badges: Badge[];
}

interface BoardColumn {
  id: string;
  title: string;
  items: BoardItem[];
  isCollapsible?: boolean;
  style?: string;
}

export const ProjectBoardView: React.FC<ProjectBoardViewProps> = ({
  onOpenProject,
  onEdit,
}) => {
  const [columns, setColumns] = useState<BoardColumn[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const { position, contextTarget, handleContextMenu, closeContextMenu } = useContextMenu();
  const queryClient = useQueryClient();

  // Set up polling for fresh data
  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(['projects']);
    }, 2000); // Refresh every 2 seconds
    
    return () => clearInterval(interval);
  }, [queryClient]);

  const { data: projects, isLoading, error } = useProjects();

  const mapProjectToBoardItem = useCallback(
    (project: Project): BoardItem => {
      const priority = getPriorityLabelAndIcon(project.priority.toString());
      const effort = getEffortLabelAndIcon(project.effort);
      const pulse = getPulseLabelAndIcon(project.pulse);

      return {
        id: project.slug,
        title: project.title,
        badges: [
          { label: priority.label, icon: priority.icon },
          { label: effort.label, icon: effort.icon },
          { label: pulse.label, icon: pulse.icon },
        ],
      };
    },
    []
  );

  const updateColumns = useCallback(
    (projects: Project[]) => {
      const newColumns = projectStateOptions.map((stateOption) => ({
        id: stateOption.value,
        title: stateOption.label,
        isCollapsible: stateOption.value === 'DONE', // Collapsible option for 'DONE' state
        items: projects
          .filter((project) => project.state === stateOption.value)
          .map(mapProjectToBoardItem),
        // Apply pastel green style to "DONE"
        style:
          stateOption.value === 'DONE'
            ? 'bg-green-50 border-t-4 border-green-200'
            : '', // Default empty style for other columns
      }));
      setColumns(newColumns);
    },
    [mapProjectToBoardItem]
  );

  useEffect(() => {
    if (!isUpdating && projects) {
      updateColumns(projects);
    }
  }, [projects, isUpdating, updateColumns]);

  const handleOpenItem = useCallback(
    (item: { id: string }) => {
      const project = projects?.find((p) => p.slug === item.id);
      if (project) {
        onOpenProject(project); // Opens the modal with selected project
      }
    },
    [projects, onOpenProject]
  );

  const handleMoveItem = useCallback(
    async (result: DropResult) => {
      const { destination, source, draggableId: slug } = result;

      if (!destination || destination.droppableId === source.droppableId) return;

      const newState = destination.droppableId as Project['state'];
      setIsUpdating(true);

      try {
        // First update the local state optimistically for immediate feedback
        const projectToUpdate = projects?.find(p => p.slug === slug);
        
        if (projectToUpdate && projects) {
          const updatedProject = { ...projectToUpdate, state: newState };
          const updatedProjects = projects.map(p => 
            p.slug === slug ? updatedProject : p
          );
          
          // Apply the optimistic update
          updateColumns(updatedProjects);
        }
        
        // Then send to server
        await onEdit(slug, { state: newState });
        
        // Finally refresh data from server
        queryClient.invalidateQueries(['projects']);
      } catch (error) {
        console.error('Failed to update project:', error);
        // Revert to original data on error
        if (projects) {
          updateColumns(projects);
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [onEdit, queryClient, projects, updateColumns]
  );

  // Handle right-click on a project
  const handleProjectContextMenu = useCallback(
    (event: React.MouseEvent, projectId: string) => {
      event.preventDefault();
      const project = projects?.find(p => p.slug === projectId);
      if (project) {
        handleContextMenu(event, project);
      }
    },
    [projects, handleContextMenu]
  );

  // Handle updates from context menu
  const handleContextMenuUpdate = useCallback(
    async (projectSlug: string, updates: Partial<Project>) => {
      setIsUpdating(true);
      try {
        // First update local state optimistically
        if (projects) {
          const updatedProjects = projects.map(p => 
            p.slug === projectSlug ? { ...p, ...updates } : p
          );
          // Apply the optimistic update to the board
          updateColumns(updatedProjects);
        }
        
        // Then send to server
        await onEdit(projectSlug, updates);
        
        // Finally refresh data from server
        queryClient.invalidateQueries(['projects']);
      } catch (error) {
        console.error('Failed to update project:', error);
        // Revert to original data on error
        if (projects) {
          updateColumns(projects);
        }
      } finally {
        setIsUpdating(false);
        closeContextMenu();
      }
    },
    [onEdit, closeContextMenu, queryClient, projects, updateColumns]
  );

  if (isLoading) return <p>Loading...</p>;
  if (error) {
    const errorMessage = (error as Error).message || 'An error occurred';
    return <p>Error: {errorMessage}</p>;
  }

  return (
    <div className="h-full" onContextMenu={(e) => e.preventDefault()}>
      <Board
        columns={columns}
        minHeight="calc(100vh - 300px)"
        allowAddItems={false}
        onOpenItem={handleOpenItem} // Opens ProjectModal on item click
        onMoveItem={handleMoveItem}
        onContextMenu={handleProjectContextMenu}
      />

      {/* Context Menu */}
      <ProjectContextMenu
        project={contextTarget}
        position={position}
        onClose={closeContextMenu}
        onUpdate={handleContextMenuUpdate}
        onOpenProject={onOpenProject}
      />
    </div>
  );
};

export default ProjectBoardView;
