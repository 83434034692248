import { useState, useEffect, useMemo } from 'react';
import { Task } from '../api/task/types';
import LocalStorageUtils from '../utils/localStorageUtils';

/**
 * Interface defining all the filter options available for tasks
 */
export interface TaskFilters {
  searchTerm: string;
  showArchived: boolean;
  showCompleted: boolean;
  selectedTags: string[];
  selectedOwner: string[];
  selectedState: string[];
  selectedPriority: string[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  viewMode: 'table' | 'weekly' | 'state';
}

// Storage key for task filters
const TASK_FILTERS_STORAGE_KEY = 'taskListFilters';

/**
 * Default values for filters when none are saved in localStorage
 */
const defaultFilters: TaskFilters = {
  searchTerm: '',
  showArchived: false,
  showCompleted: false,
  selectedTags: [],
  selectedOwner: [],
  selectedState: [],
  selectedPriority: [],
  sortBy: 'state',
  sortDirection: 'desc',
  viewMode: 'table',
};

/**
 * Loads task filters from localStorage using LocalStorageUtils
 * @returns The saved filters or default values if none exist
 */
const loadFiltersFromStorage = (): TaskFilters => {
  try {
    const savedFilters = LocalStorageUtils.getItem<TaskFilters>(TASK_FILTERS_STORAGE_KEY);
    console.log('Loaded filters from storage:', savedFilters);
    if (savedFilters) {
      // Ensure viewMode is a valid value
      const validViewModes = ['table', 'weekly', 'state'];
      if (!validViewModes.includes(savedFilters.viewMode)) {
        console.warn('Invalid view mode detected:', savedFilters.viewMode);
        savedFilters.viewMode = defaultFilters.viewMode;
      }
      
      return {
        ...defaultFilters,  // Ensure all properties exist
        ...savedFilters     // Override with saved values
      };
    }
  } catch (error) {
    console.error('Error loading filters from storage:', error);
  }
  return defaultFilters;
};

/**
 * Saves task filters to localStorage using LocalStorageUtils
 * @param filters - The filters to save
 */
const saveFiltersToStorage = (filters: TaskFilters) => {
  LocalStorageUtils.setItem(TASK_FILTERS_STORAGE_KEY, filters);
};

/**
 * Hook for managing task filters with persistence in localStorage
 * 
 * Provides methods to:
 * - Access current filter values
 * - Update individual filters
 * - Add/remove tags, owners, states, priorities
 * - Clear all filters
 * - Apply filters to a task list
 * 
 * @returns An object containing filter state and methods to manipulate it
 */
export const useTaskFilters = () => {
  // Load saved filters on initial render
  const initialFilters = useMemo(() => loadFiltersFromStorage(), []);
  
  // State for all filters
  const [filters, setFilters] = useState<TaskFilters>(initialFilters);

  // Listen for storage events from other tabs
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === TASK_FILTERS_STORAGE_KEY && event.newValue) {
        try {
          console.log('Storage changed in another tab, updating filters');
          const updatedFilters = JSON.parse(event.newValue) as TaskFilters;
          setFilters(prev => {
            // Only update if different to avoid loops
            if (JSON.stringify(prev) !== event.newValue) {
              return updatedFilters;
            }
            return prev;
          });
        } catch (error) {
          console.error('Error handling storage event:', error);
        }
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Save filters to localStorage whenever they change
  useEffect(() => {
    saveFiltersToStorage(filters);
  }, [filters]);

  /**
   * Updates a specific filter value
   * @param key - The filter key to update
   * @param value - The new value for the filter
   */
  const updateFilter = <K extends keyof TaskFilters>(key: K, value: TaskFilters[K]) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  /**
   * Resets all filters to their default values
   */
  const clearAllFilters = () => {
    setFilters(defaultFilters);
  };

  // Helper functions for common filter operations
  const setSearchTerm = (value: string) => updateFilter('searchTerm', value);
  const setShowArchived = (value: boolean) => updateFilter('showArchived', value);
  const setShowCompleted = (value: boolean) => updateFilter('showCompleted', value);
  const setSortBy = (value: string) => updateFilter('sortBy', value);
  const setSortDirection = (value: 'asc' | 'desc') => updateFilter('sortDirection', value);
  const setViewMode = (value: 'table' | 'weekly' | 'state') => {
    console.log(`Setting view mode to: ${value}`);
    // Update the view mode in state and immediately save to localStorage
    const updatedFilters = { ...filters, viewMode: value };
    setFilters(updatedFilters);
    
    // Directly save to localStorage to ensure it's saved immediately
    saveFiltersToStorage(updatedFilters);
  };
  
  /**
   * Adds a tag to the selected tags filter if not already included
   * @param tag - The tag name to add
   */
  const addTag = (tag: string) => {
    if (!filters.selectedTags.includes(tag)) {
      updateFilter('selectedTags', [...filters.selectedTags, tag]);
    }
  };
  
  /**
   * Removes a tag from the selected tags filter
   * @param tag - The tag name to remove
   */
  const removeTag = (tag: string) => {
    updateFilter('selectedTags', filters.selectedTags.filter(t => t !== tag));
  };
  
  /**
   * Adds an owner to the selected owners filter if not already included
   * @param owner - The owner name to add
   */
  const addOwner = (owner: string) => {
    if (!filters.selectedOwner.includes(owner)) {
      updateFilter('selectedOwner', [...filters.selectedOwner, owner]);
    }
  };
  
  /**
   * Removes an owner from the selected owners filter
   * @param owner - The owner name to remove
   */
  const removeOwner = (owner: string) => {
    updateFilter('selectedOwner', filters.selectedOwner.filter(o => o !== owner));
  };
  
  /**
   * Adds a state to the selected states filter if not already included
   * @param state - The state value to add
   */
  const addState = (state: string) => {
    if (!filters.selectedState.includes(state)) {
      updateFilter('selectedState', [...filters.selectedState, state]);
    }
  };
  
  /**
   * Removes a state from the selected states filter
   * @param state - The state value to remove
   */
  const removeState = (state: string) => {
    updateFilter('selectedState', filters.selectedState.filter(s => s !== state));
  };
  
  /**
   * Adds a priority to the selected priorities filter if not already included
   * @param priority - The priority value to add
   */
  const addPriority = (priority: string) => {
    if (!filters.selectedPriority.includes(priority)) {
      updateFilter('selectedPriority', [...filters.selectedPriority, priority]);
    }
  };
  
  /**
   * Removes a priority from the selected priorities filter
   * @param priority - The priority value to remove
   */
  const removePriority = (priority: string) => {
    updateFilter('selectedPriority', filters.selectedPriority.filter(p => p !== priority));
  };
  
  /**
   * Applies all current filters to a task list and returns the filtered and sorted result
   * @param tasks - The task list to filter and sort
   * @returns The filtered and sorted task list
   */
  const applyFilters = (tasks: Task[]): Task[] => {
    return tasks
      .filter(task => 
        // Text search filter
        task.title.toLowerCase().includes(filters.searchTerm.toLowerCase()) &&
        // Archived filter
        (filters.showArchived || task.state !== 'ARCHIVED') &&
        // Tags filter (task must have ALL selected tags)
        (filters.selectedTags.length === 0 || filters.selectedTags.every(tag => 
          task.tags.some(taskTag => taskTag.name === tag)
        )) &&
        // Owner filter
        (filters.selectedOwner.length === 0 || filters.selectedOwner.every(owner => 
          task.owner?.name === owner
        )) &&
        // State filter
        (filters.selectedState.length === 0 || filters.selectedState.includes(task.state)) &&
        // Priority filter
        (filters.selectedPriority.length === 0 || filters.selectedPriority.includes(task.priority))
      )
      // Sort results based on sortBy and sortDirection
      .sort((a, b) => {
        let result = 0;
        
        switch (filters.sortBy) {
          case 'title':
            result = a.title.localeCompare(b.title);
            break;
          case 'state':
            result = a.state.localeCompare(b.state);
            break;
          case 'priority':
            result = a.priority.localeCompare(b.priority);
            break;
          case 'dial_stage':
            result = compareDialStages(a.dial_stage, b.dial_stage);
            break;
          case 'created_at':
            result = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            break;
          case 'due_date':
            result = compareDates(a.due_date, b.due_date);
            break;
          default:
            break;
        }
        
        return filters.sortDirection === 'asc' ? result : -result;
      });
  };

  // Return all filter values and methods
  return {
    filters,
    setSearchTerm,
    setShowArchived,
    setShowCompleted,
    setSortBy,
    setSortDirection,
    setViewMode,
    addTag,
    removeTag,
    addOwner,
    removeOwner,
    addState,
    removeState,
    addPriority,
    removePriority,
    clearAllFilters,
    applyFilters,
  };
};

// Helper functions for sorting

/**
 * Order of dial stages for sorting
 */
const dialStageOrder = ['MENTION', 'INVITATION', 'CONVERSATION', 'BOUNDARY', 'LIMIT'];

/**
 * Gets the numeric value of a dial stage for sorting
 * @param stage - The dial stage value
 * @returns A numeric value representing the stage order
 */
const getDialStageValue = (stage: string | undefined): number => {
  if (!stage) return -1;
  const index = dialStageOrder.indexOf(stage);
  return index === -1 ? dialStageOrder.length : index;
};

/**
 * Compares two dial stages for sorting
 * @param a - First dial stage
 * @param b - Second dial stage
 * @returns Comparison result (-1, 0, or 1)
 */
const compareDialStages = (a: string | undefined, b: string | undefined): number => {
  return getDialStageValue(a) - getDialStageValue(b);
};

/**
 * Compares two dates for sorting, handling null/undefined values
 * @param dateA - First date
 * @param dateB - Second date
 * @returns Comparison result (-1, 0, or 1)
 */
const compareDates = (dateA: string | null | undefined, dateB: string | null | undefined): number => {
  if (!dateA && !dateB) return 0;
  if (!dateA) return 1;
  if (!dateB) return -1;
  return new Date(dateA).getTime() - new Date(dateB).getTime();
}; 