import React, { useMemo } from 'react';
import { Task } from '../../api/task/types';
import { ContextMenu, ContextMenuGroup, ContextMenuItem } from '../ui/ContextMenu';
import { 
  taskStateOptions, 
  dialStageOptions,
  importanceOptions,
  taskPriorityOptions 
} from '../../utils/entitiesOptions';
import { IconName } from '../ui';

interface TaskContextMenuProps {
  task: Task | null;
  position: { x: number; y: number } | null;
  onClose: () => void;
  onUpdate: (taskSlug: string, updates: Partial<Task>) => void;
}

export const TaskContextMenu: React.FC<TaskContextMenuProps> = ({ 
  task, 
  position, 
  onClose,
  onUpdate 
}) => {
  const menuGroups = useMemo(() => {
    if (!task) return [];

    // Map for state icons
    const stateIcons: Record<string, IconName> = {
      IDENTIFIED: 'Flag',
      IN_PROGRESS: 'Loader',
      COMPLETED: 'CheckCircle',
      ARCHIVED: 'Archive',
      BLOCKED: 'Ban',
    };

    // Map for dial stage icons
    const dialIcons: Record<string, IconName> = {
      MENTION: 'MessageSquare',
      INVITATION: 'Mail',
      CONVERSATION: 'MessageCircle',
      BOUNDARY: 'Shield',
      LIMIT: 'Slash',
    };

    // Map for priority icons
    const priorityIcons: Record<string, IconName> = {
      LOW: 'ArrowDown',
      MEDIUM: 'ArrowRight',
      HIGH: 'ArrowUp',
      URGENT: 'AlertTriangle',
    };

    // Create submenu items for task states
    const stateSubmenuItems: ContextMenuItem[] = taskStateOptions.map(option => ({
      id: `state-${option.value}`,
      label: option.label as string,
      icon: stateIcons[option.value],
      onClick: () => onUpdate(task.slug, { state: option.value as any }),
    }));

    // Create submenu items for dial stages
    const dialSubmenuItems: ContextMenuItem[] = dialStageOptions.map(option => ({
      id: `dial-${option.value}`,
      label: option.label as string,
      icon: dialIcons[option.value],
      onClick: () => onUpdate(task.slug, { dial_stage: option.value as any }),
    }));

    // Create submenu items for priorities
    const prioritySubmenuItems: ContextMenuItem[] = taskPriorityOptions.map(option => ({
      id: `priority-${option.value}`,
      label: option.label as string,
      icon: priorityIcons[option.value],
      onClick: () => onUpdate(task.slug, { priority: option.value as any }),
    }));

    // Create parent menu items with submenus
    const groups: ContextMenuGroup[] = [
      {
        title: 'Actions',
        items: [
          {
            id: 'edit',
            label: 'Edit Task',
            icon: 'Edit2',
            onClick: () => {
              // This would be handled by the parent component
            }
          },
          {
            id: 'duplicate',
            label: 'Duplicate',
            icon: 'Copy',
            onClick: () => {
              // Handle duplication
            }
          },
          { 
            id: 'state',
            label: 'Change State',
            icon: stateIcons[task.state] || 'Circle',
            submenu: stateSubmenuItems
          },
          {
            id: 'dial-stage',
            label: 'Accountability Dial',
            icon: dialIcons[task.dial_stage] || 'Circle',
            submenu: dialSubmenuItems
          },
          {
            id: 'priority',
            label: 'Priority',
            icon: priorityIcons[task.priority] || 'Circle',
            submenu: prioritySubmenuItems
          }
        ]
      }
    ];

    return groups;
  }, [task, onUpdate]);

  if (!task) return null;

  return (
    <ContextMenu
      groups={menuGroups}
      position={position}
      onClose={onClose}
    />
  );
};

export default TaskContextMenu; 