import React from 'react';
import { MeetingOccurrence } from '../../api/meeting/types';
import { TableActionButtons, TablePersonsComponent, TableCountComponent, TableBadgeComponent } from '../ui';
import { meetingOccurrenceStateOptions, getLabelForValue } from '../../utils/entitiesOptions';

interface MeetingOccurrenceTableMobileProps {
  data: MeetingOccurrence[];
  onEdit: (updatedOccurrence: MeetingOccurrence) => void;
  onDelete: (slug: string) => void;
}

export const MeetingOccurrenceTableMobile: React.FC<MeetingOccurrenceTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((occurrence) => (
        <div 
          key={occurrence.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(occurrence)}
          >
            {occurrence.title}
          </div>
          
          <div className="text-xs text-gray-500">
            {occurrence.date}
          </div>

          <div className="flex items-center justify-between">
            <TableBadgeComponent
              label={occurrence.meeting_type?.name ?? 'Ad Hoc'}
              type="state"
            />
            <TableBadgeComponent
              label={getLabelForValue(occurrence.state, meetingOccurrenceStateOptions)}
              type="state"
            />
          </div>

          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-end">
              <TableActionButtons
                onEdit={() => onEdit(occurrence)}
                onDelete={() => onDelete(occurrence.slug)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 