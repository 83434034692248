import React from 'react';
import { MeetingType } from '../../api/meeting/types';
import { TableActionButtons, TableBadgeComponent } from '../ui';
import { recurrenceOptions, durationOptions, getLabelForValue } from '../../utils/entitiesOptions';

interface MeetingTypeTableMobileProps {
  data: MeetingType[];
  onEdit: (meetingType: MeetingType) => void;
  onDelete: (id: string) => void;
  onUpdateRecurrence?: (meetingTypeId: string, newRecurrence: string) => void;
  onUpdateDuration?: (meetingTypeId: string, newDuration: string) => void;
}

export const MeetingTypeTableMobile: React.FC<MeetingTypeTableMobileProps> = ({
  data,
  onEdit,
  onDelete,
  onUpdateRecurrence,
  onUpdateDuration,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      {data.map((meetingType) => (
        <div 
          key={meetingType.slug}
          className="bg-white rounded-lg shadow-sm p-3 space-y-2 border border-gray-200"
        >
          <div 
            className="text-sm font-medium cursor-pointer hover:text-primary"
            onClick={() => onEdit(meetingType)}
          >
            {meetingType.name}
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <TableBadgeComponent
                label={getLabelForValue(meetingType.recurrence, recurrenceOptions)}
                type="recurrence"
                onUpdate={(newRecurrence) => onUpdateRecurrence?.(meetingType.slug, newRecurrence)}
                options={recurrenceOptions}
                isEditable={!!onUpdateRecurrence}
              />
              <TableBadgeComponent
                label={getLabelForValue(meetingType.duration, durationOptions)}
                type="duration"
                onUpdate={(newDuration) => onUpdateDuration?.(meetingType.slug, newDuration)}
                options={durationOptions}
                isEditable={!!onUpdateDuration}
              />
            </div>
            <div className="flex items-center justify-end">
              <TableActionButtons
                onEdit={() => onEdit(meetingType)}
                onDelete={() => onDelete(meetingType.slug)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}; 