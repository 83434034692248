import React from 'react';
import { EnumFilter } from '../ui/base/EnumFilter';

export interface MeetingStateCount {
  state: 'SCHEDULED' | 'IN_PROGRESS' | 'COMPLETED';
  count: number;
}

interface MeetingStateFilterProps {
  states: MeetingStateCount[];
  selectedStates: string[];
  onStateSelect: (state: string) => void;
  onStateDeselect: (state: string) => void;
  className?: string;
}

export const MeetingStateFilter: React.FC<MeetingStateFilterProps> = ({
  states,
  selectedStates,
  onStateSelect,
  onStateDeselect,
  className,
}) => {
  const stateColors = {
    SCHEDULED: 'bg-yellow-100 text-yellow-800',
    IN_PROGRESS: 'bg-blue-100 text-blue-800',
    COMPLETED: 'bg-green-100 text-green-800',
  };

  const stateLabels = {
    SCHEDULED: 'Scheduled',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
  };

  return (
    <EnumFilter
      title="Meeting State"
      options={states.map((item) => ({
        value: item.state,
        label: stateLabels[item.state],
        count: item.count,
        className: stateColors[item.state],
      }))}
      selectedValues={selectedStates}
      onSelect={onStateSelect}
      onDeselect={onStateDeselect}
      className={`${className} w-48`}
      variant="primary"
    />
  );
}; 