import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestMagicLink, validateMagicCode, initializeCsrf } from '../../services/authService';
import alignLogo from '../../assets/logo/align-logo-light.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
    // Initialize CSRF token
    initializeCsrf();

    const personId = localStorage.getItem('person_id');
    const companyId = localStorage.getItem('company_id');
    
    if (personId && companyId) {
      navigate('/tasks/board', { replace: true });
    }
  }, [navigate]);

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleRequestMagicLink = async () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      await requestMagicLink(email);
      setLinkSent(true);
    } catch (error: any) {
      setError(error.response?.data?.error || 'Failed to send magic link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateCode = async () => {
    if (!code || code.length !== 8) {
      setError('Please enter a valid 8-character code');
      return;
    }
  
    setIsLoading(true);
    setError('');
  
    try {
      const response = await validateMagicCode(email, code);
      if (response.person_id) localStorage.setItem('person_id', response.person_id);
      if (response.company_id) localStorage.setItem('company_id', response.company_id);
      window.location.href = '/tasks/board';  // Changed from '/dashboard' to '/tasks/board'
    } catch (error: any) {
      setError(error.response?.data?.error || 'Invalid code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualCodeEntry = () => {
    setShowCodeInput(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent, action: () => void) => {
    if (e.key === 'Enter') {
      action();
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-background px-4">
      <div className="w-full max-w-md space-y-8">
        {/* Logo */}
        <div className="flex justify-center">
          <img 
            src={alignLogo} 
            alt="Align Logo" 
            className="w-20 h-20 object-contain"
          />
        </div>

        {/* Main Content */}
        <div className="text-center space-y-6 transition-all duration-300 ease-in-out">
          {!linkSent ? (
            <>
              <div className="space-y-2">
                <h1 className="text-2xl font-medium text-text">
                  Sign in to Align
                </h1>
                <p className="text-base text-gray-600">
                  Your workspace for better collaboration
                </p>
              </div>
            </>
          ) : (
            <div className="space-y-2">
              <h1 className="text-2xl font-medium text-text">
                Check your email
              </h1>
              <p className="text-base text-text">
                We've sent a temporary login link.
              </p>
              <p className="text-base text-text">
                Please check your inbox at <span className="font-medium">{email}</span>.
              </p>
            </div>
          )}

          {!showCodeInput && !isLoading && linkSent && (
            <div className="transform transition-all duration-300 ease-in-out">
              <button
                onClick={handleManualCodeEntry}
                className="text-base text-primary hover:text-primary-dark transition-colors duration-200"
              >
                Enter code manually
              </button>
            </div>
          )}

          <div className={`space-y-4 overflow-hidden transition-all duration-300 ease-in-out ${
            showCodeInput ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
          }`}>
            {showCodeInput && (
              <>
                <input
                  type="text"
                  maxLength={8}
                  placeholder="Enter code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value.toUpperCase());
                    setError('');
                  }}
                  onKeyPress={(e) => handleKeyPress(e, handleValidateCode)}
                  className="w-full px-4 py-3 text-base border border-neutral rounded-md
                          placeholder-placeholder text-text bg-white
                          focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary
                          transition duration-200"
                />
                <button
                  onClick={handleValidateCode}
                  disabled={!code || isLoading}
                  className="w-full py-3 px-4 text-base font-medium text-white 
                          bg-primary hover:bg-primary-dark rounded-md
                          focus:outline-none focus:ring-2 focus:ring-offset-2 
                          focus:ring-primary transition duration-200
                          disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Continue with login code
                </button>
              </>
            )}
          </div>

          {!linkSent && (
            <div className="space-y-4">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError('');
                }}
                onKeyPress={(e) => handleKeyPress(e, handleRequestMagicLink)}
                disabled={isLoading}
                className="w-full px-4 py-3 text-base border border-neutral rounded-md
                        placeholder-placeholder text-text bg-white
                        focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary
                        transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              />
              <button
                onClick={handleRequestMagicLink}
                disabled={!email || isLoading}
                className="w-full py-3 px-4 text-base font-medium text-white 
                        bg-primary hover:bg-primary-dark rounded-md
                        focus:outline-none focus:ring-2 focus:ring-offset-2 
                        focus:ring-primary transition duration-200
                        disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Sending...' : 'Continue with email'}
              </button>
            </div>
          )}

          {(showCodeInput || linkSent) && (
            <button
              onClick={() => {
                setShowCodeInput(false);
                setLinkSent(false);
                setCode('');
                setError('');
              }}
              className="text-base text-primary hover:text-primary-dark transition-colors duration-200"
            >
              Back to login
            </button>
          )}

          {error && (
            <div className="text-base text-red-500 p-3 bg-red-50 rounded-md transform transition-all duration-300 ease-in-out">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;